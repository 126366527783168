import { FormEvent, useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import $ from 'jquery'
import 'jquery-mask-plugin'
import { iEmpresaCadastrarDto } from "./dto/cadastrar-empresa.dto"
import { ApiEndpoints, reqPatch, reqPostNew, reqPut } from "../../../config/apiClient"
import { toast } from "react-toastify"
import removeAllErrorMsg from "../../../components/funcRemoveAllErros"
import { getUrlSearchParams } from "../../urlParams"
import LoadingModal from "../../../components/loadingModal"
import funcSleep from "../../../components/funcSleep"
import iOneEmpresaDto from "./dto/one-empresa.dto"

function EmpresaCadastrarPage () {

    const [loading, setLoading] = useState<boolean>(false)
    const [empresaD, setEmpresa] = useState<iEmpresaCadastrarDto>()

    useEffect(() => {
        $('.cnpj').mask('00.000.000/0000-00', {
            reverse: false,
            placeholder: '00.000.000/0000-00'
        })
        $('.cep').mask('00000-000', {
            reverse: false,
            placeholder: '00000-000'
        })
        $('.telefone').mask('(00) 00000-0000', {
            reverse: false,
            placeholder: '(00) 00000-0000'
        })

        document.title = "Cadastrar Empresa | OB PORTUS"

        const urlDados = getUrlSearchParams()
        const id = urlDados.get("id")

        const consultarEmpresa = async (id:number) => {
            setLoading(true)

            const dados:iOneEmpresaDto = {
                codigo: Number(id)
            }

            const api = await reqPostNew(ApiEndpoints.painelControle.empresas.consultar, dados)

            if (api.status === 200) {
                const empresa:iEmpresaCadastrarDto = api.data
                setEmpresa(empresa)

                let form:any = document.getElementById("formEmpresa")
                form.codigo.value = empresa.codigo
                form.razao_social.value = empresa.razao_social
                form.nome.value = empresa.nome
                form.cnpj.value = empresa.cnpj
                form.endereco.value = empresa.endereco
                form.numero.value = empresa.numero
                form.bairro.value = empresa.bairro
                form.cidade.value = empresa.cidade
                form.cep.value = empresa.cep
                form.estado.value = empresa.estado
                form.telefone.value = empresa.telefone
                form.email.value = empresa.email
            }

            setLoading(false)
        }

        if (id !== null) {
            document.title = "Editar Empresa | OB PORTUS"
            consultarEmpresa(Number(id))
        }
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        // throw new Error("Function not implemented.")
        const form:any = event.target
        let formValidado = true

        let dados:iEmpresaCadastrarDto = {
            codigo: Number(form.codigo.value),
            razao_social: form.razao_social.value,
            nome: form.nome.value,
            cnpj: form.cnpj.value,
            endereco: form.endereco.value,
            numero: form.numero.value,
            bairro: form.bairro.value,
            cidade: form.cidade.value,
            cep: form.cep.value,
            estado: form.estado.value,
            telefone: form.telefone.value,
            email: form.email.value
        }

        if (dados.codigo === null || dados.codigo === 0) {
            form.codigo.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.razao_social === "") {
            form.razao_social.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.nome === "") {
            form.nome.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.cnpj === "") {
            form.cnpj.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.endereco === "") {
            form.endereco.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.numero === "") {
            form.numero.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.bairro === "") {
            form.bairro.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.cidade === "") {
            form.cidade.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.cep === "") {
            form.cep.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.estado === "") {
            form.estado.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.telefone === "") {
            form.telefone.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.email === "") {
            form.email.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (!formValidado) {
            return
        }

        console.log(dados)

        if (empresaD === undefined) {
            cadastrar(dados)
        }

        if (empresaD !== undefined) {
            editar(dados)
        }
    }

    async function cadastrar(dados:iEmpresaCadastrarDto) {

        setLoading(true)

        const api = await reqPut(ApiEndpoints.painelControle.empresas.cadastrar, dados)

        setLoading(false)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Empresa cadastrada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(1500)

            window.location.assign(`/painelControle/empresas`)
        }

    }

    async function editar(dados: iEmpresaCadastrarDto) {
        setLoading(true)

        const api = await reqPatch(ApiEndpoints.painelControle.empresas.editar, dados)

        setLoading(false)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Empresa editada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/painelControle/empresas`)
        }
    }

    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <h1 id="titleMain">Empresa</h1>
            <br></br>
            <Form id='formEmpresa' onSubmit={handleSubmit} noValidate>
                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Código da empresa:</Form.Label>
                            <Form.Control name='codigo' type="number" id='codigo' placeholder="#ID"/>
                            <Form.Control.Feedback type="invalid">
                                Informe o código da empresa!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>CNPJ da empresa:</Form.Label>
                            <Form.Control className="cnpj" name='cnpj' type="text" id='cnpj' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o CNPJ da empresa!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Razão Social:</Form.Label>
                            <Form.Control name='razao_social' type="text" id='razao_social' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe a razão social da empresa!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Nome:</Form.Label>
                            <Form.Control name='nome' type="text" id='nome' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Nome da empresa!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Endereço:</Form.Label>
                            <Form.Control name='endereco' type="text" id='endereco' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Endereço da empresa!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Número:</Form.Label>
                            <Form.Control name='numero' type="text" id='numero' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Número da empresa!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Bairro:</Form.Label>
                            <Form.Control name='bairro' type="text" id='bairro' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Bairro da empresa!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>Cidade:</Form.Label>
                            <Form.Control name='cidade' type="text" id='cidade' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Cidade da empresa!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>Estado:</Form.Label>
                            <Form.Control name='estado' type="text" id='estado' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Estado da empresa!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>CEP:</Form.Label>
                            <Form.Control className="cep" name='cep' type="text" id='cep' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o CEP da empresa!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Telefone:</Form.Label>
                            <Form.Control className="telefone" name='telefone' type="text" id='telefone' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Telefone da empresa!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Email:</Form.Label>
                            <Form.Control name='email' type="email" id='email' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Email da empresa!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                {
                    empresaD === undefined &&
                    <Button variant="success" type="submit">Cadastrar</Button>
                }

                {
                    empresaD !== undefined &&
                    <Button variant="success" type="submit">Editar</Button>
                }
            </Form>
        </div>
    )
}

export default EmpresaCadastrarPage