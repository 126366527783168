import { FormEvent, useEffect, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import removeAllErrorMsg from "../../components/funcRemoveAllErros"
import Select from "react-select"

interface iProps {
    show: boolean,
    handleClose: any,
    handleAplicarFiltro: any,
    dadosFiltro: any
}

export default function FolhaPontoModalFiltroPage({show, handleClose, handleAplicarFiltro, dadosFiltro}:iProps) {

    const [centrosCustoFiltro, setCentrosCustoFiltro] = useState<any[]>([])
    const [centrosCusto, setCentrosCusto] = useState<any[]>([])

    const [empresasFiltro, setEmpresasFiltro] = useState<any[]>([])
    const [empresas, setEmpresas] = useState<any[]>([])

    const [situacoesFiltro, setSituacoesFiltro] = useState<any[]>([])
    const [situacoes, setSituacoes] = useState<any[]>([])

    const [cargosFiltro, setCargosFiltro] = useState<any[]>([])
    const [cargos, setCargos] = useState<any[]>([])

    const [admissaoInicial, setAdmissaoInicial] = useState<string>('')
    const [admissaoFinal, setAdmissaoFinal] = useState<string>('')
    const [demissaoInicial, setDemissaoInicial] = useState<string>('')
    const [demissaoFinal, setDemissaoFinal] = useState<string>('')

    useEffect(() => {

        let centroAux = dadosFiltro.centro_custo.map((item:any, index:any) => (
            {
                "value": `${item.codigo}`,
                "label": `${item.codigo} - ${item.centro_custo}`
            }
        ))
        setCentrosCustoFiltro(centroAux)

        let empresasAux = dadosFiltro.empresas.map((item:any, index:any) => (
            {
                "value": `${item.codigo}`,
                "label": `${item.codigo} - ${item.nome}`
            }
        ))
        setEmpresasFiltro(empresasAux)

        let cargosAux = dadosFiltro.cargos.map((item:any, index:any) => (
            {
                "value": `${item.codigo}`,
                "label": `${item.codigo} - ${item.descricao}`
            }
        ))
        setCargosFiltro(cargosAux)

        let situacoesAux = dadosFiltro.situacoes.map((item:any, index:any) => (
            {
                "value": `${item.codigo}`,
                "label": `${item.codigo} - ${item.descricao}`
            }
        ))
        setSituacoesFiltro(situacoesAux)

    }, [dadosFiltro])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dados = {
            empresas: empresas.map((item:any, index:any) => (Number(item.value))),
            situacoes: situacoes.map((item:any, index:any) => (Number(item.value))),
            cargos: cargos.map((item:any, index:any) => (Number(item.value))),
            centros_custo: centrosCusto.map((item:any, index:any) => (Number(item.value))),
            admissao_inicial: admissaoInicial === "" ? null : admissaoInicial,
            admissao_final: admissaoFinal === "" ? null : admissaoFinal,
            demissao_inicial: demissaoInicial === "" ? null : demissaoInicial,
            demissao_final: demissaoFinal === "" ? null : demissaoFinal,
            mostrar_demitidos: Number(form.mostrar_demitidos.value),
        }

        if (!formValidado) {
            return
        }

        handleAplicarFiltro(dados)
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>Filtro Avançado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id={`form`} onSubmit={handleSubmit} noValidate>
                    <Row>
                        <Col className="col-4">
                            <Form.Group>
                                <Form.Label>Empresa:</Form.Label>
                                <Select name='empresas' id='empresas' options={empresasFiltro} value={empresas} isMulti closeMenuOnSelect={false} placeholder="Selecione a empresa" onChange={
                                    (choice:any) => {
                                        setEmpresas(choice)
                                    }
                                } styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: "#dee2e6"
                                    }),
                                }} />
                            </Form.Group>
                        </Col>
                        <Col className="col-4">
                            <Form.Group>
                                <Form.Label>Situação:</Form.Label>
                                <Select name='situacoes' id='situacoes' options={situacoesFiltro} value={situacoes} isMulti closeMenuOnSelect={false} placeholder="Selecione a Situação" onChange={
                                    (choice:any) => {
                                        setSituacoes(choice)
                                    }
                                } styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: "#dee2e6"
                                    }),
                                }} />
                            </Form.Group>
                        </Col>
                        <Col className="col-4">
                            <Form.Group>
                                <Form.Label>Cargo:</Form.Label>
                                <Select name='cargos' id='cargos' options={cargosFiltro} value={cargos} isMulti closeMenuOnSelect={false} placeholder="Selecione o Cargo" onChange={
                                    (choice:any) => {
                                        setCargos(choice)
                                    }
                                } styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: "#dee2e6"
                                    }),
                                }} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Centro de custo:</Form.Label>
                                <Select name='centros_custo' id='centros_custo' options={centrosCustoFiltro} value={centrosCusto} isMulti closeMenuOnSelect={false} placeholder="Selecione o Centro de Custo" onChange={
                                    (choice:any) => {
                                        setCentrosCusto(choice)
                                    }
                                } styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: "#dee2e6"
                                    }),
                                }} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="col-4">
                            <div className="div-row">
                            <Form.Group style={{marginRight: "5px"}}>
                                <Form.Label>Admissão inicial:</Form.Label>
                                <Form.Control name='admissao_inicial' type="date" id='admissao_inicial' value={admissaoInicial} onChange={(event) => {setAdmissaoInicial(event.target.value)}} placeholder="dd/mm/aaaa" />
                                <Form.Control.Feedback type="invalid">
                                    Informe a Data!
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Admissão final:</Form.Label>
                                <Form.Control name='admissao_final' type="date" id='admissao_final' value={admissaoFinal} onChange={(event) => {setAdmissaoFinal(event.target.value)}} placeholder="dd/mm/aaaa" />
                                <Form.Control.Feedback type="invalid">
                                    Informe a Data!
                                </Form.Control.Feedback>
                            </Form.Group>
                            </div>
                        </Col>
                        <Col className="col-4">
                            <div className="div-row">
                            <Form.Group style={{marginRight: "5px"}}>
                                <Form.Label>Demissão inicial:</Form.Label>
                                <Form.Control name='demissao_inicial' type="date" id='demissao_inicial' value={demissaoInicial} onChange={(event) => {setDemissaoInicial(event.target.value)}} placeholder="dd/mm/aaaa" />
                                <Form.Control.Feedback type="invalid">
                                    Informe a Data!
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Demissão final:</Form.Label>
                                <Form.Control name='demissao_final' type="date" id='demissao_final' value={demissaoFinal} onChange={(event) => {setDemissaoFinal(event.target.value)}} placeholder="dd/mm/aaaa" />
                                <Form.Control.Feedback type="invalid">
                                    Informe a Data!
                                </Form.Control.Feedback>
                            </Form.Group>
                            </div>
                        </Col>
                        <Col className="col-4">
                            <Form.Group>
                                <Form.Label>Mostrar Demitidos?</Form.Label>
                                <Form.Select name="mostrar_demitidos" id="mostrar_demitidos" aria-label="Selecione o opção">
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br></br>
                    <div className="div-row">
                        <Button type="submit" style={{marginRight: '5px'}}>Aplicar Filtro</Button>
                        <Button variant="secondary" onClick={() => {
                            setEmpresas([])
                            setSituacoes([])
                            setCargos([])
                            setCentrosCusto([])
                            // defineValueInInput('admissao_inicial', '')
                            setAdmissaoInicial('')
                            // defineValueInInput('admissao_final', '')
                            setAdmissaoFinal('')
                            // defineValueInInput('demissao_inicial', '')
                            setDemissaoInicial('')
                            // defineValueInInput('demissao_final', '')
                            setDemissaoFinal('')
                            const filtroAux = {
                                empresas: [],
                                situacoes: [],
                                cargos: [],
                                centros_custo: [],
                                admissao_inicial: null,
                                admissao_final: null,
                                demissao_inicial: null,
                                demissao_final: null,
                                mostrar_demitidos: 0
                            }
                            handleAplicarFiltro(filtroAux)
                        }}>Limpar Fitro</Button>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}