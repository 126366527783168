import React, { useState } from "react"
import styles from '../../styles/apuracao.module.css'
import dayjs from "dayjs"
import { Button } from "react-bootstrap"
import ApuracaoModalMarcacoesDia from "./ApuracaoModalMarcacoes"

interface iProps {
    dadosMarcacoes: any[],
    dia: any,
    colaborador: any,
}

export default function ApuracaoPagePainelDetalhesMarcacoes({dadosMarcacoes, dia, colaborador}:iProps) {
    const divStyle:React.CSSProperties = {
        margin: "10px 0px",
    }
    
    const [showModalMapa, setShowModalMapa] = useState<boolean>(false)

    return (
        <div style={divStyle}>
            <p>Marcações</p>
            {
                dadosMarcacoes.map((registro:any, index:any) => (
                    <div key={index} className={styles.apuracaoDiaRegs} style={divStyle}>
                        <p>Marcação #{registro.id_marcacao} Conexão: {registro.internet} - {registro.conexao}</p>
                        <p>{dayjs(registro.data_criacao).format("DD/MM/YYYY HH:mm:ss")} {registro.tipo === 0 ? 'Entrada' : 'Saída'}</p>
                        {
                            registro.intervalo !== null &&
                            <p>Intervalo Realizado? {registro.intervalo === 1 ? 'Sim' : 'Não'}</p>
                        }
                                                {
                            registro.obs_intervalo !== null &&
                            <p>Intervalo observação: {registro.obs_intervalo}</p>
                        }
                        {
                            registro.latitude !== null &&
                            <p>Localização: {registro.latitude}, {registro.longitude} <a href={`https://www.google.com/maps/search/?api=1&query=${registro.latitude},${registro.longitude}`} target="_blank" rel="noreferrer">Mapa</a></p>
                        }
                        {
                            registro.latitude === null &&
                            <p>Localização não disponível.</p>
                        }
                    </div>
                ))
            }
            { dadosMarcacoes.length > 0 &&
              <div>
                <Button variant="success" onClick={() => setShowModalMapa(true)}>Visualizar no Mapa</Button>
                { showModalMapa && <ApuracaoModalMarcacoesDia colaborador={colaborador} dia={dia} handleClose={() => setShowModalMapa(false)} marcacoes={dadosMarcacoes} show={showModalMapa} /> }
              </div>
            }
        </div>
    )
}