import { useEffect, useState } from "react"
import { iListaEmpresasDto } from "./dto/lista-empresas.dto"
import { ApiEndpoints, reqGetNew } from "../../../config/apiClient"
import { createColumnHelper } from "@tanstack/react-table"
import DataTable from "../../../components/dataTable/DataTable"
import { Button } from "react-bootstrap"

const columnHelper = createColumnHelper<iListaEmpresasDto>()

const defaultColumns = [
    columnHelper.accessor('codigo', {
        header: 'ID',
        size: 10,
        minSize: 10,
        maxSize: 150
    }),
    columnHelper.accessor('razao_social', {
        header: 'Razão Social'
    }),
    columnHelper.accessor('nome', {
        header: 'Nome'
    }),
    columnHelper.accessor('cnpj', {
        header: 'CNPJ'
    }),
]

const defaultOrder:any = []

function EmpresasPage() {
    const [empresas, setEmpresas] = useState<iListaEmpresasDto[]>([])

    useEffect(() => {
        document.title = "Empresas | OB PORTUS"

        const listarEmpresas = async () => {
            const empresasApi = await reqGetNew(ApiEndpoints.painelControle.empresas.todos)

            if (empresasApi.status === 200) {
                const empresasAux:iListaEmpresasDto[] = empresasApi.data
                setEmpresas(empresasAux)
            }
        }

        listarEmpresas()
    }, [])

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const empresa = empresas[idx]
        const link = `/painelControle/empresas/editar?id=${empresa.codigo}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const empresa = empresas[idx]
        const link = `/painelControle/empresas/editar?id=${empresa.codigo}`

        if (event.button === 0) {
            window.location.assign(link)
        }
    }

    return (
        <div id="main">
            <div className="div-row-space-between">
                <h1 id="titleMain">Empresas</h1>
                <Button onClick={() => {
                    // window.open(`/painelControle/empresas/cadastrar`)
                    window.location.assign(`/painelControle/empresas/cadastrar`)
                }}>Cadastrar</Button>
            </div>
            <br></br>
            {<DataTable dados={empresas} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}
        </div>
    )
}

export default EmpresasPage