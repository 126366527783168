import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { ApiEndpoints, reqGetNew } from "../../../config/apiClient"
import DataTable from "../../../components/dataTable/DataTable"
import { createColumnHelper } from "@tanstack/react-table"
import iLocalTrabalhoDadosDto from "./dto/dados-local_trabalho.dto"

const columnHelper = createColumnHelper<iLocalTrabalhoDadosDto>()

const defaultColumns = [
    columnHelper.accessor('id_local', {
        header: 'ID',
        size: 10,
        minSize: 10,
        maxSize: 150
    }),
    columnHelper.accessor('local', {
        header: 'Local de Trabalho'
    }),
    columnHelper.accessor(row => `${row.endereco}, ${row.numero}, ${row.bairro}, ${row.cidade}, ${row.estado}, ${row.cep}`, {
        header: 'Endereço',
    }),
    columnHelper.accessor(row => row.situacao === 1 ? 'Ativo' : 'Inativo', {
        header: 'Situação'
    }),
]

const defaultOrder:any = []

function LocalTrabalhoPage() {
    const [locais_trabalho, setLocaisTrabalho] = useState<iLocalTrabalhoDadosDto[]>([])

    useEffect(() => {
        document.title = "Locais de Trabalho | OB PORTUS"

        const listarLocaisTrabalho = async () => {
            const api = await reqGetNew(ApiEndpoints.painelControle.locais.todos)

            if (api.status === 200) {
                const locaisTrabalhoAux:iLocalTrabalhoDadosDto[] = api.data
                setLocaisTrabalho(locaisTrabalhoAux)
            }
        }

        listarLocaisTrabalho()
    }, [])

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const local = locais_trabalho[idx]
        const link = `/painelControle/localTrabalho/editar?id=${local.id_local}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const local = locais_trabalho[idx]
        const link = `/painelControle/localTrabalho/editar?id=${local.id_local}`

        if (event.button === 0) {
            window.location.assign(link)
        }
    }

    return (
        <div id="main">
            <div className="div-row-space-between">
                <h1 id="titleMain">Locais de Trabalho</h1>
                <Button onClick={() => {
                    // window.open(`/painelControle/empresas/cadastrar`)
                    window.location.assign(`/painelControle/localTrabalho/cadastrar`)
                }}>Cadastrar</Button>
            </div>
            <br></br>
            {<DataTable dados={locais_trabalho} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}
        </div>
    )
}

export default LocalTrabalhoPage