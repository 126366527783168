import React, { useState, useEffect } from 'react'
import styles from '../styles/login/login.module.css'
import obLogo from '../img/ob-logo.png'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { reqPostNoAuth, ApiEndpoints } from '../config/apiClient'
import { setCookie, getCookie, deleteCookie } from '../config/cookies'
import { toast, ToastContainer } from 'react-toastify'

function LoginPage() {
    
    useEffect(() => {
        document.title = 'Login | OB PORTUS'

        const id_user = getCookie('id_user')
        if (id_user !== "") {
            window.location.assign("/dashboard")
        }
    })

    interface User {
        id: number,
        id_colaborador: number,
        nome: string,
        email: string,
        senha: string,
        apelido: string,
        token?: string,
        situacao: number
    }

    const [validated, setValidated] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const handleSubmit = async (event: any) => {
        let formValidated = false
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        } else {
            formValidated = true
        }

        setValidated(true)

        if (formValidated) {
            event.preventDefault()
            event.stopPropagation()
            const data = {
                email: form.email.value,
                senha: form.password.value,
            }
            await verifyUser(data, form)
        }
    }

    async function verifyUser(data: any, form:any) {
        setLoading(true)
        // await new Promise(resolve => setTimeout(resolve, 10000))
        const response = await reqPostNoAuth(ApiEndpoints.login, data)

        if (response.status === 200) {
            toast.success('Usuário logado com sucesso!', {
                containerId: 2,
                theme: 'dark'
            })
            await new Promise(resolve => setTimeout(resolve, 1000))

            let usuario:User = response.data[0]
            usuario.token = response.data[1]

            setCookie('id_user', String(usuario.id))
            setCookie('id_colaborador', String(usuario.id_colaborador))
            setCookie('nome', usuario.nome)
            setCookie('email', usuario.email)
            setCookie('apelido', usuario.apelido)
            setCookie('token', usuario.token!)

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const redirect = urlParams.get('redirect')

            if (redirect != null) {
                const urlNow = getCookie('redirect')
                deleteCookie('redirect')
                window.location.assign(urlNow)
            } else {
                window.location.assign("/dashboard")
            }
        }

        if (response.status === 400) {
            setValidated(false)
            form.password.value = ""
            toast.error('Usuário ou senha inválido!', {
                containerId: 2,
                theme: 'dark'
            })
            setLoading(false)
        }

        if (response.status === 0) {
            toast.warning('Erro de conexão!', {
                containerId: 2,
                theme: 'dark'
            })
            setLoading(false)
        }
    }

    return (
        <div id={styles.page}>
            <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet" />
            <div id={styles['container-principal']}>
                <img id={styles.logo} src={obLogo} alt='logo_nao_encontrada' />
                <p id={styles.title}>PAINEL DE GESTÃO</p>
                <ToastContainer containerId={2} position='bottom-center' />
                <Form id={styles.formLogin} noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3 mt-3">
                        <Form.Control name='email' type="email" placeholder="Endereço e-mail" required/>
                        <Form.Control.Feedback type="invalid">
                            Informe um e-mail válido!
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control name='password' type="password" placeholder="Senha" required/>
                        <Form.Control.Feedback type="invalid">
                            Necessário informar a senha!
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Button id={styles.btnLogin} type="submit" variant='success' disabled={isLoading}>{
                        isLoading ?
                        <Spinner animation="border" role="status"></Spinner>
                        :
                        "Logar"
                    }</Button>
                </Form>
            </div>
        </div>
    )

}

export default LoginPage