import { FormEvent, useEffect, useState } from "react"
import LoadingModal from "../../../components/loadingModal"
import $ from 'jquery'
import 'jquery-mask-plugin'
import getUrlSearchParams from "../../../components/funcGetUrlSearchParams"
import { ApiEndpoints, reqPatch, reqPostNew, reqPut } from "../../../config/apiClient"
import { Button, Col, Form, Row } from "react-bootstrap"
import removeAllErrorMsg from "../../../components/funcRemoveAllErros"
import { toast } from "react-toastify"
import funcSleep from "../../../components/funcSleep"
import iLocalTrabalhoDadosDto from "./dto/dados-local_trabalho.dto"
import iLocalTrabalhoOneDto from "./dto/one-local_trabalho.dto"
import iLocalTrabalhoCadastrarDto from "./dto/cadastrar-local_trabalho.dto"

function LocalTrabalhoFormPage() {
    const [loading, setLoading] = useState<boolean>(false)
    const [localD, setLocalD] = useState<iLocalTrabalhoDadosDto>()

    useEffect(() => {
        $('.cep').mask('00000-000', {
            reverse: false,
            placeholder: '00000-000'
        })
        $('.localizacao').mask('~999.9999999999', {
            reverse: false,
            placeholder: '00.00000',
            translation: {
                "~": {
                    pattern: /[-]/,
                    optional: true,
                }
            }
        })

        document.title = "Cadastrar Local de Trabalho | OB PORTUS"

        const urlDados = getUrlSearchParams()
        const id = urlDados.get("id")

        const consultarLocalTrabalho = async (id:number) => {
            setLoading(true)

            const dados:iLocalTrabalhoOneDto = {
                id_local: Number(id)
            }

            const api = await reqPostNew(ApiEndpoints.painelControle.locais.consultar, dados)

            if (api.status === 200) {
                const local:iLocalTrabalhoDadosDto = api.data
                setLocalD(local)

                let form:any = document.getElementById("formLocal")
                form.id_local.value = local.id_local
                form.local.value = local.local
                form.endereco.value = local.endereco
                form.numero.value = local.numero
                form.bairro.value = local.bairro
                form.cidade.value = local.cidade
                form.cep.value = local.cep
                form.estado.value = local.estado
                form.situacao.value = local.situacao
                form.latitude.value = local.latitude!
                form.longitude.value = local.longitude!
                if (local.utc_app === null) {
                    form.utc_app.value = "0"
                } else {
                    form.utc_app.value = local.utc_app!
                }
                form.latlng_verificado.value = local.latlng_verificado!
            }

            setLoading(false)
        }

        if (id !== null) {
            document.title = "Editar Local de Trabalho | OB PORTUS"
            consultarLocalTrabalho(Number(id))
        }
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        // throw new Error("Function not implemented.")
        const form:any = event.target
        let formValidado = true

        let dados:iLocalTrabalhoCadastrarDto = {
            id_local: Number(form.id_local.value),
            local: form.local.value,
            endereco: form.endereco.value,
            numero: form.numero.value,
            bairro: form.bairro.value,
            cidade: form.cidade.value,
            cep: form.cep.value,
            estado: form.estado.value,
            situacao: Number(form.situacao.value),
            latitude: form.latitude.value,
            longitude: form.longitude.value,
            utc_app: form.utc_app.value,
        }

        if (dados.id_local === null || dados.id_local === 0) {
            form.id_local.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.local === "") {
            form.local.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.endereco === "") {
            form.endereco.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.numero === "") {
            form.numero.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.bairro === "") {
            form.bairro.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.cidade === "") {
            form.cidade.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.cep === "") {
            form.cep.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.estado === "") {
            form.estado.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.latitude === "") {
            dados.latitude = null
        }

        if (dados.longitude === "") {
            dados.longitude = null
        }

        if (dados.utc_app === "0") {
            dados.utc_app = null
        }

        if (!formValidado) {
            return
        }

        console.log(dados)

        if (localD === undefined) {
            cadastrar(dados)
        }

        if (localD !== undefined) {

            if (dados.id_local !== localD.id_local) {
                toast.warning('Não é possível alterar o código do local!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                return
            }

            let dadosAux:any = dados
            editar(dadosAux)
        }
    
    }

    async function cadastrar(dados:iLocalTrabalhoCadastrarDto) {

        setLoading(true)

        const api = await reqPut(ApiEndpoints.painelControle.locais.cadastrar, dados)

        setLoading(false)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Local de Trabalho cadastrado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(1500)

            window.location.assign(`/painelControle/localTrabalho`)
        }

    }

    async function editar(dados: iLocalTrabalhoDadosDto) {
        setLoading(true)

        const api = await reqPatch(ApiEndpoints.painelControle.locais.editar, dados)

        setLoading(false)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Local de Trabalho editado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/painelControle/localTrabalho`)
        }
    }
    
    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <h1 id="titleMain">Local de Trabalho</h1>
            <br></br>
            <Form id='formLocal' onSubmit={handleSubmit} noValidate>
                <Row className="mb-15-forms">
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Código:</Form.Label>
                            <Form.Control name='id_local' type="number" id='id_local' placeholder="#ID"/>
                            <Form.Control.Feedback type="invalid">
                                Informe o código!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Nome do Local de Trabalho:</Form.Label>
                            <Form.Control name='local' type="text" id='local' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Nome!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Situação:</Form.Label>
                            <Form.Select name="situacao" id="situacao" aria-label="Selecione a situação">
                                <option value="1">Ativo</option>
                                <option value="0">Desativado</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Endereço:</Form.Label>
                            <Form.Control name='endereco' type="text" id='endereco' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Endereço!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Número:</Form.Label>
                            <Form.Control name='numero' type="text" id='numero' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Número!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Bairro:</Form.Label>
                            <Form.Control name='bairro' type="text" id='bairro' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Bairro!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>Cidade:</Form.Label>
                            <Form.Control name='cidade' type="text" id='cidade' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Cidade!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>Estado:</Form.Label>
                            <Form.Control name='estado' type="text" id='estado' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Estado!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>CEP:</Form.Label>
                            <Form.Control className="cep" name='cep' type="text" id='cep' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o CEP!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            
                <Row className="mb-15-forms">
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Latitude:</Form.Label>
                            <Form.Control name='latitude' type="text" id='latitude' className="localizacao" placeholder=""/>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Longitude:</Form.Label>
                            <Form.Control name='longitude' type="text" id='longitude' className="localizacao" placeholder=""/>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Timezone:</Form.Label>
                            <Form.Select name="utc_app" id="utc_app" aria-label="Selecione o Timezone">
                                <option value="0">Não Informado</option>
                                <option value="1">America/São_Paulo</option>
                                <option value="2">America/Mato_Grosso</option>
                                <option value="3">America/Acre</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Última Data Localização verificado pelo sistema:</Form.Label>
                            <Form.Control name='latlng_verificado' type="text" id='latlng_verificado' readOnly={true} disabled={true} placeholder=""/>
                        </Form.Group>
                    </Col>
                </Row>

                {
                    localD === undefined &&
                    <Button variant="success" type="submit">Cadastrar</Button>
                }

                {
                    localD !== undefined &&
                    <Button variant="success" type="submit">Editar</Button>
                }
            </Form>
        </div>
    )
}

export default LocalTrabalhoFormPage