import dayjs from "dayjs";
import React, { FormEvent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import removeAllErrorMsg from "../../../../components/funcRemoveAllErros";
import Select from "react-select";
import { getCookie } from "../../../../config/cookies";
import { ApiEndpoints, reqPatch } from "../../../../config/apiClient";
import { toast } from "react-toastify";

interface iPropsModal {
  show: boolean;
  handleClose: any;
  handleNew: any;
  dados: any;
  data_dia: string;
}

export default function ApuracaoModalEscala({
  show,
  handleClose,
  handleNew,
  dados,
  data_dia,
}: iPropsModal) {
  const [loading, setLoading] = useState<boolean>(false);
  const [escalaNova, setEscalaNova] = useState({
    value: "0",
    label: "Selecione a Escala",
  });
  const [registroHistoricoEscala, setRegistroHistoricoEscala] = useState<any>(
    {},
  );
  const [escalasFiltro, setEscalasFiltro] = useState<any[]>([]);
  useEffect(() => {
    const getDados = async () => {
      setLoading(true);

      let escalasFiltroAux = dados.escalas.map((item: any, index: any) => ({
        value: `${item.codigo}`,
        label: `${item.codigo} - ${item.descricao}`,
      }));
      setEscalasFiltro(escalasFiltroAux);

      let escalas_historico = listarHistoricoEscalasDia();
      if (escalas_historico.length > 0) {
        setRegistroHistoricoEscala(escalas_historico[0]);
        for (let escala of dados.escalas) {
          if (escala.codigo === escalas_historico[0]["id_escala_nova"]) {
            setEscalaNova({
              value: `${escala.codigo}`,
              label: `${escala.codigo} - ${escala.descricao}`,
            });
            break;
          }
        }
      }

      setLoading(false);
    };

    getDados();
    // eslint-disable-next-line
  }, [show]);

  function listarHistoricoEscalasDia(): any[] {
    let escalasHistoricoDia: any[] = [];

    for (let registro of dados.escalas_historico) {
      if (registro.data_inicio === data_dia) {
        escalasHistoricoDia.push(registro);
      }
    }

    return escalasHistoricoDia;
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    event.stopPropagation();

    removeAllErrorMsg();

    const form: any = event.target;
    let formValidado = true;

    const data_atual = dayjs();
    let retroativo = 1;
    if (data_atual.format("YYYY-MM-DD") === data_dia) {
      retroativo = 0;
    }

    let dadosCadastrar = {
      id_colaborador: dados.colaborador.id_colaborador,
      id_escala_antiga: dados.colaborador.id_escala.codigo,
      id_escala_nova: Number(escalaNova.value),
      data_inicio: retroativo === 1 ? data_dia : null,
      retroativo: retroativo,
      id_usuario: Number(getCookie("id_user")),
    };

    if (dadosCadastrar.id_escala_nova === 0) {
      form.escala_aux.nextElementSibling.style.display = "block";
      formValidado = false;
    }

    console.log(dadosCadastrar);

    if (!formValidado) {
      return;
    }

    if (Object.values(registroHistoricoEscala).length === 0) { 
      salvarNovaEscala(dadosCadastrar);
    }
    
    if (Object.values(registroHistoricoEscala).length > 0) { 
      handleChangeHistoricoEdit(registroHistoricoEscala, dadosCadastrar);
    }
  }

  async function salvarNovaEscala(dados: any) {
    setLoading(true);
    const api = await reqPatch(ApiEndpoints.colaboradorEscalaAlterar, dados);

    if (api.status === 200) {
      toast.success("Escala alterada com sucesso!", {
        containerId: 1,
        position: "top-center",
        theme: "dark",
      });
      handleNew();
    }
    setLoading(false);
  }
  
  async function handleChangeHistoricoEdit(historico: any, dadosCadastrar:any) {
    let dados: any = {
      id_historico: historico.id,
      id_escala_nova: dadosCadastrar.id_escala_nova,
      ativo: historico.ativo,
    };
    console.log(dados);
    setLoading(true);
    const api = await reqPatch(
      ApiEndpoints.colaboradorEscalaHistoricoAlterar,
      dados,
    );

    if (api.status === 200) {
      toast.success("Escala alterada com sucesso!", {
        containerId: 1,
        position: "top-center",
        theme: "dark",
      });
      handleNew();
    }
    setLoading(false);
  }

  async function handleChangeHistorico(event: any, historico: any) {
    let dados: any = {
      id_historico: historico.id,
      ativo: Number(event.target.value),
    };
    let registroHistoricoEscalaAux = registroHistoricoEscala
    registroHistoricoEscalaAux.ativo = dados.ativo
    setRegistroHistoricoEscala(registroHistoricoEscalaAux)
    console.log(dados);
    setLoading(true);
    const api = await reqPatch(
      ApiEndpoints.colaboradorEscalaHistoricoAlterar,
      dados,
    );

    if (api.status === 200) {
      toast.success("Escala alterada com sucesso!", {
        containerId: 1,
        position: "top-center",
        theme: "dark",
      });
      handleNew();
    }
    setLoading(false);
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="modalApuracaoRight"
      dialogClassName="modalDialogApuracaoRight"
      contentClassName="modalContentApuracaoRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Alterar escala à partir de {dayjs(data_dia).format("DD/MM/YYYY")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          id={`formAlterarEscala${data_dia}`}
          onSubmit={handleSubmit}
          noValidate
        >
          <Row>
            <Col className="col-12">
              <Form.Group>
                <Form.Label>Escala Nova:</Form.Label>
                <Select
                  name="escala"
                  id="escala"
                  value={escalaNova}
                  options={escalasFiltro}
                  placeholder="Selecione a Escala"
                  onChange={(choice: any) => {
                    setEscalaNova(choice);
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#dee2e6",
                    }),
                  }}
                />
                <Form.Control
                  name="escala_aux"
                  type="text"
                  id="escala_aux"
                  placeholder="..."
                  style={{ display: "none" }}
                />
                <Form.Control.Feedback type="invalid">
                  Informe a escala!
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Button type="submit" disabled={loading}>
            {loading ? "Aguarde..." : "Salvar"}
          </Button>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          {Object.values(registroHistoricoEscala).length > 0 && (
            <Form.Group>
              <Form.Label>Status Registro da Escala:</Form.Label>
              <Form.Select
                value={registroHistoricoEscala.ativo === 1 ? "1" : "0"}
                onChange={(event: any) =>
                  handleChangeHistorico(event, registroHistoricoEscala)
                }
                aria-label="Selecione"
              >
                <option value="0">Desativado</option>
                <option value="1">Ativado</option>
              </Form.Select>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
