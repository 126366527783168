import { FormEvent, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import removeAllErrorMsg from "../../../components/funcRemoveAllErros"
import { getCookie } from "../../../config/cookies"
import { ApiEndpoints } from "../../../config/apiClient"
import { toast } from "react-toastify"

interface iPropsModal {
    show: boolean,
    handleClose: any,
    nfDados: any
}

interface iNfNotasDownloadDto {
    id_nota: number
    ambiente: string
    codigo_verificacao: string
    tipo_arquivo: "pdf" | "xml"
}

export default function NfModalDownload ({show, handleClose, nfDados}: iPropsModal) {
    const [loading, setLoading] = useState<boolean>(false)

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dadosDownload:iNfNotasDownloadDto = {
            id_nota: nfDados.id_nota,
            ambiente: nfDados.ambiente.ambiente,
            codigo_verificacao: nfDados.codigo_verificacao,
            tipo_arquivo: form.formato.value,
        }
        console.log(dadosDownload)

        if (!formValidado) {
            return
        }

        download(dadosDownload)
    }

    async function download(dadosDownload:iNfNotasDownloadDto) {
        setLoading(true)

        var token = getCookie('token')
        let request = new XMLHttpRequest();

        request.responseType = "blob";
        request.open("POST", dadosDownload.tipo_arquivo === "pdf" ? ApiEndpoints.nfsDownloadPDF.endpoint : ApiEndpoints.nfsDownloadXML.endpoint, true)
        request.setRequestHeader("Content-Type", "application/json")
        request.setRequestHeader("Authorization", `Bearer ${token}`)
        request.send(JSON.stringify(dadosDownload))

        request.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                const imageURL = window.URL.createObjectURL(this.response)
                const anchor = document.createElement("a")
                anchor.href = imageURL
                anchor.download = `NFe ${nfDados.numero_nf} - ${nfDados.id_cliente.nome}.${dadosDownload.tipo_arquivo === 'pdf' ? 'pdf' : 'xml'}`
                document.body.appendChild(anchor)
                anchor.click()
                toast.success('Download efetuado com sucesso!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            } else if (this.status === 401 || this.status === 400) {
                toast.error('Erro Download!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }
            setLoading(false)
        }

    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Download Relatório</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id={`formDownload`} onSubmit={handleSubmit} noValidate>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Formato de Dados:</Form.Label>
                                <Form.Select name="formato" id="formato" aria-label="Selecione o Formato">
                                    <option value="pdf">PDF</option>
                                    <option value="xml">XML</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Button type="submit" disabled={loading}>{loading ? "Aguarde..." : "Download"}</Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}