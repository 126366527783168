import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { ApiEndpoints, reqGetNew } from "../../../config/apiClient"
import { createColumnHelper } from "@tanstack/react-table"
import DataTable from "../../../components/dataTable/DataTable"
import iUsuarioDadosDto from "./dto/dados-usuarios.dto"

const columnHelper = createColumnHelper<iUsuarioDadosDto>()

const defaultColumns = [
    columnHelper.accessor('id', {
        header: 'ID',
        size: 10,
        minSize: 10,
        maxSize: 150
    }),
    columnHelper.accessor('nome', {
        header: 'Nome'
    }),
    columnHelper.accessor('email', {
        header: 'E-mail'
    }),
    columnHelper.accessor(row => row.situacao === 1 ? 'Ativo' : 'Inativo', {
        header: 'Status'
    }),
]

const defaultOrder:any = []

function UsuariosPage() {

    const [usuarios, setUsuarios] = useState<iUsuarioDadosDto[]>([])

    useEffect(() => {
        document.title = "Usuários | OB PORTUS"

        const listar = async () => {
            const api = await reqGetNew(ApiEndpoints.painelControle.usuarios.todos)

            if (api.status === 200) {
                const aux:iUsuarioDadosDto[] = api.data
                setUsuarios(aux)
            }
        }

        listar()
    }, [])

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = usuarios[idx]
        const link = `/painelControle/usuarios/editar?id=${reg.id}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = usuarios[idx]
        const link = `/painelControle/usuarios/editar?id=${reg.id}`

        if (event.button === 0) {
            window.location.assign(link)
        }
    }

    return (
        <div id="main">

            <div className="div-row-space-between">
                <h1 id="titleMain">Usuários</h1>
                <Button onClick={() => {
                    // window.open(`/painelControle/empresas/cadastrar`)
                    window.location.assign(`/painelControle/usuarios/cadastrar`)
                }}>Cadastrar</Button>
            </div>

            <br></br>

            {<DataTable dados={usuarios} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}

        </div>
    )

}

export default UsuariosPage