import { useState } from 'react'
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    Table,
    getFilteredRowModel,
    ColumnResizeMode,
    getSortedRowModel,
    SortingState,
    getPaginationRowModel
} from '@tanstack/react-table'

function DateTableDoubleClick(props:any) {

    document.body.onmousedown = function(e) { if (e.button === 1) return false; }

    const [searching, setSearching] = useState<string>('')
    const [pageIndex, setPageIndex] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(23)
    const [sorting, setSorting] = useState<SortingState>(props.defaultOrder)
    const [columns] = useState<typeof props.defaultColumns>(() => [...props.defaultColumns])
    const [columnResizeMode, setColumnResizeMode] = useState<ColumnResizeMode>('onChange')

    let table:Table<any> = useReactTable({
        data: props.dados,
        columns: columns,
        columnResizeMode,
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onGlobalFilterChange: setSearching,
        onSortingChange: setSorting,
        state: {
            globalFilter: searching,
            sorting: sorting,
            pagination: {
                pageIndex: pageIndex,
                pageSize: pageSize
            }
        },
    })

    function handleRowMiddleClick(event: any) {
        event.preventDefault()
        event.stopPropagation()
        props.tableMiddleClickhandler(event)
    }

    function handleRowClick(event: any) {
        props.tableDoubleClickhandler(event)
    }

    return (
        <div>
            <div className='divDataTableSearching'>
                <div className='divDataTableSearchingInput'>
                    <input type="text" className='dataTableSearchingInput' value={searching} onChange={e => {setSearching(e.target.value)}} placeholder='Pesquisar' />
                    <span className="material-symbols-outlined" style={{ color: 'black' }}>
                        search
                    </span>
                </div>
                <div className='divDataTableRegs'>
                    <p className='dataTableQtdRegs'>{table.getFilteredRowModel().rows.length} Registros {' '}</p>
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                        className='form-select dataTableQtd'
                    >
                    {[10, 23, 30, 50, 100, 250, 500].map(pageSizeAux => (
                        <option key={pageSizeAux} value={pageSizeAux}>
                        Mostrar {pageSizeAux}
                        </option>
                    ))}
                    </select>
                </div>
                <select
                    value={columnResizeMode}
                    onChange={e => setColumnResizeMode(e.target.value as ColumnResizeMode)}
                    className="border p-2 border-black rounded"
                    style={{
                        display: 'none'
                    }}
                >
                    <option value="onEnd">Resize: "onEnd"</option>
                    <option value="onChange">Resize: "onChange"</option>
                </select>
            </div>
            <br/>
            <div className='divDataTable' style={{ direction: table.options.columnResizeDirection }}>

                    <table
                        {...{
                            style: props.tableSize === 'fixed' ? {
                                width: table.getCenterTotalSize(),
                            } : {
                                width: "100%",
                                display: "table",
                                tableLayout: "auto"
                            },
                            className: 'dataTable'
                          }}
                    >
                        <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th
                                {...{
                                    key: header.id,
                                    colSpan: header.colSpan,
                                    style: {
                                      width: header.getSize(),
                                    },
                                  }}
                                >
                                {header.isPlaceholder
                                    ? null
                                    : (
                                        <div
                                            {...{
                                            className: header.column.getCanSort()
                                                ? 'cursor-pointer select-none'
                                                : '',
                                            onClick: header.column.getToggleSortingHandler(),
                                            }}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {{
                                                asc: ' 🔽',
                                                desc: ' 🔼',
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                    )
                                }
                                <div
                                {...{
                                onDoubleClick: () => header.column.resetSize(),
                                onMouseDown: header.getResizeHandler(),
                                onTouchStart: header.getResizeHandler(),
                                className: `resizer ${
                                    table.options.columnResizeDirection
                                } ${
                                    header.column.getIsResizing() ? 'isResizing' : ''
                                }`,
                                style: {
                                    transform:
                                    columnResizeMode === 'onEnd' &&
                                    header.column.getIsResizing()
                                        ? `translateX(${
                                            (table.options.columnResizeDirection ===
                                            'rtl'
                                            ? -1
                                            : 1) *
                                            (table.getState().columnSizingInfo
                                            .deltaOffset ?? 0)
                                        }px)`
                                        : '',
                                },
                                }}
                            />
                                </th>
                            ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody>
                        {table.getRowModel().rows.map(row => (
                            // <a href={row.getValue('situacao')} className=''>
                                <tr key={row.id} onAuxClick={handleRowMiddleClick} onDoubleClick={handleRowClick} about={String(row.index)} style={{height: '32px'}}>
                                {row.getVisibleCells().map(cell => (
                                    <td
                                        {...{
                                            key: cell.id,
                                            style: {
                                            width: cell.column.getSize(),
                                            },
                                        }}
                                    >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                                </tr>
                            // </a>
                        ))}
                        </tbody>
                    </table>
   
            </div>
            <br />
            <div className='divDataTablePages'>
                <div>
                    <button
                        type="button" className="btn btn-outline-secondary border-0"
                        disabled={!table.getCanPreviousPage()} onClick={() => setPageIndex(0)}
                    >Primeira</button>
                    {' '}
                    <button
                        type="button" className="btn btn-outline-primary border-0"
                        disabled={!table.getCanPreviousPage()} onClick={() => setPageIndex(pageIndex - 1)}
                    >Anterior</button>
                    {' '}
                    <button
                        type="button" className="btn btn-outline-primary border-0"
                        disabled={!table.getCanNextPage()} onClick={() => setPageIndex(pageIndex + 1)}
                    >Proxima</button>
                    {' '}
                    <button
                        type="button" className="btn btn-outline-secondary border-0"
                        disabled={!table.getCanNextPage()} onClick={() => setPageIndex(table.getPageCount() - 1)}
                    >Última</button>
                </div>
                <div>
                    <span className="flex items-center gap-1">
                    Ir para: {' '}
                    <input
                        type="number"
                        value={pageIndex + 1}
                        onChange={e => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        setPageIndex(page)
                        }}
                        className="border p-1 rounded w-16"
                    />
                    </span>
                    <span className="flex items-center gap-1">
                    <strong>
                        {' '} Página {' '}
                        {table.getState().pagination.pageIndex + 1} de {' '}
                        {table.getPageCount()}
                    </strong>
                    </span>
                </div>
            </div>
        </div>
    )

}

export default DateTableDoubleClick