import { Button, Col, Form, Modal, Row } from "react-bootstrap";

interface iPropsModal {
    show: boolean,
    handleClose: any,
    dados: any
}

export default function NfModalEmitir({show, handleClose, dados}: iPropsModal) {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>Emitir NOTA FISCAL</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className="col-6">
                        <Form.Group className="mt-0 mb-3">
                            <Form.Label>XML Enviado</Form.Label>
                            <Form.Control as="textarea" rows={8} name='enviado' id='enviado' autoComplete='off' />
                        </Form.Group>
                    </Col>
                    <Col className="col-6">
                        <Form.Group className="mt-0 mb-3">
                            <Form.Label>XML Recebido</Form.Label>
                            <Form.Control as="textarea" rows={8} name='recebido' id='recebido' autoComplete='off' />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={() => {}} className="div-row-space-between">Emitir NF <span className="material-symbols-outlined" style={{fontSize: "1.2em", marginLeft: "10px", paddingBottom: "1px"}}>send</span></Button>
                <Button variant="secondary" onClick={handleClose}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}