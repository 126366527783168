import * as React from "react";
import type { MapConfig } from "./ApuracaoModalMarcacoes";

type ControlPanelProps = {
  mapConfigs: MapConfig[];
  mapConfigId: string;
  onMapConfigIdChange: (id: string) => void;
};

export default function ControlPanel({
  mapConfigs,
  mapConfigId,
  onMapConfigIdChange,
}: ControlPanelProps) {
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        color: "white",
        backgroundColor: "rgba(0,0,0,.5)",
      }}
    >
      <div>
        <label>Tipo do Mapa: &nbsp;</label>
        <select
          value={mapConfigId}
          onChange={(ev) => onMapConfigIdChange(ev.target.value)}
        >
          {mapConfigs.map(({ id, label }) => (
            <option key={id} value={id}>
              {label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
