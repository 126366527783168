import { FormEvent, useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import removeAllErrorMsg from "../../../components/funcRemoveAllErros"
import iDefinicoesAppDadosDto from "./dto/dados-definicoes_app.dto"
import { toast } from "react-toastify"
import { ApiEndpoints, reqGetNew, reqPatch } from "../../../config/apiClient"
import LoadingModal from "../../../components/loadingModal"

function DefinicoesAppPage() {

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        document.title = "Definições APP | OB PORTUS"

        const consultarDados = async () => {
            setLoading(true)

            const api = await reqGetNew(ApiEndpoints.painelControle.definicoesApp.dados)
    
            if (api.status === 200) {
                const reg:iDefinicoesAppDadosDto = api.data
    
                let form:any = document.getElementById("form")
                form.telefone_suporte_visivel.value = reg.telefone_suporte_visivel
                form.telefone_suporte.value = reg.telefone_suporte
                form.versao_android.value = reg.versao_android
                form.versao_ios.value = reg.versao_ios
                form.versao_api.value = reg.versao_api
                form.email_send.value = reg.email_send
            }
    
            setLoading(false)
        }

        consultarDados()
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        // throw new Error("Function not implemented.")
        const form:any = event.target
        let formValidado = true

        let dados:iDefinicoesAppDadosDto = {
            id: 1,
            telefone_suporte_visivel: form.telefone_suporte_visivel.value,
            telefone_suporte: form.telefone_suporte.value,
            versao_android: form.versao_android.value,
            versao_ios: form.versao_ios.value,
            versao_api: form.versao_api.value,
            email_send: form.email_send.value
        }

        if (dados.telefone_suporte_visivel === 1) {
            if (dados.telefone_suporte === "") {
                form.telefone_suporte.nextElementSibling.style.display = "block"
                formValidado = false
            }
        }

        if (dados.versao_android === "") {
            form.versao_android.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.versao_ios === "") {
            form.versao_ios.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.versao_api === "") {
            form.versao_api.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.email_send === "") {
            form.email_send.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (!formValidado) {
            return
        }

        console.log(dados)

        editar(dados)

    }

    async function editar(dados: iDefinicoesAppDadosDto) {
        setLoading(true)

        const api = await reqPatch(ApiEndpoints.painelControle.definicoesApp.editar, dados)

        setLoading(false)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Definições Alteradas com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }
    }

    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <h1 id="titleMain">Definições do APP</h1>
            <br></br>
            <Form id='form' onSubmit={handleSubmit} noValidate>

                <Row className="mb-15-forms">
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>Telefone do Suporte:</Form.Label>
                            <Form.Control name='telefone_suporte' type="text" id='telefone_suporte' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o telefone!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-2">
                        <Form.Group>
                            <Form.Label>Telefone Suporte Visível?</Form.Label>
                            <Form.Select name="telefone_suporte_visivel" id="telefone_suporte_visivel" aria-label="Selecione a opção">
                                <option value="1">Sim</option>
                                <option value="0">Não</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>Versão Android:</Form.Label>
                            <Form.Control name='versao_android' type="text" id='versao_android' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe a versão do Android!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>Versão IOS:</Form.Label>
                            <Form.Control name='versao_ios' type="text" id='versao_ios' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe a versão do IOS!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>Versão ApI:</Form.Label>
                            <Form.Control name='versao_api' type="text" id='versao_api' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe a versão da API!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>E-mail para envio de relatórios automáticos:</Form.Label>
                            <Form.Control name='email_send' type="text" id='email_send' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o E-mail!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="success" type="submit">Editar</Button>

            </Form>
        </div>
    )

}

export default DefinicoesAppPage