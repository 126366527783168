import { FormEvent, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import removeAllErrorMsg from "../../../../components/funcRemoveAllErros";
import { ApiEndpoints, reqPatch, reqPostNew } from "../../../../config/apiClient";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { getCookie } from "../../../../config/cookies";
import { iDiaApuracao } from "../../dto/dados-apuracao.dto";

interface iPropsModal {
    show: boolean,
    handleClose: any,
    handleNew: any,
    handleDelete: any,
    dados: any,
    data_dia: string,
    dia: iDiaApuracao,
    observacao: any
}

export default function ApuracaoModalObservacao ({show, handleClose, handleNew, handleDelete, dados, data_dia, dia, observacao}: iPropsModal) {

    const [loading, setLoading] = useState<boolean>(false)

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dadosCadastar:any = {
            id_dia: dia.id_dia_apuracao === null ? 0 : dia.id_dia_apuracao,
            id_observacao: observacao === null ? null : observacao.id_observacao,
            data_criacao: null,
            data_dia: data_dia,
            id_motivo: Number(form.justificativa.value),
            observacao: form.observacao.value,
            id_colaborador: dados.dados.colaborador.id_colaborador,
            id_usuario: Number(getCookie('id_user'))
        }

        if (dadosCadastar.id_motivo === 0 && dadosCadastar.observacao === "") {
            form.justificativa.nextElementSibling.style.display = "block"
            form.observacao.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dadosCadastar.id_motivo === 0) {
            dadosCadastar.id_motivo = null
        }

        if (dadosCadastar.observacao === "") {
            dadosCadastar.observacao = null
        }

        console.log(dadosCadastar)

        if (!formValidado) {
            return
        }

        cadastrar(dadosCadastar)
    }

    async function cadastrar(dadosCadastrar:any) {
        setLoading(true)

        const api = await reqPatch(ApiEndpoints.apuracaoObservacaoRegistrar, dadosCadastrar)

        setLoading(false)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Observação registrada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            handleNew(api.data)
        }
    }

    async function deletar() {

        if (!window.confirm("Tem certeza que deseja apagar essa observação?")) {
            return
        }

        let dadosCadastrar = {
            id_observacao: observacao.id_observacao
        }

        setLoading(true)

        const api = await reqPostNew(ApiEndpoints.apuracaoObservacaoDeletar, dadosCadastrar)

        setLoading(false)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Observação deletada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            handleDelete(api.data)
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="modalApuracaoRight"
            dialogClassName="modalDialogApuracaoRight"
            contentClassName="modalContentApuracaoRight"
        >
            <Modal.Header closeButton>
                <Modal.Title>Observação Dia - {dayjs(data_dia).format("DD/MM/YYYY")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id={`formObservacap${data_dia}`} onSubmit={handleSubmit} noValidate>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Justificativa:</Form.Label>
                                <Form.Select name="justificativa" id="justificativa" defaultValue={observacao === null ? "0" : `${observacao.id_motivo.id_justificativa}`} aria-label="Selecione o Tipo">
                                    <option value="0">Não Informado</option>
                                    {
                                        dados.dados.ocorrencias_dados.justificativas.map((reg:any, index:any) => (
                                            <option key={index} value={reg.id_justificativa}>{reg.id_justificativa}-{reg.descricao}</option>
                                        ))
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Informe a Justificativa!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Observação:</Form.Label>
                                <Form.Control name="observacao" id="observacao" defaultValue={observacao === null ? "" : observacao.observacao} as="textarea" aria-label="" />
                                <Form.Control.Feedback type="invalid">
                                    Informe a Observação!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Button type="submit" disabled={loading}>{loading ? "Aguarde..." : "Registrar"}</Button>
                    <br></br>
                    <br></br>
                    {
                        observacao !== null &&
                        <Button variant="danger" disabled={loading} onClick={() => deletar()}>{loading ? "Aguarde..." : "Deletar"}</Button>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )

}
