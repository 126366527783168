import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { Button, Form, Spinner } from "react-bootstrap"
import Select from 'react-select'
import { defineValueInInput, getValueFromInput } from "../../components/funcForInput"
import { ApiEndpoints, reqGetNew, reqPostNew } from "../../config/apiClient"
import { iApuracaoConsultarDadosFiltroDto } from "../apuracao/dto/dados-consultar-filtro.dto"
import { createColumnHelper } from "@tanstack/react-table"
import DateTableDoubleClick from "../../components/dataTable/DataTableDoubleClick"

const customSelectStylesConsultarPontoDigital = {
    control: (baseStyles:any, state:any) => ({
        ...baseStyles,
        width: '200px',
        borderColor: "#dee2e6",
        minHeight: '39px',
        maxHeight: '120px',
        marginRight: '5px',
        fontSize: '14px'
    }),
    valueContainer: (provided:any, state:any) => ({
        ...provided,
        minHeight: '30px',
        maxHeight: '120px',
        overflowY: 'auto',
        fontSize: '14px'
    }),
    input: (provided:any, state:any) => ({
        ...provided,
        margin: '0px',
        fontSize: '14px'
    }),
    indicatorSeparator: (state:any) => ({
        display: 'none',
        fontSize: '14px'
    }),
    indicatorsContainer: (provided:any, state:any) => ({
        ...provided,
        minHeight: '39px',
        maxHeight: '120px',
        fontSize: '14px'
    }),
    menu: (provided:any, state:any) => ({
        ...provided,
        fontSize: '14px'
    }),
}

const customSelectStylesConsultarPontoDigitalLarge = {
    control: (baseStyles:any, state:any) => ({
        ...baseStyles,
        width: '400px',
        borderColor: "#dee2e6",
        minHeight: '39px',
        maxHeight: '120px',
        marginRight: '5px',
        fontSize: '14px'
    }),
    valueContainer: (provided:any, state:any) => ({
        ...provided,
        minHeight: '30px',
        maxHeight: '120px',
        overflowY: 'auto',
        fontSize: '14px'
    }),
    input: (provided:any, state:any) => ({
        ...provided,
        margin: '0px',
        fontSize: '14px'
    }),
    indicatorSeparator: (state:any) => ({
        display: 'none',
        fontSize: '14px',
    }),
    indicatorsContainer: (provided:any, state:any) => ({
        ...provided,
        minHeight: '39px',
        maxHeight: '120px',
        fontSize: '14px'
    }),
    menu: (provided:any, state:any) => ({
        ...provided,
        fontSize: '14px'
    }),
}

const inputDatas:React.CSSProperties = {
    height: "41px",
    marginRight: "5px"
}

interface Marcacao {
    id_marcacao:number
    id_validacao:number
    data_criacao:string
    data_dia:string
    tipo:number
    id_colaborador:number
    matricula:number
    nome_completo:string
    longitude:string
    latitude:string
    data_recebido:string
    internet:string
    conexao:string
    intervalo:number
    obs_intervalo:string
    id_tmz:number
    status_m:string
    dentro_tolerancia:number
    minutos_tolerancia:string
    id_escala:number
    escala:string
    tipo_diurna_noturna:number
    intervalo_registrar:number
    distancia_local_trabalho:string
}

const columnHelper = createColumnHelper<Marcacao>()

const defaultColumns = [
    columnHelper.accessor(row => `${dayjs(row.data_criacao).format("DD/MM/YYYY HH:mm:ss")} #${row.id_marcacao}`, {
        header: 'Data'
    }),
    columnHelper.accessor(row => row.tipo === 0 ? 'Entrada' : 'Saída', {
        header: 'Tipo',
    }),
    columnHelper.accessor(row => `${row.matricula} - ${row.nome_completo}`, {
        header: 'Colaborador',
    }),
    columnHelper.accessor(row => `${row.id_escala} - ${row.escala}`, {
        header: 'Escala',
    }),
    columnHelper.accessor(row => `${row.internet}`, {
        header: 'Internet',
    }),
    columnHelper.accessor(row => `${row.conexao}`, {
        header: 'Conexão',
    }),
    columnHelper.accessor(row => `${row.data_recebido !== null ? dayjs(row.data_recebido).format("DD/MM/YYYY HH:mm:ss") : ''}`, {
        header: 'Data recebido',
    }),
    columnHelper.accessor(row => `${row.intervalo === null ? '' : row.intervalo === 1 ? 'Sim' : 'Não'}`, {
        header: 'Almoço',
    }),
    columnHelper.accessor(row => `${row.latitude === null ? '' : `${row.latitude}/${row.longitude}`}`, {
        header: 'Lat/Long',
    }),
]

const defaultOrder:any[] = []

export default function MarcacoesPage() {

    const [loading, setLoading] = useState<boolean>(false)
    const [dados, setDados] = useState<Marcacao[]>([])

    const tiposFiltro = [
        {
            "value": "0",
            "label": "Entrada"
        },
        {
            "value": "1",
            "label": "Saída"
        }
    ]
    const [tiposSelected, setTiposSelected] = useState<any>([])

    const [empresasFiltro, setEmpresasFiltro] = useState<any>([])
    const [empresasFiltroSelected, setEmpresasFiltroSelected] = useState<any>([])

    const [centrosCustoFiltro, setCentrosCustoFiltro] = useState<any[]>([])
    const [centrosCustoFiltroSelected, setCentrosCustoFiltroSelected] = useState<any[]>([])

    const [colaboradoresFiltro, setColaboradoresFiltro] = useState<any>([])
    const [colaboradoresFiltroSelected, setColaboradoresFiltroSelected] = useState<any>([])

    useEffect(() => {

        document.title = "Marcações | OB PORTUS"

        const getDados = async () => {
            const data_atual = dayjs()
            const data_inicial = data_atual.startOf('month').format("YYYY-MM-DD")
            const data_final = data_atual.endOf('month').format("YYYY-MM-DD")
            defineValueInInput('data_inicial', data_inicial)
            defineValueInInput('data_final', data_final)

            const api = await reqGetNew(ApiEndpoints.apuracaoConsultarFiltroDados)
            if (api.status === 200) {
                const dados:iApuracaoConsultarDadosFiltroDto = api.data

                let empresasAux = dados.empresas.map((item:any, index:any) => (
                    {
                        "value": `${item.codigo}`,
                        "label": `${item.codigo} - ${item.nome}`
                    }
                ))
                setEmpresasFiltro(empresasAux)

                let centrosAux = dados.centro_custo.map((item:any, index:any) => (
                    {
                        "value": `${item.codigo}`,
                        "label": `${item.codigo} - ${item.centro_custo}`
                    }
                ))
                setCentrosCustoFiltro(centrosAux)

                let colaboradoresAux = dados.colaboradores.map((item:any, index:any) => (
                    {
                        "value": `${item.id_colaborador}`,
                        "label": `${item.matricula} - ${item.nome_completo}`
                    }
                ))
                setColaboradoresFiltro(colaboradoresAux)
            }
        }

        getDados()

    }, [])

    async function handleFiltrar() {
        const filtro = {
            data_inicial: getValueFromInput("data_inicial"),
            data_final: getValueFromInput("data_final"),
            id_tipo: tiposSelected.map((item:any, index:any) => (Number(item.value))),
            id_empresa: empresasFiltroSelected.map((item:any, index:any) => (Number(item.value))),
            id_centro_custo: centrosCustoFiltroSelected.map((item:any, index:any) => (Number(item.value))),
            id_colaborador: colaboradoresFiltroSelected.map((item:any, index:any) => (Number(item.value))),
        }

        setLoading(true)
        const api = await reqPostNew(ApiEndpoints.pontoMarcacoes, filtro)
        
        if (api.status === 200) {
            setDados(api.data)
        }
        setLoading(false)
    }

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = dados[idx]
        if (reg.latitude === null) {
            return
        }
        if (event.button === 1) {
            window.open(`https://www.google.com/maps/search/?api=1&query=${reg.latitude},${reg.longitude}`)
        }
    }

    function tableDoubleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = dados[idx]
        if (reg.latitude === null) {
            return
        }
        window.open(`https://www.google.com/maps/search/?api=1&query=${reg.latitude},${reg.longitude}`)
    }

    return (
        <div id="main">
            <h1 id='titleMain'>Marcações</h1>
            <br></br>
            <div className='div-row'>
                <Form.Control name='data_inicial' type="date" id='data_inicial' placeholder="Data Inicial" style={inputDatas} />
                <Form.Control name='data_final' type="date" id='data_final' placeholder="Data Final" style={inputDatas} />
                <Select name='ids_tipos' id='ids_tipos' options={tiposFiltro} defaultValue={tiposSelected} placeholder="Tipos" isMulti closeMenuOnSelect={false} onChange={
                    (choice) => {
                        setTiposSelected(choice)
                    }
                } styles={customSelectStylesConsultarPontoDigital} />
                <Select name='ids_empresas' id='ids_empresas' options={empresasFiltro} defaultValue={empresasFiltroSelected} placeholder="Empresas" isMulti closeMenuOnSelect={false} onChange={
                    (choice) => {
                        setEmpresasFiltroSelected(choice)
                    }
                } styles={customSelectStylesConsultarPontoDigital} />
                <Select name='ids_centro_custo' id='ids_centro_custo' options={centrosCustoFiltro} defaultValue={centrosCustoFiltroSelected} value={centrosCustoFiltroSelected} placeholder="Centros de Custo" isMulti closeMenuOnSelect={false} onChange={
                    (choice:any) => {
                        setCentrosCustoFiltroSelected(choice)
                    }
                } styles={customSelectStylesConsultarPontoDigitalLarge} />
                <Select name='ids_colaboradores' id='ids_colaboradores' options={colaboradoresFiltro} defaultValue={colaboradoresFiltroSelected} placeholder="Colaboradores" isMulti closeMenuOnSelect={false} onChange={
                    (choice) => {
                        setColaboradoresFiltroSelected(choice)
                    }
                } styles={customSelectStylesConsultarPontoDigitalLarge} />
                <Button onClick={() => { handleFiltrar() }} variant='primary' style={{height: '40px'}} className='div-row-space-between'>Filtrar <span className="material-symbols-outlined">filter_alt</span></Button>
            </div>
            <br></br>
            {loading && <Spinner />}
            {!loading && <DateTableDoubleClick dados={dados} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableDoubleClickhandler={tableDoubleClickhandler} defaultOrder={defaultOrder} />}
        </div>
    )
}