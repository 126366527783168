import { FormEvent, useEffect, useState } from "react";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import removeAllErrorMsg from "../../components/funcRemoveAllErros";
import { defineValueInInput, getValueFromInput } from "../../components/funcForInput";
import dayjs from "dayjs";
import consultarStyle from '../apuracao/styles/consultar.module.css'
import { ApiEndpoints, reqGetNew, reqPost, reqPostDownloadFile, reqPostNew } from "../../config/apiClient";
import { createColumnHelper } from "@tanstack/react-table";
import DateTableSelected from "../../components/dataTable/DataTableSelected";
import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx"
import { observer } from "mobx-react-lite"
import FolhaPontoModalFiltroPage from "./FolhaPontoModalFiltroPage";
import { ColaboradoresFiltroFolhaPontoDto } from "./dto/colaboradores-filtro-avancado.dto";

function RowDiv({children}:any) {
    return (
        <div className="div-row">
            {children}
        </div>
    )
}

interface iDadosDownload {
    id_colaborador: number[],
    data_inicial: string,
    data_final: string,
    mostrar_totais: number,
    apenas_totais: number,
    formato_dados: string
}

interface ColaboradorFiltro {
    id_colaborador:number
    matricula:number
    nome_completo:string
    empresa:string
    centro_custo:string
    cargo:string
    situacao:string
    data_admissao: string | null
    data_demissao: string | null
}

const columnHelper = createColumnHelper<ColaboradorFiltro>()

const defaultColumns = [
    columnHelper.accessor(row => '', {
        id: 'select',
        header: ({table}) => (
            <input type="checkbox" checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />
        ),
        cell: ({row}) => (
            <input type="checkbox" checked={row.getIsSelected()} disabled={!row.getCanSelect()} onChange={row.getToggleSelectedHandler()} />
        ),
        size: 10,
        enableSorting: false,
    }),
    columnHelper.accessor(row => `${row.matricula} - ${row.nome_completo}`, {
        header: 'Colaborador',
    }),
    columnHelper.accessor('empresa', {
        header: 'Empresa',
    }),
    columnHelper.accessor('centro_custo', {
        header: 'Centro de Custo',
    }),
    columnHelper.accessor('cargo', {
        header: 'Cargo',
    }),
    columnHelper.accessor(row => `${row.situacao} AD-${dayjs(row.data_admissao).format("DD/MM/YYYY")} ${row.data_demissao === null ? '' : `DM-${dayjs(row.data_demissao).format("DD/MM/YYYY")}`}`, {
        header: 'Situação',
        id: 'situacao'
    }),
]

const defaultOrder:any[] = []

class DownloadState {
    downloadQtd:number = 1
    downloadQtdProgresso:number = 0
    constructor() {
        makeAutoObservable(this)
    }
    atualizarState(qtd:number, progresso:number) {
        this.downloadQtd = qtd
        this.downloadQtdProgresso = progresso
    }
}

const downloadState = new DownloadState()

interface iPropsDownloadStateView {
    downloadState: DownloadState
}

const DownloadStateView = observer(({ downloadState }:iPropsDownloadStateView) => {
    return (
        <div style={{marginLeft: "25px"}}>
            <p>Downloading {downloadState.downloadQtdProgresso} de {downloadState.downloadQtd}</p>
        </div>
    )
})

export default function FolhaPontoPage() {

    useEffect(() => {
        document.title = "Folha de Ponto | OB PORTUS"

        let data_atual = dayjs()
        defineValueInInput('data_inicial', data_atual.startOf('month').subtract(1, 'day').startOf('month').format("YYYY-MM-DD"))
        defineValueInInput('data_final', data_atual.startOf('month').subtract(1, 'day').endOf('month').format("YYYY-MM-DD"))

        const getDados = async () => {
            const api = await reqGetNew(ApiEndpoints.apuracaoConsultarFiltroDados)
            if (api.status === 200) {
                setDadosFiltro(api.data)
                console.log(api.data)
                
            }
            const apiCols = await reqPostNew(ApiEndpoints.apuracaoFolhaPontoColaboradores, filtroAvancado)
            if (apiCols.status === 200) {
                let cols:any[] = apiCols.data
                let aux:ColaboradorFiltro[] = cols.map((reg:any) => ({
                    id_colaborador: reg.id_colaborador,
                    matricula: reg.matricula,
                    nome_completo: reg.nome_completo,
                    empresa: reg.id_empresa === null ? '' : reg.id_empresa.nome,
                    centro_custo: reg.id_centro_custo === null ? '' : `${reg.id_centro_custo.codigo} - ${reg.id_centro_custo.centro_custo}`,
                    cargo: reg.id_cargo === null ? '' : reg.id_cargo.descricao,
                    situacao: reg.id_situacao === null ? '' : reg.id_situacao.descricao,
                    data_admissao: reg.data_admissao === null ? null : reg.data_admissao,
                    data_demissao: reg.data_demissao === null ? null : reg.data_demissao,
                }))
                console.log(aux)
                setColaboradoresFiltro(aux)
            }
            setLoading(false)
            setLoadingCols(false)
        }
        getDados()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [loading, setLoading] = useState<boolean>(true)
    const [loadingCols, setLoadingCols] = useState<boolean>(true)
    const [showModalFiltro, setShowModalFiltro] = useState<boolean>(false)
    const [dadosFiltro, setDadosFiltro] = useState<any>({})
    const [colaboradoresFiltro, setColaboradoresFiltro] = useState<ColaboradorFiltro[]>([])
    const [filtroAvancado, setFiltroAvancado] = useState<ColaboradoresFiltroFolhaPontoDto>({
        empresas: [],
        situacoes: [],
        cargos: [],
        centros_custo: [],
        admissao_inicial: null,
        admissao_final: null,
        demissao_inicial: null,
        demissao_final: null,
        mostrar_demitidos: 0
    })
    const [filtroAvancadoStatus, setFiltroAvancadoStatus] = useState<boolean>(false)

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dadosDownload:iDadosDownload = {
            data_inicial: form.data_inicial.value,
            data_final: form.data_final.value,
            formato_dados: form.formato.value,
            apenas_totais: form.apenas_totais.checked === true ? 1 : 0,
            mostrar_totais: form.mostrar_totais.checked === true ? 1 : 0,
            id_colaborador: getColaboadoresSelected(),
        }
        console.log(dadosDownload)

        if (dadosDownload.data_inicial === "") {
            form.data_inicial.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dadosDownload.data_final === "") {
            form.data_final.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dadosDownload.id_colaborador.length === 0) {
            formValidado = false
            toast.warning('Nenhum Colaborador selecionado!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        if (!formValidado) {
            return
        }

        if (dadosDownload.id_colaborador.length === 1 || dadosDownload.apenas_totais === 1) {
            downloadUnico(dadosDownload)
        }

        if (dadosDownload.id_colaborador.length > 1 && dadosDownload.apenas_totais === 0) {
            downloadVarios(dadosDownload)
        }
    }

    async function downloadUnico(dados:iDadosDownload) {

        setLoading(true)
        
        let dadosAux:any = {
            id_colaborador: String(dados.id_colaborador[0]),
            data_inicial: dados.data_inicial,
            data_final: dados.data_final,
            formato_dados: dados.formato_dados,
            apenas_totais: dados.apenas_totais,
            mostrar_totais: dados.mostrar_totais,
        }
        console.log(dadosAux)
        
        let arquivo:string = ''

        if (dados.apenas_totais === 1) {
            arquivo = `Totais-Folha-Ponto-${dayjs(dados.data_inicial).format("MM-YYYY")}.${dados.formato_dados === 'pdf' ? 'pdf' : 'xlsx'}`
            dadosAux.id_colaborador = dados.id_colaborador.toString()
        }
        if (dados.apenas_totais === 0) {
            let colaborador = colaboradoresFiltro.filter((reg) => reg.id_colaborador === dados.id_colaborador[0])[0]
            arquivo = `Folha-Ponto-${colaborador.matricula}-${colaborador.nome_completo}-${dayjs(dados.data_inicial).format("MM-YYYY")}.${dados.formato_dados === 'pdf' ? 'pdf' : 'xlsx'}`
        }

        let response = await reqPostDownloadFile(ApiEndpoints.apuracaoFolhaPonto, dadosAux, arquivo)
        if (response.status === 200) {
            toast.success('Aguarde o termino do download...', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        } else {
            toast.error('Erro no download, tente mais tarde ou verifique com o suporte!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }
        setLoading(false)

    }

    async function downloadVarios(dados:iDadosDownload) {

        setLoading(true)

        downloadState.atualizarState(dados.id_colaborador.length, 0)
        let arquivos:string[] = []
        let erro = false

        for (let id_colaborador of dados.id_colaborador) {
            let dadosAux:any = {
                id_colaborador: id_colaborador,
                data_inicial: dados.data_inicial,
                data_final: dados.data_final,
                formato_dados: dados.formato_dados,
                apenas_totais: dados.apenas_totais,
                mostrar_totais: dados.mostrar_totais,
            }
            let api = await reqPost(ApiEndpoints.apuracaoFolhaPontoArquivo, dadosAux)
            if (api.status === 200) {
                arquivos.push(api.data.arquivo)
                downloadState.atualizarState(dados.id_colaborador.length, downloadState.downloadQtdProgresso + 1)
            } else {
                toast.error('Erro no download, tente mais tarde ou verifique com o suporte!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                erro = true
                break
            }
        }

        if (erro) {
            setLoading(false)
            downloadState.atualizarState(1, 0)
            return
        }

        let dadosAux:any = {
            arquivos: arquivos
        }

        let arquivo:string = ''

        arquivo = `Folhas-Ponto-${dayjs(dados.data_inicial).format("MM-YYYY")}.zip`

        console.log(dadosAux)

        let response = await reqPostDownloadFile(ApiEndpoints.apuracaoFolhaPontoCompactar, dadosAux, arquivo)
        if (response.status === 200) {
            toast.success('Aguarde o termino do download...', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        } else {
            toast.error('Erro no download, tente mais tarde ou verifique com o suporte!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        setLoading(false)
        downloadState.atualizarState(1, 0)

    }

    function handleLoopDatasFiltro(acao:'proximo' | 'voltar') {
        let data_inicial = getValueFromInput('data_inicial')
        let data_final = getValueFromInput('data_final')
        if (data_inicial === '' || data_final === '') {
            return
        }

        if (acao === 'proximo') {
            let inicial = dayjs(data_inicial).add(1, 'month').startOf('month')
            let final = dayjs(data_final).add(1, 'month').endOf('month')
            defineValueInInput('data_inicial', inicial.format("YYYY-MM-DD"))
            defineValueInInput('data_final', final.format("YYYY-MM-DD"))
        }

        if (acao === 'voltar') {
            let inicial = dayjs(data_inicial).subtract(1, 'month').startOf('month')
            let final = dayjs(data_final).subtract(1, 'month').endOf('month')
            defineValueInInput('data_inicial', inicial.format("YYYY-MM-DD"))
            defineValueInInput('data_final', final.format("YYYY-MM-DD"))
        }
    }

    function getColaboadoresSelected():any[] {
        let rows = getValueFromInput('rowsSelected')
        let colaboradores:any []= JSON.parse(rows)
        colaboradores = colaboradores.map((reg:any, idx:any) => (colaboradoresFiltro[Number(reg[0])].id_colaborador))
        return colaboradores
    }

    async function handleAplicarFiltro(filtro:ColaboradoresFiltroFolhaPontoDto) {
        setLoading(true)
        setLoadingCols(true)
        setFiltroAvancado(filtro)
        setShowModalFiltro(false)

        if (
            (filtro.cargos.length > 0 || filtro.empresas.length > 0 || filtro.centros_custo.length > 0 || filtro.situacoes.length > 0) ||
            (filtro.admissao_inicial !== null || filtro.admissao_final !== null || filtro.demissao_inicial !== null || filtro.demissao_final !== null || filtro.mostrar_demitidos === 1)
        ) {
            setFiltroAvancadoStatus(true)
        } else {
            setFiltroAvancadoStatus(false)
        }

        const apiCols = await reqPostNew(ApiEndpoints.apuracaoFolhaPontoColaboradores, filtro)
        if (apiCols.status === 200) {
            let cols:any[] = apiCols.data
            let aux:ColaboradorFiltro[] = cols.map((reg:any) => ({
                id_colaborador: reg.id_colaborador,
                matricula: reg.matricula,
                nome_completo: reg.nome_completo,
                empresa: reg.id_empresa === null ? '' : reg.id_empresa.nome,
                centro_custo: reg.id_centro_custo === null ? '' : `${reg.id_centro_custo.codigo} - ${reg.id_centro_custo.centro_custo}`,
                cargo: reg.id_cargo === null ? '' : reg.id_cargo.descricao,
                situacao: reg.id_situacao === null ? '' : reg.id_situacao.descricao,
                data_admissao: reg.data_admissao === null ? null : reg.data_admissao,
                data_demissao: reg.data_demissao === null ? null : reg.data_demissao,
            }))
            console.log(aux)
            setColaboradoresFiltro(aux)
        }
        setLoadingCols(false)
        setLoading(false)
    }

    return (
        <div id="main">
            {
                Object.values(dadosFiltro).length > 0 &&
                <FolhaPontoModalFiltroPage show={showModalFiltro} handleAplicarFiltro={handleAplicarFiltro} handleClose={() => {setShowModalFiltro(false)}} dadosFiltro={dadosFiltro} />
            }
            <h1 id="titleMain">Relatório - Folha de Ponto</h1>
            <br></br>
            <Form id={`formDownload`} onSubmit={handleSubmit} noValidate>
                <RowDiv>
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>Formato de Dados:</Form.Label>
                            <Form.Select name="formato" id="formato" aria-label="Selecione o Formato">
                                <option value="excel">Excel</option>
                                <option value="pdf">PDF</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </RowDiv>
                <br></br>
                <RowDiv>
                    <Col className="col-2">
                        <div>
                            <label style={{marginRight: "10px"}}>Mostrar calculo de Horas?</label>
                            <input name='mostrar_totais' type="checkbox" id='mostrar_totais' defaultChecked/>
                        </div>
                    </Col>
                    <Col className="col-2">
                        <div>
                            <label style={{marginRight: "10px"}}>Apenas totais? (sem registros)</label>
                            <input name='apenas_totais' type="checkbox" id='apenas_totais'/>
                        </div>
                    </Col>
                </RowDiv>
                <br></br>
                <RowDiv>
                    <Col className="col-12">
                    <div className="div-row">
                    <div className={consultarStyle.divInputDatas}>
                        <Form.Control name='data_inicial' type="date" id='data_inicial' placeholder="Data Inicial" className={consultarStyle.inputDatas}/>
                        <Form.Control.Feedback type="invalid">
                            Informe a data inicial!
                        </Form.Control.Feedback>
                        <div className={consultarStyle.divInputArrows}>
                            <Button size='sm' variant='secondary' className='div-row' style={{height: '30px', margin: '0px 5px 0px 0px', padding: '0px 10px'}} onClick={() => handleLoopDatasFiltro('voltar')}>
                                <span className="material-symbols-outlined" style={{fontSize: '14px'}}>
                                    arrow_back
                                </span><span style={{fontSize: '12px'}}> Voltar</span>
                            </Button>
                        </div>
                    </div>
                    <div className={consultarStyle.divInputDatas}>
                        <Form.Control name='data_final' type="date" id='data_final' placeholder="Data Final" className={consultarStyle.inputDatas}/>
                        <Form.Control.Feedback type="invalid">
                            Informe a data final!
                        </Form.Control.Feedback>
                        <div className={consultarStyle.divInputArrows}>
                            <Button size='sm' variant='secondary' className='div-row' style={{height: '30px', margin: '0px', padding: '0px 10px'}} onClick={() => handleLoopDatasFiltro('proximo')}>
                                <span style={{fontSize: '12px'}}>Proximo </span><span className="material-symbols-outlined" style={{fontSize: '14px'}}>
                                arrow_forward
                                </span>
                            </Button>
                        </div>
                    </div>
                    </div>
                    </Col>
                </RowDiv>
                <br/>
                <br/>
                <br/>
                <div className="div-row">
                    <Button type="submit" variant="success" disabled={loading} style={{marginRight: "5px"}}>{loading ? "Aguarde..." : "Download"}</Button>
                    <Button variant="primary" onClick={() => {setShowModalFiltro(true)}} disabled={loading}>Filtro Avançado {filtroAvancadoStatus && "(*Ativo*)"}</Button>
                    { (loading && downloadState.downloadQtd > 1) && <DownloadStateView downloadState={downloadState} /> }
                </div>
                <br></br>
                {loadingCols && <Spinner />}
                {!loadingCols && <DateTableSelected dados={colaboradoresFiltro} tableSize={'auto'} defaultColumns={defaultColumns} defaultOrder={defaultOrder} />}
                <br/>
            </Form>
        </div>
    )
}