import { makeAutoObservable } from "mobx"
import { iResponseApuracao } from "./dto/dados-apuracao.dto"

export default class ApuracaoState {
    // secondsPassed = 0
    ocorrencias:any[] = []
    dados:iResponseApuracao = {
        calendario: [],
        dados: {
            dias: [],
            ocorrencias: [],
            ocorrencias_dados: {},
            observacoes: [],
            ajustes: [],
            marcacoes: [],
            trocas_turno: [],
            flags: [],
            feriados: [],
            afastamentos: [],
            escalas: [],
            escalas_historico: [],
            colaborador: {},
            definicoes_app: {}
        },
        filtro: {},
        totais: {}
    }

    constructor() {
        makeAutoObservable(this)
    }

    atualizarApuracaoState(novosDados:any) {
        this.dados = novosDados
        // this.secondsPassed += 1
        // this.dados.calendario = dados.calendario
        // this.dados.dados.dias = dados.dados.dias
        // this.dados.dados.ocorrencias = dados.dados.ocorrencias
        // this.dados.dados.ocorrencias_dados = dados.dados.ocorrencias_dados
        // this.dados.dados.observacoes = dados.dados.observacoes
        // this.dados.dados.ajustes = dados.dados.ajustes
        // this.dados.dados.marcacoes = dados.dados.marcacoes
        // this.dados.dados.trocas_turno = dados.dados.trocas_turno
        // this.dados.dados.flags = dados.dados.flags
        // this.dados.dados.feriados = dados.dados.feriados
        // this.dados.dados.afastamentos = dados.dados.afastamentos
        // this.dados.dados.escalas_historico = dados.dados.escalas_historico
        // this.dados.dados.colaborador = dados.dados.colaborador
        // this.dados.filtro = dados.filtro
        // this.dados.totais = dados.totais
        // this.ocorrencias = dados.dados.ocorrencias
    }
}