import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { Button, Form, Spinner } from "react-bootstrap"
import Select from 'react-select'
import { defineValueInInput, getValueFromInput } from "../../components/funcForInput"
import { ApiEndpoints, reqGetNew, reqPostNew } from "../../config/apiClient"
import { iApuracaoConsultarDadosFiltroDto } from "../apuracao/dto/dados-consultar-filtro.dto"
import { createColumnHelper } from "@tanstack/react-table"
import DataTable from "../../components/dataTable/DataTable"

const customSelectStylesConsultarPontoDigital = {
    control: (baseStyles:any, state:any) => ({
        ...baseStyles,
        width: '200px',
        borderColor: "#dee2e6",
        minHeight: '39px',
        maxHeight: '120px',
        marginRight: '5px',
        fontSize: '14px'
    }),
    valueContainer: (provided:any, state:any) => ({
        ...provided,
        minHeight: '30px',
        maxHeight: '120px',
        overflowY: 'auto',
        fontSize: '14px'
    }),
    input: (provided:any, state:any) => ({
        ...provided,
        margin: '0px',
        fontSize: '14px'
    }),
    indicatorSeparator: (state:any) => ({
        display: 'none',
        fontSize: '14px'
    }),
    indicatorsContainer: (provided:any, state:any) => ({
        ...provided,
        minHeight: '39px',
        maxHeight: '120px',
        fontSize: '14px'
    }),
    menu: (provided:any, state:any) => ({
        ...provided,
        fontSize: '14px'
    }),
}

const inputDatas:React.CSSProperties = {
    height: "41px",
    marginRight: "5px",
    maxWidth: "max-content"
}

interface Ocorrencia {
    id_colaborador: number
	id_validacao: number
	id_tipo_pendencia: number
	pendencia: string
	pendencia_data_criacao: string
	id_status: number
	status: string
	matricula: number
	nome_completo: string
	id_marcacao: number
	data_criacao: string
	tipo: number
	id_escala: number
	escala: string
	id_justificativa: number
	justificativa: string
	gmt: string
}

const columnHelper = createColumnHelper<Ocorrencia>()

const defaultColumns = [
    columnHelper.accessor(row => `${dayjs(row.pendencia_data_criacao).format("DD/MM/YYYY HH:mm:ss")} - ${row.pendencia}`, {
        header: 'Pendencia'
    }),
    columnHelper.accessor(row => row.status, {
        header: 'Status',
    }),
    columnHelper.accessor(row => `${row.matricula} - ${row.nome_completo}`, {
        header: 'Colaborador',
    }),
    columnHelper.accessor(row => row.id_marcacao === null ? '' : `${row.data_criacao} ${row.tipo === 0 ? 'Entrada' : 'Saída'}`, {
        header: 'Marcação',
    }),
    columnHelper.accessor(row => `${row.id_escala} - ${row.escala}`, {
        header: 'Escala',
    }),
]

const defaultOrder:any[] = []

export default function OcorrenciasPage() {

    const [loading, setLoading] = useState<boolean>(false)
    const [dados, setDados] = useState<Ocorrencia[]>([])

    const [empresasFiltro, setEmpresasFiltro] = useState<any>([])
    const [empresasFiltroSelected, setEmpresasFiltroSelected] = useState<any>([])

    const [centrosCustoFiltro, setCentrosCustoFiltro] = useState<any[]>([])
    const [centrosCustoFiltroSelected, setCentrosCustoFiltroSelected] = useState<any[]>([])

    const [colaboradoresFiltro, setColaboradoresFiltro] = useState<any>([])
    const [colaboradoresFiltroSelected, setColaboradoresFiltroSelected] = useState<any>([])

    const [ocorrenciasTiposFiltro, setOcorrenciasTiposFiltro] = useState<any>([])
    const [ocorrenciasTiposFiltroSelected, setOcorrenciasTiposFiltroSelected] = useState<any>([])

    const [ocorrenciasStatusFiltro, setOcorrenciasStatusFiltro] = useState<any>([])
    const [ocorrenciasStatusFiltroSelected, setOcorrenciasStatusFiltroSelected] = useState<any>([])

    useEffect(() => {

        document.title = "Ocorrências | OB PORTUS"

        const getDados = async () => {
            const data_atual = dayjs()
            const data_inicial = data_atual.startOf('month').format("YYYY-MM-DD")
            const data_final = data_atual.endOf('month').format("YYYY-MM-DD")
            defineValueInInput('data_inicial', data_inicial)
            defineValueInInput('data_final', data_final)

            const api = await reqGetNew(ApiEndpoints.apuracaoConsultarFiltroDados)
            if (api.status === 200) {
                const dados:iApuracaoConsultarDadosFiltroDto = api.data

                let empresasAux = dados.empresas.map((item:any, index:any) => (
                    {
                        "value": `${item.codigo}`,
                        "label": `${item.codigo} - ${item.nome}`
                    }
                ))
                setEmpresasFiltro(empresasAux)

                let centrosAux = dados.centro_custo.map((item:any, index:any) => (
                    {
                        "value": `${item.codigo}`,
                        "label": `${item.codigo} - ${item.centro_custo}`
                    }
                ))
                setCentrosCustoFiltro(centrosAux)

                let colaboradoresAux = dados.colaboradores.map((item:any, index:any) => (
                    {
                        "value": `${item.id_colaborador}`,
                        "label": `${item.matricula} - ${item.nome_completo}`
                    }
                ))
                setColaboradoresFiltro(colaboradoresAux)

                let tiposAux = dados.ocorrencias_tipo.map((item:any, index:any) => (
                    {
                        "value": `${item.id_tipo_pendencia}`,
                        "label": `${item.id_tipo_pendencia} - ${item.descricao}`
                    }
                ))
                setOcorrenciasTiposFiltro(tiposAux)

                let statusAux = dados.ocorrencias_status.map((item:any, index:any) => (
                    {
                        "value": `${item.id_status}`,
                        "label": `${item.id_status} - ${item.descricao}`
                    }
                ))
                setOcorrenciasStatusFiltro(statusAux)
            }
        }

        getDados()

    }, [])

    async function handleFiltrar() {
        const filtro = {
            data_inicial: getValueFromInput("data_inicial"),
            data_final: getValueFromInput("data_final"),
            id_empresa: empresasFiltroSelected.map((item:any, index:any) => (Number(item.value))),
            id_centro_custo: centrosCustoFiltroSelected.map((item:any, index:any) => (Number(item.value))),
            id_colaborador: colaboradoresFiltroSelected.map((item:any, index:any) => (Number(item.value))),
            id_tipo: [],
		    id_tipo_ocorrencia: ocorrenciasTiposFiltroSelected.map((item:any, index:any) => (Number(item.value))),
		    id_status: ocorrenciasStatusFiltroSelected.map((item:any, index:any) => (Number(item.value))),
        }

        setLoading(true)
        const api = await reqPostNew(ApiEndpoints.pontoOcorrencias, filtro)
        
        if (api.status === 200) {
            setDados(api.data)
        }
        setLoading(false)
    }

    return (
        <div id="main">
            <h1 id='titleMain'>Ocorrências</h1>
            <br></br>
            <div className='div-row'>
                <Form.Control name='data_inicial' type="date" id='data_inicial' placeholder="Data Inicial" style={inputDatas} />
                <Form.Control name='data_final' type="date" id='data_final' placeholder="Data Final" style={inputDatas} />
                <Select name='ids_empresas' id='ids_empresas' options={empresasFiltro} defaultValue={empresasFiltroSelected} placeholder="Empresas" isMulti closeMenuOnSelect={false} onChange={
                    (choice) => {
                        setEmpresasFiltroSelected(choice)
                    }
                } styles={customSelectStylesConsultarPontoDigital} />
                <Select name='ids_centro_custo' id='ids_centro_custo' options={centrosCustoFiltro} defaultValue={centrosCustoFiltroSelected} value={centrosCustoFiltroSelected} placeholder="Centros de Custo" isMulti closeMenuOnSelect={false} onChange={
                    (choice:any) => {
                        setCentrosCustoFiltroSelected(choice)
                    }
                } styles={customSelectStylesConsultarPontoDigital} />
                <Select name='ids_colaboradores' id='ids_colaboradores' options={colaboradoresFiltro} defaultValue={colaboradoresFiltroSelected} placeholder="Colaboradores" isMulti closeMenuOnSelect={false} onChange={
                    (choice) => {
                        setColaboradoresFiltroSelected(choice)
                    }
                } styles={customSelectStylesConsultarPontoDigital} />
                <Select name='ids_tipos' id='ids_tipos' options={ocorrenciasTiposFiltro} defaultValue={ocorrenciasTiposFiltroSelected} placeholder="Tipos" isMulti closeMenuOnSelect={false} onChange={
                    (choice) => {
                        setOcorrenciasTiposFiltroSelected(choice)
                    }
                } styles={customSelectStylesConsultarPontoDigital} />
                <Select name='ids_status' id='ids_status' options={ocorrenciasStatusFiltro} defaultValue={ocorrenciasStatusFiltroSelected} placeholder="Status" isMulti closeMenuOnSelect={false} onChange={
                    (choice) => {
                        setOcorrenciasStatusFiltroSelected(choice)
                    }
                } styles={customSelectStylesConsultarPontoDigital} />
                <Button onClick={() => { handleFiltrar() }} variant='primary' style={{height: '40px'}} className='div-row-space-between'>Filtrar <span className="material-symbols-outlined">filter_alt</span></Button>
            </div>
            <br></br>
            {loading && <Spinner />}
            {!loading && <DataTable dados={dados} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={() => {}} tableClickhandler={() => {}} defaultOrder={defaultOrder} />}
        </div>
    )
}