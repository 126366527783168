import React from "react"
import styles from '../../styles/apuracao.module.css'
import dayjs from "dayjs"

interface iProps {
    dadosOcorrencias: any[],
    handleOcorrenciaCancelar: any
}

export default function ApuracaoPagePainelDetalhesOcorrenicas({dadosOcorrencias, handleOcorrenciaCancelar}:iProps) {
    const divStyle:React.CSSProperties = {
        margin: "10px 0px",
    }

    const divOcorrenciaStyle:React.CSSProperties = {
        height: "16px"
    }

    return (
        <div style={divStyle} className={styles.apuracaoDiaRegs}>
            <p>Ocorrências</p>
            {
                dadosOcorrencias.map((reg, index) => (
                    <div key={index} style={divOcorrenciaStyle} className="div-row">
                        <p>#{reg.id_validacao} {reg.id_status.descricao} {reg.id_tipo_pendencia.descricao} {dayjs(reg.data_criacao).format("DD/MM/YYYY HH:mm:ss")}</p>
                        {
                            reg.data_justificado !== null &&
                        <p> Justificado em: {dayjs(reg.data_justificado).format("DD/MM/YYYY HH:mm:ss")} | Motivo: {reg.id_justificativa.descricao} | Justificativa: {reg.observacao}</p>
                        }
                        {
                            reg.id_status.id_status === 1 &&
                            <p className={styles.btnCancelarOcorrencias} onClick={() => handleOcorrenciaCancelar(reg)}>Cancelar</p>
                        }
                    </div>
                ))
            }
        </div>
    )
}