import removeAllErrorMsg from "../../../components/funcRemoveAllErros"
import { FormEvent, useEffect, useState } from "react"
import { getUrlSearchParams } from "../../urlParams"
import { Button, Col, Form, Row } from "react-bootstrap"
import { ApiEndpoints, reqPatch, reqPostNew, reqPut } from "../../../config/apiClient"
import { toast } from "react-toastify"
import funcSleep from "../../../components/funcSleep"
import LoadingModal from "../../../components/loadingModal"
import iSituacoesDadosDto from "./dto/dados-situacoes.dto"
import iSituacoesOneDto from "./dto/one-situacoes.dto"
import iSituacoesCadastrarDto from "./dto/cadastrar-situacoes.dto"

function SituacoesFormPage() {

    const [loading, setLoading] = useState<boolean>(false)
    const [situacaoD, setSituacaoD] = useState<iSituacoesDadosDto>()

    useEffect(() => {
        document.title = "Cadastrar Situação | OB PORTUS"

        const urlDados = getUrlSearchParams()
        const id = urlDados.get("id")

        const consultar = async (id:number) => {

            setLoading(true)

            const dados:iSituacoesOneDto = {
                codigo: Number(id)
            }

            const api = await reqPostNew(ApiEndpoints.painelControle.situacoes.consultar, dados)

            if (api.status === 200) {
                const reg:iSituacoesDadosDto = api.data
                setSituacaoD(reg)

                let form:any = document.getElementById("form")
                form.codigo.value = reg.codigo
                form.descricao.value = reg.descricao
                form.situacao.value = reg.situacao
            }

            setLoading(false)

        }

        if (id !== null) {
            document.title = "Editar Situação | OB PORTUS"
            consultar(Number(id))
        }
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        // throw new Error("Function not implemented.")
        const form:any = event.target
        let formValidado = true

        let dados:iSituacoesCadastrarDto = {
            codigo: Number(form.codigo.value),
            descricao: form.descricao.value,
            situacao: form.situacao.value
        }

        if (dados.codigo === null || dados.codigo === 0) {
            form.codigo.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.descricao === "") {
            form.descricao.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (!formValidado) {
            return
        }

        console.log(dados)

        if (situacaoD === undefined) {
            cadastrar(dados)
        }

        if (situacaoD !== undefined) {
            editar(dados)
        }

    }

    async function cadastrar(dados:iSituacoesCadastrarDto) {

        setLoading(true)

        const api = await reqPut(ApiEndpoints.painelControle.situacoes.cadastrar, dados)

        setLoading(false)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Situação cadastrada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(1500)

            window.location.assign(`/painelControle/situacoes`)
        }

    }

    async function editar(dados: iSituacoesCadastrarDto) {
        setLoading(true)

        let dadosAux:any = dados
        dadosAux['id_situacao'] = situacaoD?.id_situacao

        const api = await reqPatch(ApiEndpoints.painelControle.situacoes.editar, dadosAux)

        setLoading(false)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Situação editada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/painelControle/situacoes`)
        }
    }


    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />

            <h1 id="titleMain">Situação</h1>

            <br></br>

            <Form id='form' onSubmit={handleSubmit} noValidate>
                
                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Código da situação:</Form.Label>
                            <Form.Control name='codigo' type="number" id='codigo' placeholder="#ID"/>
                            <Form.Control.Feedback type="invalid">
                                Informe o código!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Descrição:</Form.Label>
                            <Form.Control name='descricao' type="text" id='descricao' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe a Descrição!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Situação:</Form.Label>
                            <Form.Select name="situacao" id="situacao" aria-label="Selecione a situação">
                                <option value="1">Ativo</option>
                                <option value="0">Desativado</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                
                {
                    situacaoD === undefined &&
                    <Button variant="success" type="submit">Cadastrar</Button>
                }

                {
                    situacaoD !== undefined &&
                    <Button variant="success" type="submit">Editar</Button>
                }

            </Form>

        </div>
    )

}

export default SituacoesFormPage