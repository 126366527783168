import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import styles from '../../styles/apuracao.module.css'
import dayjs from "dayjs"
import { iDiaApuracao } from "../../dto/dados-apuracao.dto"
import { getCookie } from "../../../../config/cookies"

interface iProps {
    dia: iDiaApuracao,
    handleEditar: any,
    troca_turno: any
}

export default function ApuracaoPagePainelDetalhesTrocasTurno({dia, handleEditar, troca_turno}:iProps) {
    const divStyle:React.CSSProperties = {
        margin: "10px 0px",
    }

    function handleClickEditar(situacao:number) {
        if (!window.confirm("Tem certeza que deseja editar a troca de turno selecionada?")) {
            return
        }

        const dadosEditar = {
            "id": troca_turno.id,
            "data_dia": dia.data,
            "situacao": situacao,
            "id_status": null,
            "id_usuario": Number(getCookie('id_user'))
        }

        handleEditar(dadosEditar, troca_turno)
    }
    
    return (
        <div style={divStyle} className={styles.apuracaoDiaRegs}>
            <Row>
                <Col className="col-4">
                    <p><b>Troca de Turno #{troca_turno.id}</b></p>
                    <p>Criado em {dayjs(troca_turno.data_criacao).format("DD/MM/YYYY HH:mm:ss")}</p>
                    <p>Por {troca_turno.colaborador_cadastrou}</p>
                    {
                        troca_turno.situacao === 1 &&
                        <div>
                            <br></br>
                            <Button size="sm" variant="danger" onClick={() => handleClickEditar(0)}>Deletar</Button>
                        </div>
                    }
                    {
                        troca_turno.situacao === 0 &&
                        <div>
                            <br></br>
                            <Button size="sm" variant="primary" onClick={() => handleClickEditar(1)}>Desfezer</Button>
                        </div>
                    }
                    {
                        troca_turno.data_excluido !== null &&
                        <div>
                            <br></br>
                            <p style={{color: 'red'}}><b>Excluído em {dayjs(troca_turno.data_excluido).format("DD/MM/YYYY HH:mm:ss")}</b></p>
                        </div>
                    }
                    {
                        troca_turno.data_validado !== null &&
                        <div>
                            <br></br>
                        <p>Status: Confirmado em {dayjs(troca_turno.data_validado).format("DD/MM/YYYY HH:mm:ss")}</p>
                        <p>Por {troca_turno.colaborador_trocado}</p>
                        </div>
                    }
                </Col>
                <Col className="col-4">
                    <p>Status</p>
                    <p>{troca_turno.id_status.descricao}</p>
                    {
                        dia.data === troca_turno.data_dia &&
                        <div>
                            <br></br>
                            <p>Escalado - {troca_turno.colaborador_cadastrou}</p>
                            <p>Folga    - {troca_turno.colaborador_trocado}</p>
                        </div>
                    }
                    {
                        dia.data === troca_turno.data_dia_aux &&
                        <div>
                            <br></br>
                            <p>Trabalhou - {troca_turno.colaborador_trocado}</p>
                            <p>Folgou    - {troca_turno.colaborador_cadastrou}</p>
                        </div>
                    }
                </Col>
                <Col className="col-4">
                    <p>Observação</p>
                    <p>{troca_turno.obs}</p>
                </Col>
            </Row>
        </div>
    )
}