import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import iCargosDto from "./dto/lista-cargos.dto"
import { ApiEndpoints, reqGetNew } from "../../../config/apiClient"
import { createColumnHelper } from "@tanstack/react-table"
import DataTable from "../../../components/dataTable/DataTable"

const columnHelper = createColumnHelper<iCargosDto>()

const defaultColumns = [
    columnHelper.accessor('codigo', {
        header: 'ID',
        size: 10,
        minSize: 10,
        maxSize: 150
    }),
    columnHelper.accessor('descricao', {
        header: 'Descrição'
    }),
    columnHelper.accessor('situacao', {
        header: 'Situação'
    }),
]

const defaultOrder:any = []

function CargosPage() {

    const [cargos, setCargos] = useState<iCargosDto[]>([])

    useEffect(() => {
        document.title = "Cargos | OB PORTUS"

        const listarEmpresas = async () => {
            const cargosApi = await reqGetNew(ApiEndpoints.painelControle.cargos.todos)

            if (cargosApi.status === 200) {
                const cargosAux:iCargosDto[] = cargosApi.data
                setCargos(cargosAux)
            }
        }

        listarEmpresas()
    }, [])

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const cargo = cargos[idx]
        const link = `/painelControle/cargos/editar?id=${cargo.codigo}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const cargo = cargos[idx]
        const link = `/painelControle/cargos/editar?id=${cargo.codigo}`

        if (event.button === 0) {
            window.location.assign(link)
        }
    }

    return (
        <div id="main">

            <div className="div-row-space-between">
                <h1 id="titleMain">Cargos</h1>
                <Button onClick={() => {
                    // window.open(`/painelControle/empresas/cadastrar`)
                    window.location.assign(`/painelControle/cargos/cadastrar`)
                }}>Cadastrar</Button>
            </div>

            <br></br>

            {<DataTable dados={cargos} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}

        </div>
    )

}

export default CargosPage