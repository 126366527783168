import { useEffect, useState } from "react"
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { ApiEndpoints, reqPostNew } from "../../../config/apiClient"
import styles from '../styles/modelo.module.css'
import $ from 'jquery'
import 'jquery-mask-plugin'
import { defineValueInInput } from "../../../components/funcForInput"

interface iPropsModal {
    show: boolean,
    handleClose: any,
    empresa: any,
    handleModelo: any
}

function formatarDinheiro(valor:string):string {
    defineValueInInput("dinheiro_aux", valor)
    return $("#dinheiro_aux").masked(valor)
    // return valor
}

export default function NfModalModelos ({ show, handleClose, handleModelo, empresa }:iPropsModal) {
    useEffect(() => {
        $('.dinheiro').mask('###.###.###.###.###.###.##0,00', {reverse: true, placeholder: "0,00"});
        setLoading(true)
        const loadModelos = async () => {
            const api = await reqPostNew(ApiEndpoints.nfModelosConsultar, {})
            if (api.status === 200) {
                setModelos(api.data)
            }
        }
        loadModelos()
        setLoading(false)
    }, [])

    const [modelos, setModelos] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={true}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>Selecione um modelo:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { loading && <Spinner></Spinner> }
                { (!loading && modelos.filter(reg => reg.id_empresa.id_empresa === empresa.id_empresa).length === 0) && <p>Não há modelos cadastrados na empresa selecionada.</p> }
                { !loading && modelos.filter(reg => reg.id_empresa.id_empresa === empresa.id_empresa).map((reg:any, idx: any) => (
                    <div key={idx} className={styles.modeloLista} onClick={() => { handleModelo(reg) }}>
                        <p style={{fontWeight: "bold"}}>{reg.nome} {reg.id_modelo} {reg.tipo === 1 ? "(Mensal)" : "(Apenas Modelo)"}</p>
                        <p>Cliente: {reg.id_cliente.nome} CNPJ {reg.id_cliente.cnpj}</p>
                        <p>Serviço: {reg.cod_serv.cod_serv} {reg.cod_serv.descricao} {reg.cod_serv.iss_incidencia_municipio_prestador === 1 ? "Retido" : "Tributado"}</p>
                        <br></br>
                        <p>Valor: R$ {formatarDinheiro(reg.valor)}</p>
                        <br></br>
                        <Row>
                            <Col className="col-2">IRRF <br></br>{reg.IRRF} {formatarDinheiro(reg.IRRF_V)}</Col>
                            <Col className="col-2">INSS <br></br>{reg.INSS} {formatarDinheiro(reg.INSS_V)}</Col>
                            <Col className="col-2">COFINS <br></br>{reg.COFINS} {formatarDinheiro(reg.COFINS_V)}</Col>
                            <Col className="col-2">CSLL <br></br>{reg.CSLL} {formatarDinheiro(reg.CSLL_V)}</Col>
                            <Col className="col-2">PIS <br></br>{reg.PIS} {formatarDinheiro(reg.PIS_V)}</Col>
                            <Col className="col-2">ISS <br></br>{reg.ISS} {formatarDinheiro(reg.ISS_V)}</Col>
                        </Row>
                        {/* <p>IRRF {reg.IRRF} {formatarDinheiro(reg.IRRF_V)} • INSS {reg.INSS} {formatarDinheiro(reg.INSS_V)} • COFINS {reg.COFINS} {formatarDinheiro(reg.COFINS_V)} • CSLL {reg.CSLL} {formatarDinheiro(reg.CSLL_V)} • PIS {reg.PIS} {formatarDinheiro(reg.PIS_V)} • ISS {reg.ISS} {formatarDinheiro(reg.ISS_V)}</p> */}
                    </div>
                )) }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}