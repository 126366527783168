import '../styles/home/home.css'
import '../components/dataTable/table.css'
import Menu from '../components/home/Menu'
import Header from '../components/home/Header'
import { Outlet } from 'react-router-dom'
import { useEffect } from 'react'
import { getCookie, setCookie } from '../config/cookies'

function Home() {

    useEffect(() => {
        const id_user = getCookie('id_user')

        if (id_user === "") {
            let urlNow = window.location.href
            setCookie('redirect', urlNow)
            window.location.assign(`/login?redirect=${urlNow}`)
        }
    })

    return (
        <div id='page'>
            <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            <Header />
            <div id='container-principal'>
                <Menu />
                <Outlet />
            </div>
        </div>
    )

}

export default Home