import styles from '../styles/inicial.module.css'

interface iProps {
    empresas: any[]
    handleEmpresa: any
}

export default function NfSelecionarEmpresas({ empresas, handleEmpresa }:iProps) {
    return (
        <div className="div-row">
            {
                empresas.map(
                    (item, index) => (
                        <div key={index} className={styles.divEmpresaItem} onClick={(e) => { handleEmpresa(item) }}>
                            <img src={item.logo} alt="banner_empresa" width={80}></img>
                            <p>{item.nome}</p>
                        </div>
                    )
                )
            }
            {
                empresas.length === 0 &&
                <p>Nenhum registro encontrado!</p>
            }
        </div>
    )
}