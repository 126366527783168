import React, { useEffect, useState } from 'react'
import { iEmpresa } from './dto/nfInterfaces'
import { Button, InputGroup, Spinner, Table, Alert, Modal } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { ApiEndpoints, reqPost, reqPostDownloadFile } from '../../config/apiClient';
import Select from 'react-select'
import $ from 'jquery'
import 'jquery-mask-plugin';
import styles from './styles/emissao.module.css'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { getUrlSearchParams } from '../urlParams';

interface iChoice {
    "value": string,
    "label": string
}

interface iSelectValues {
    "id_empresa": iChoice,
    "id_cliente": iChoice,
    "cod_serv": iChoice,
    "cod_cidade": iChoice
}

function calcTributoValor(id_input_tributo:string) {

    let input_tributo:any = document.getElementById(id_input_tributo)

    let input_valor:any = document.getElementById("valor")

    if (input_valor.value === "") {
        return
    }

    let valor_nota_string:string = String(input_valor.value)
    let valor_nota:number = Number(valor_nota_string.replaceAll(".", "").replaceAll(",", "."))
    
    let valor_tributo:number = valor_nota * (Number(input_tributo.value) / 100)
    
    let input_tributo_valor:any = document.getElementById(`${id_input_tributo}_V`)
    input_tributo_valor.value = $(`#${id_input_tributo}_V`).masked(String(valor_tributo.toFixed(2)))

}

function calcValorLiquido() {

    let input_valor:any = document.getElementById("valor")

    if (input_valor.value === "") {
        return
    }

    let valor_nota_string:string = String(input_valor.value)
    let valor_nota:number = Number(valor_nota_string.replaceAll(".", "").replaceAll(",", "."))

    let inputIRRF:any = document.getElementById("IRRF")
    let IRRF:number = valor_nota * (Number(inputIRRF.value) / 100)

    let inputINSS:any = document.getElementById("INSS")
    let INSS:number = valor_nota * (Number(inputINSS.value) / 100)

    let inputCOFINS:any = document.getElementById("COFINS")
    let COFINS:number = valor_nota * (Number(inputCOFINS.value) / 100)

    let inputCSLL:any = document.getElementById("CSLL")
    let CSLL:number = valor_nota * (Number(inputCSLL.value) / 100)

    let inputPIS:any = document.getElementById("PIS")
    let PIS:number = valor_nota * (Number(inputPIS.value) / 100)

    let inputISS:any = document.getElementById("ISS")
    let ISS:number = valor_nota * (Number(inputISS.value) / 100)

    let ISS_status:any = document.getElementById("ISS_status")

    let valor_liquido:number = valor_nota - (IRRF + INSS + COFINS + CSLL + PIS + ISS)

    if (ISS_status.value === "Tributado") {
        valor_liquido = valor_nota
    }

    let input_valor_liquido:any = document.getElementById("LIQUIDO_V")
    input_valor_liquido.value = $(`#LIQUIDO_V`).masked(String(valor_liquido.toFixed(2)))


}

function calcAllTributos() {
    let input_valor:any = document.getElementById("valor")

    if (input_valor.value === "") {
        return
    }

    calcTributoValor("IRRF")
    calcTributoValor("INSS")
    calcTributoValor("COFINS")
    calcTributoValor("CSLL")
    calcTributoValor("PIS")
    calcTributoValor("ISS")

    calcValorLiquido()
}

function removeAllErrorMsg() {

    let divs:any = document.querySelectorAll(".invalid-feedback")

    for (let ele of divs) {

        ele.style.display = "none"

    }

}

function defineValueInInput(id:string, value:any) {
    let ele :any = document.getElementById(id)
    ele.value = value
}

function ModalMessageXMLError(props:any) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            // centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <b>Erro</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <textarea id="modal_msg_xml_erro" className='form-control' style={{height: "512px"}} value={props.msg} readOnly></textarea>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
  }

export default function NFNova({ empresaDados, currentView, nfView }:any) {

    const empresa:iEmpresa = empresaDados

    const [nfDados, setNfDados] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false)
    const [formAction, setFormAction] = useState<string>('')
    const [modalXMLError, setModalXMLError] = useState<boolean>(false)
    const [modalXMLErrorMSG, setModalXMLErrorMSG] = useState<string>('')

    const [formData, setFormData] = useState<any>({})
    const [optionsEmpresas, setOptionsEmpresas] = useState<any>({})
    const [optionsClientes, setOptionsClientes] = useState<any>({})
    const [idCliente, setIdCliente] = useState<iChoice>({
        "value": "0",
        "label": "Selecione..."
    })
    const [optionsServicos, setOptionsServicos] = useState<any>({})
    const [codServ, setCodServ] = useState<iChoice>({
        "value": "0",
        "label": "Selecione..."
    })
    const [statusIss, setStatusIss] = useState<string>('')
    const [optionsCidades, setOptionsCidades] = useState<any>({})
    const [codCidade, setCodCidade] = useState<iChoice>({
        "value": "0",
        "label": "Selecione..."
    })
    const [selectValues, setSelectValues] = useState<iSelectValues>({
        "id_empresa": {
            "value": String(empresa.id_empresa),
            "label": String(empresa.nome)
        },
        "id_cliente": {
            "value": "0",
            "label": "Selecione..."
        },
        "cod_serv": {
            "value": "0",
            "label": "Selecione..."
        },
        "cod_cidade": {
            "value": "0",
            "label": "Selecione..."
        }
    })

    useEffect(() => {
        document.title = "NF Criar | OB PORTUS"

        const loadDataNf = (dados:any) => {

            defineValueInInput("descricao", dados.n_descricao)
            defineValueInInput("data_servico", dados.n_data_servico)
            defineValueInInput("data_competencia", dados.n_data_competencia)

            if (dados.s_issRetidoOuTributado === 1) {
                setStatusIss("Retido")
            } else {
                setStatusIss("Tributado")
            }
    
            defineValueInInput("valor", $(".inputMoney").masked(dados.n_valor_servicos))
            defineValueInInput("BASE_V", $(".inputMoney").masked(dados.n_valor_servicos))
            defineValueInInput("IRRF", dados.n_IRRF)
            defineValueInInput("INSS", dados.n_INSS)
            defineValueInInput("COFINS", dados.n_COFINS)
            defineValueInInput("CSLL", dados.n_CSLL)
            defineValueInInput("PIS", dados.n_PIS)
            defineValueInInput("ISS", dados.n_ISS)
            calcAllTributos()
            calcValorLiquido()
    
            defineValueInInput("descriminacao", dados.n_descriminacao)
    
            const idClienteAux:iChoice = {
                value: `${dados.n_id_cliente}`,
                label: `${dados.c_nome} - ${dados.c_cnpj}`
            }
            setIdCliente(idClienteAux)
    
            const codCidadeAux:iChoice = {
                value: `${dados.n_cod_cidade}`,
                label: `${dados.n_cod_cidade} - ${dados.n_cidade}/${dados.n_uf}`
            }
            setCodCidade(codCidadeAux)
    
            const codServAux:iChoice = {
                value: `${dados.n_cod_serv}`,
                label: `${dados.n_cod_serv} - ${dados.s_servico}`
            }
            setCodServ(codServAux)
    
            let selectValuesAux = selectValues
            selectValuesAux['id_cliente'] = idClienteAux
            selectValuesAux['cod_cidade'] = codCidadeAux
            selectValuesAux['cod_serv'] = codServAux 
            setSelectValues(selectValuesAux)
    
            if (dados.n_emitido === 1) {
                setIsFormDisabled(true)
            }

        }

        const getFormData = async () => {
    
            const api = await reqPost(ApiEndpoints.nfFormData, empresa)
        
            if (api.status === 200) {

                setFormData(api.data)
        
                let optionsEmpresas = api.data.empresas.map((item:any, index:any) => (
                    {
                        "value": `${item.id_empresa}`,
                        "label": `${item.nome}`
                    }
                ))
    
                setOptionsEmpresas(optionsEmpresas)
    
                let optionsClientes = api.data.clientes.map((item:any, index:any) => (
                    {
                        "value": `${item.id_cliente}`,
                        "label": `${item.nome} - ${item.cnpj}`
                    }
                ))
    
                setOptionsClientes(optionsClientes)

                let optionsServicos = api.data.servicos.map((item:any, index:any) => (
                    {
                        "value": `${item.cod_serv}`,
                        "label": `${item.cod_serv} - ${item.descricao}`
                    }
                ))
    
                setOptionsServicos(optionsServicos)

                let optionsCidades = api.data.cidades.map((item:any, index:any) => (
                    {
                        "value": `${item.cod_cidade}`,
                        "label": `${item.cod_cidade} - ${item.nome}/${item.uf}`
                    }
                ))
    
                setOptionsCidades(optionsCidades)

                // Se a dados nas props referente a NF, consultar dados:
                if (Object.values(nfView).length !== 0) {

                    const nota = await reqPost(ApiEndpoints.nfConsultar, nfView)

                    if (nota.status === 200) {

                        console.log(nota.data)
                        setNfDados(nota.data)
                        loadDataNf(nota.data)

                    }

                }
        
            }
        
        }

        getFormData()
    }, [empresa, nfView, selectValues])

    $('.inputMoney').mask('#.##0,00', {
        reverse: true,
        placeholder: '0,00'
    })

    $('.inputPor').mask('000.00', {
        reverse: true
    })

    $('#IRRF').mask('000.00', {
        reverse: true
    })

    $('#IRRF_V').mask('#.##0,00', {
        reverse: true
    })

    $('#INSS').mask('000.00', {
        reverse: true
    })

    $('#INSS_V').mask('#.##0,00', {
        reverse: true
    })

    $('#COFINS').mask('000.00', {
        reverse: true
    })

    $('#COFINS_V').mask('#.##0,00', {
        reverse: true
    })

    $('#CSLL').mask('000.00', {
        reverse: true
    })

    $('#CSLL_V').mask('#.##0,00', {
        reverse: true
    })

    $('#PIS').mask('000.00', {
        reverse: true
    })

    $('#PIS_V').mask('#.##0,00', {
        reverse: true
    })

    $('#ISS').mask('000.00', {
        reverse: true
    })

    $('#ISS_V').mask('#.##0,00', {
        reverse: true
    })

    $('#BASE_V').mask('#.##0,00', {
        reverse: true
    })

    $('#LIQUIDO_V').mask('#.##0,00', {
        reverse: true
    })

    const handleSubmit = (event:any) => {

        let validado = true
        const form = event.target.form

        let dados = {
            "descricao": form.descricao.value,
            "id_empresa": selectValues.id_empresa.value,
            "id_cliente": selectValues.id_cliente.value,
            "data_servico": form.data_servico.value,
            "data_competencia": form.data_competencia.value,
            "valor_servicos": form.valor.value,
            "cod_serv": selectValues.cod_serv.value,
            "cod_cidade": selectValues.cod_cidade.value,
            "IRRF": form.IRRF.value,
            "IRRF_V": form.IRRF_V.value,
            "INSS": form.INSS.value,
            "INSS_V": form.INSS_V.value,
            "COFINS": form.COFINS.value,
            "COFINS_V": form.COFINS_V.value,
            "CSLL": form.CSLL.value,
            "CSLL_V": form.CSLL_V.value,
            "PIS": form.PIS.value,
            "PIS_V": form.PIS_V.value,
            "ISS": form.ISS.value,
            "ISS_V": form.ISS_V.value,
            "descriminacao": form.descriminacao.value
        }

        removeAllErrorMsg()

        if (dados.descricao === "") {
            validado = false
            form.descricao.nextElementSibling.innerText = "Informe uma descrição!"
            form.descricao.nextElementSibling.style.display = "block"
        }

        if (dados.id_cliente === "0") {
            validado = false
            form.id_cliente_aux.nextElementSibling.style.display = "block"
        }

        if (dados.data_servico === "") {
            validado = false
            form.data_servico.nextElementSibling.style.display = "block"
        }

        if (dados.data_competencia === "") {
            validado = false
            form.data_competencia.nextElementSibling.style.display = "block"
        }

        if (dados.valor_servicos === "") {
            validado = false
            form.valor.nextElementSibling.style.display = "block"
        }

        if (dados.cod_serv === "0") {
            validado = false
            form.cod_serv_aux.nextElementSibling.style.display = "block"
        }

        if (dados.descriminacao === "") {
            validado = false
            form.descriminacao.nextElementSibling.style.display = "block"
        }

        if (dados.cod_cidade === "0") {
            validado = false
            form.cod_cidade_aux.nextElementSibling.style.display = "block"
        }

        // console.log(form.descricao.nextElementSibling.innerText)
        // console.log(form.id_empresa_aux.nextElementSibling.innerText)
        // form.id_empresa_aux.nextElementSibling.style.display = "flex"
        // document.querySelectorAll(".invalid-feedback")[1].style.display = "none"

        if (!validado) {
            toast.warning('Verifique se todos os campos estão corretos!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            return
        }

        const action = event.target.getAttribute("about")

        if (action === "criar_emitir_nota") {
            sendDataNf(dados, true)
        }

        if (action === "criar_nota") {
            sendDataNf(dados, false)
        }

        if (action === "editar_nota") {
            editDataNf(dados)
        }

    }

    async function editDataNf(dados:any) {

        setIsLoading(true)
        setFormAction('editar')

        dados['n_id_nota'] = nfDados.n_id_nota

        const api = await reqPost(ApiEndpoints.nfEditar, dados)

        if (api.status === 200) {

            setNfDados(api.data)

            toast.success('Nota editada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

        }

        setIsLoading(false)
        setFormAction('')

    }

    async function deletarNf(dados:any) {

        setIsLoading(true)
        setFormAction('deletar')

        if (!window.confirm(`Tem certeza que deseja DELETAR a NF?`)) {
            setIsLoading(false)
            setFormAction('')
            return
        }

        const api = await reqPost(ApiEndpoints.nfDeletar, dados)

        if (api.status === 200) {

            toast.success('Nota excluída com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            currentView('consultar')

        }

        setIsLoading(false)
        setFormAction('')

    }

    async function sendDataNf(data:any, emitir:boolean) {

        setIsLoading(true)

        if (emitir) {
            setFormAction('criar_emitir')
        } else {
            setFormAction('criar')
        }

        const api = await reqPost(ApiEndpoints.nfCadastro, data)

        if (api.status === 200) {

            setNfDados(api.data)

            toast.success('Nota cadastrada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            const urlParams = getUrlSearchParams()

            window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESA NF", `/nf?id_empresa=${urlParams.get('id_empresa')}&id_nota=${api.data.n_id_nota}`)

            if (emitir) {
                emitirNf(api.data)
            }

        }

        if (!emitir) {
            setIsLoading(false)
            setFormAction('')
        }

    }

    async function emitirNf(data:any) {

        setIsLoading(true)
        setFormAction('emitir')

        if (!window.confirm(`Tem certeza que deseja EMITIR a NF?`)) {
            setIsLoading(false)
            setFormAction('')
            return
        }

        const enviarNota = await reqPost(ApiEndpoints.nfEnviarAh, data)

        if (enviarNota.status === 200) {

            setNfDados(enviarNota.data.dados)

            if (enviarNota.data.status === "ok") {
                setIsFormDisabled(true)
                toast.success('Nota emitida com sucesso!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }

            if (enviarNota.data.status === "error") {
                toast.error(enviarNota.data.msg, {
                    containerId: 1,
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 5000
                })
                setModalXMLError(true)
                setModalXMLErrorMSG(enviarNota.data.descricao)
                // window.setTimeout(() => {
                //     document.getElementById("modal_msg_xml_erro")!.innerHTML = `${enviarNota.data.descricao}`
                // }, 500)
            }

        }

        setIsLoading(false)
        setFormAction('')

    }

    async function cancelarNf(data:any) {

        setIsLoading(true)
        setFormAction('cancelar')

        if (!window.confirm(`Tem certeza que deseja CANCELAR a NF?`)) {
            setIsLoading(false)
            setFormAction('')
            return
        }

        const api = await reqPost(ApiEndpoints.nfCancelar, data)

        if (api.status === 200) {

            console.log(api.data)

            if (api.data.status === "ok") {
                setIsFormDisabled(true)
                toast.success('Pedido para cancelamento feito com sucesso!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                setNfDados(api.data.nota)
            }

            if (api.data.status === "error") {
                toast.error(`${api.data.msg}\n
                \n${api.data.descricao}`, {
                    containerId: 1,
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 15000
                })
            }

        }

        setIsLoading(false)
        setFormAction('')

    }

    async function consultarXML(data:any) {

        setIsLoading(true)
        setFormAction('consultar_xml')

        const api = await reqPost(ApiEndpoints.nfXml, data)

        if (api.status === 200) {

            console.log(api.data)

            // if (enviarNota.data.status === "ok") {
            //     setIsFormDisabled(true)
            //     toast.success('Nota emitida com sucesso!', {
            //         containerId: 1,
            //         position: 'top-center',
            //         theme: 'dark'
            //     })
            // }

            if (api.data.status === "error") {
                toast.error(`${api.data.msg}\n
                \n${api.data.descricao}`, {
                    containerId: 1,
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 15000
                })
            }

        }

        setIsLoading(false)
        setFormAction('')

    }

    async function downloadXml(data:any) {

        setIsLoading(true)
        setFormAction('download_xml')

        let filename = `${data.n_descricao}.xml`

        let api = await reqPostDownloadFile(ApiEndpoints.nfXmlDownload, data, filename)
        console.log(api)

        if (api.status === 400) {
            toast.error(`Não foi possível baixar o PDF!`, {
                containerId: 1,
                theme: 'dark',
                position: 'top-center',
                autoClose: 15000
            })
        }

        setIsLoading(false)
        setFormAction('')

    }

    async function downloadPDF(data:any) {

        setIsLoading(true)
        setFormAction('pdf')

        let filename = `${data.n_descricao}.pdf`

        let api = await reqPostDownloadFile(ApiEndpoints.nfPdf, data, filename)
        console.log(api)

        if (api.status === 400) {
            toast.error(`Não foi possível baixar o PDF!`, {
                containerId: 1,
                theme: 'dark',
                position: 'top-center',
                autoClose: 15000
            })
        }

        setIsLoading(false)
        setFormAction('')

    }

    function handleClick(event:any): void {
        currentView('consultar')
    }

    function handleClickEmitir() {
        emitirNf(nfDados)
    }

    function handleChangeInTributo(event:any): void {
        
        calcTributoValor(event.target.id)

        calcValorLiquido()

    }

    function handleChangeInValor(event:any): void {

        calcAllTributos()

        let base:any = document.getElementById("BASE_V")
        base.value = event.target.value
        
    }

    function handleClickDeletar() {
        deletarNf(nfDados)
    }

    function handleClickCancelar() {
        cancelarNf(nfDados)
    }

    function handleConsultarXml() {
        consultarXML(nfDados)
    }

    function handleDownloadXML() {
        downloadXml(nfDados)
    }

    function handleDownloadPDF() {
        downloadPDF(nfDados)
    }

    return (
        <div>
            <ModalMessageXMLError
                show={modalXMLError}
                onHide={() => setModalXMLError(false)}
                msg={modalXMLErrorMSG}
            />

            {/* <Button variant="primary" onClick={() => setModalXMLError(true)}>
                Launch vertically centered modal
            </Button> */}

            <h1 id='titleMain' style={{display: 'flex', alignItems: 'center', margin: "0px 0px 10px 0px" }}>{empresa.nome} / Nova NF <Button style={{marginLeft: "10px"}} onClick={handleClick}>Voltar</Button></h1>

            { Object.values(nfDados).length !== 0 &&
                <div>
                    {
                        nfDados.n_ambiente === "ah" &&
                        <Alert variant='info'>NF Ambiente HOMOLOGAÇÃO</Alert>
                    }
                    <Table responsive bordered className={styles.nfDadosTable}>
                        <tbody>
                            <tr>
                                <td><b>Status:</b></td>
                                <td><b>Numero Nota:</b></td>
                                <td><b>Numero Lote:</b></td>
                                <td><b>Protocolo:</b></td>
                            </tr>
                            <tr>
                                <td>{nfDados.n_status}</td>
                                <td>{nfDados.n_numero_nf}</td>
                                <td>{nfDados.n_numero_lote}</td>
                                <td>{nfDados.n_numero_protocolo}</td>
                            </tr>
                            <tr>
                                <td><b>Data emissão:</b></td>
                                <td><b>Código verificação:</b></td>
                                <td><b>Valor Base:</b></td>
                                <td><b>Valor Líquido:</b></td>
                            </tr>
                            <tr>
                                <td>{dayjs(nfDados.n_data_emissao).format("DD/MM/YYYY - HH:mm:ss")}</td>
                                <td>{nfDados.n_codigo_verificacao}</td>
                                <td>{nfDados.n_valor_base}</td>
                                <td>{nfDados.n_valor_liquido}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            }

            {/* <Form id='formNf' validated={validated} noValidate onSubmit={handleSubmit}> */}
            <Form id='formNf' noValidate>

                <div className='container-fluid p-0' style={{width: 'calc(100% - 4px)'}}>

                <div about='nf_descricao' className='row'>

                    <div className='col-12'>

                        <Form.Group className="mt-0 mb-3">
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control name='descricao' type="text" id='descricao' placeholder="" autoComplete='off' readOnly/>
                            <Form.Control.Feedback type="invalid">
                                Informe uma descrição!
                            </Form.Control.Feedback>
                        </Form.Group>

                    </div>

                </div>

                <div className='row'>

                    <div className='col-12'>
                        <h2 className={styles.titleSession}>Dados do Cliente</h2>
                    </div>

                </div>

                <div about='nf_empresa_cliente' className='row'>

                    <div className='col-6'>

                        <Form.Group className="mb-3 mt-3">
                            <Form.Label>Empresa</Form.Label>
                            <Select name='id_empresa' defaultValue={selectValues.id_empresa} id='id_empresa' required options={optionsEmpresas} placeholder="Selecione" isDisabled={true} onChange={
                                (choice) => {
                                    let selectValuesAux = selectValues
                                    selectValuesAux['id_empresa'] = choice as iChoice
                                    setSelectValues(selectValuesAux)
                                }
                            } styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: "#dee2e6"
                                }),
                            }} />
                            <Form.Control name='id_empresa_aux' type="text" id='id_empresa_aux' placeholder="..." required style={{display:"none"}}/>
                            <Form.Control.Feedback type="invalid">
                                Selecione uma empresa!
                            </Form.Control.Feedback>
                        </Form.Group>

                    </div>

                    <div className='col-6'>
                        <Form.Group className="mb-3 mt-3">
                            <Form.Label>Cliente</Form.Label>
                            <Select name='id_cliente' isDisabled={isFormDisabled} value={idCliente} id='id_cliente' required options={optionsClientes} placeholder="Selecione" onChange={
                                (choice:any) => {
                                    let selectValuesAux = selectValues
                                    selectValuesAux['id_cliente'] = choice as iChoice
                                    setSelectValues(selectValuesAux)
                                    setIdCliente(choice as iChoice)

                                    for (let i = 0; i < Object.values(formData.clientes).length; i++) {
                                        let reg = formData.clientes[i]
                                        if (reg.id_cliente === Number(choice.value)) {

                                            if (empresa.optante_simples_nacional === 2) {
                                                let IRRF:any = document.getElementById("IRRF")
                                                IRRF.value = reg.IRRF
    
                                                let INSS:any = document.getElementById("INSS")
                                                INSS.value = reg.INSS
    
                                                let COFINS:any = document.getElementById("COFINS")
                                                COFINS.value = reg.COFINS
    
                                                let CSLL:any = document.getElementById("CSLL")
                                                CSLL.value = reg.CSLL
    
                                                let PIS:any = document.getElementById("PIS")
                                                PIS.value = reg.PIS
                                            }

                                            let ISS:any = document.getElementById("ISS")
                                            if (reg.cod_cidade !== "" || reg.cod_cidade !== null || reg.cod_cidade !== undefined) {
                                                let choiceCidade:iChoice = {
                                                    "value": String(reg.cod_cidade),
                                                    "label": `${reg.cod_cidade} - ${reg.cidade}/${reg.uf}`
                                                }
                                                setCodCidade(choiceCidade)
                                                let selectValuesAux2 = selectValues
                                                selectValuesAux2['cod_cidade'] = choiceCidade
                                                setSelectValues(selectValuesAux2)

                                                if (empresa.optante_simples_nacional === 1) {
                                                    ISS.value = empresa.ISS
                                                } else {
                                                    ISS.value = reg.ISS
                                                }
                                            }

                                            defineValueInInput('descricao', `NFe ###0 - ${reg.nome}`)

                                            calcAllTributos()

                                            break
                                        }
                                    }

                                }
                            } styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: "#dee2e6"
                                }),
                            }} />
                            <Form.Control name='id_cliente_aux' type="text" id='id_cliente_aux' placeholder="..." required style={{display:"none"}}/>
                            <Form.Control.Feedback type="invalid">
                                Selecione um cliente!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>

                </div>

                <div className='row'>

                    <div className='col-12'>
                        <h2 className={styles.titleSession}>Informações do Serviço</h2>
                    </div>

                </div>

                <div about='nf_valor_codserv' className='row'>
                    
                    <div className='col-6 mt-3'>

                        <Form.Group className="mt-0 mb-3">
                            <Form.Label>Data do Serviço</Form.Label>
                            <Form.Control name='data_servico' disabled={isFormDisabled} type="date" id='data_servico' defaultValue={dayjs().format("YYYY-MM-DD")} placeholder="DD/MM/YYYY"/>
                            <Form.Control.Feedback type="invalid">
                                Informe a data do serviço!
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Label>Valor</Form.Label>
                        <InputGroup className="mt-0 mb-3">
                            <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
                            <Form.Control name='valor' type="text" disabled={isFormDisabled} id='valor' onKeyUp={handleChangeInValor} placeholder="0,00" className='inputMoney' autoComplete='off' required/>
                            <Form.Control.Feedback type="invalid">
                                Informe o valor da NF!
                            </Form.Control.Feedback>
                        </InputGroup>

                    </div>

                    <div className='col-6'>

                        <Form.Group className="mt-3 mb-3">
                            <Form.Label>Data da Competência</Form.Label>
                            <Form.Control name='data_competencia' disabled={isFormDisabled} type="date" id='data_competencia' defaultValue={dayjs().format("YYYY-MM-DD")} placeholder="DD/MM/YYYY"/>
                            <Form.Control.Feedback type="invalid">
                                Informe a data da competência!
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3 mt-3">
                            <Form.Label>Serviços</Form.Label>
                            <Select name='cod_serv' value={codServ} id='cod_serv' required options={optionsServicos} isDisabled={isFormDisabled} placeholder="Selecione" onChange={
                                (choice) => {
                                    let selectValuesAux = selectValues
                                    selectValuesAux['cod_serv'] = choice as iChoice
                                    setSelectValues(selectValuesAux)
                                    setCodServ(choice as iChoice)

                                    for (let i = 0; i < Object.values(formData.servicos).length; i++) {
                                        let reg = formData.servicos[i]
                                        if (reg.cod_serv === selectValuesAux['cod_serv'].value) {
                                            if (reg.iss_incidencia_municipio_prestador === 1) {
                                                setStatusIss("Retido")
                                            } else {
                                                setStatusIss("Tributado")
                                            }

                                            window.setTimeout(() => {
                                                calcAllTributos()
                                            }, 1000)

                                            break
                                        }
                                    }
                                }
                            } styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: "#dee2e6"
                                }),
                            }} />
                            <Form.Control name='cod_serv_aux' type="text" id='cod_serv_aux' placeholder="..." required style={{display:"none"}}/>
                            <Form.Control.Feedback type="invalid">
                                Selecione um Serviço!
                            </Form.Control.Feedback>
                        </Form.Group>

                    </div>

                </div>

                <div about='nf_valores' className='row mb-3'>
                    
                    <div about='IRRF' className='col-2'>
                        
                        <div className='div_valor_tributo'>

                            <p className={styles.titleValorTributo}>IRRF</p>

                            <div className={styles.divInputsTributo}>
                                <p>Aliquota (%)</p>
                                <input name='IRRF' onKeyUp={handleChangeInTributo} type="text" id='IRRF' disabled={isFormDisabled} placeholder="0.00"/>
                            </div>

                            <div className={styles.divInputsTributo}>
                                <p>Valor (R$)</p>
                                <input name='IRRF_V' type="text" id='IRRF_V' placeholder="0,00" readOnly disabled/>
                            </div>

                        </div>

                    </div>

                    <div about='INSS' className='col-2'>
                        
                        <div className='div_valor_tributo'>

                            <p className={styles.titleValorTributo}>INSS</p>

                            <div className={styles.divInputsTributo}>
                                <p>Aliquota (%)</p>
                                <input name='INSS' onKeyUp={handleChangeInTributo} type="text" id='INSS' disabled={isFormDisabled} placeholder="0.00"/>
                            </div>

                            <div className={styles.divInputsTributo}>
                                <p>Valor (R$)</p>
                                <input name='INSS_V' type="text" id='INSS_V' placeholder="0,00" readOnly disabled/>
                            </div>

                        </div>

                    </div>

                    <div about='COFINS' className='col-2'>
                        
                        <div className='div_valor_tributo'>

                            <p className={styles.titleValorTributo}>COFINS</p>

                            <div className={styles.divInputsTributo}>
                                <p>Aliquota (%)</p>
                                <input name='COFINS' onKeyUp={handleChangeInTributo} type="text" id='COFINS' disabled={isFormDisabled} placeholder="0.00"/>
                            </div>

                            <div className={styles.divInputsTributo}>
                                <p>Valor (R$)</p>
                                <input name='COFINS_V' type="text" id='COFINS_V' placeholder="0,00" readOnly disabled/>
                            </div>

                        </div>

                    </div>

                    <div about='CSLL' className='col-2'>
                        
                        <div className='div_valor_tributo'>

                            <p className={styles.titleValorTributo}>CSLL</p>

                            <div className={styles.divInputsTributo}>
                                <p>Aliquota (%)</p>
                                <input name='CSLL' onKeyUp={handleChangeInTributo} type="text" id='CSLL' disabled={isFormDisabled} placeholder="0.00"/>
                            </div>

                            <div className={styles.divInputsTributo}>
                                <p>Valor (R$)</p>
                                <input name='CSLL_V' type="text" id='CSLL_V' placeholder="0,00" readOnly disabled/>
                            </div>

                        </div>

                    </div>

                    <div about='PIS' className='col-2'>
                        
                        <div className='div_valor_tributo'>

                            <p className={styles.titleValorTributo}>PIS</p>

                            <div className={styles.divInputsTributo}>
                                <p>Aliquota (%)</p>
                                <input name='PIS' onKeyUp={handleChangeInTributo} type="text" id='PIS' disabled={isFormDisabled} placeholder="0.00"/>
                            </div>

                            <div className={styles.divInputsTributo}>
                                <p>Valor (R$)</p>
                                <input name='PIS_V' type="text" id='PIS_V' placeholder="0,00" readOnly disabled/>
                            </div>

                        </div>

                    </div>

                    <div about='RESUMO' className='col-2'>
                        
                        <div className='div_valor_tributo'>

                            <p className={styles.titleValorTributoResumo}>RESUMO</p>

                            <div className={styles.divInputsTributo}>
                                <p>Base (R$)</p>
                                <input name='BASE_V' type="text" id='BASE_V' placeholder="0,00" readOnly disabled/>
                            </div>

                            <div className={styles.divInputsTributo}>
                                <p>Líquido (R$)</p>
                                <input name='LIQUIDO_V' type="text" id='LIQUIDO_V' placeholder="0,00" readOnly disabled/>
                            </div>

                        </div>

                    </div>

                </div>

                <div about='informacoes' className='row mb-3'>

                    <div className='col-12'>
                        
                        <div className='col-12'>

                            <Form.Group className="mt-0 mb-3">
                                <Form.Label>Informações da Nota</Form.Label>
                                <Form.Control as="textarea" rows={3} name='descriminacao' id='descriminacao' disabled={isFormDisabled} autoComplete='off' />
                                <Form.Control.Feedback type="invalid">
                                    Preencha as Informações	da Nota!
                                </Form.Control.Feedback>
                            </Form.Group>

                        </div>

                    </div>

                </div>

                <div className='row'>

                    <div className='col-12'>
                        <h2 className={styles.titleSession}>Local de Prestação</h2>
                    </div>

                </div>

                <div about='nf_codcidade_iss' className='row mt-3'>
                    
                    <div className='col-4'>

                        <Form.Group className="mb-3 mt-0">
                            <Form.Label>Cidade</Form.Label>
                            <Select onChange={
                                (choice) => {
                                    let selectValuesAux = selectValues
                                    selectValuesAux['cod_cidade'] = choice as iChoice
                                    setSelectValues(selectValuesAux)

                                    setCodCidade(choice as iChoice)

                                    for (let i = 0; i < Object.values(formData.cidades).length; i++) {
                                        let reg = formData.cidades[i]
                                        if (String(reg.cod_cidade) === selectValuesAux['cod_cidade'].value) {
                                            let ele:any = document.getElementById("ISS")
                                            if (reg.ISS !== null) {
                                                if (empresa.optante_simples_nacional === 2) {
                                                    ele.value = reg.ISS
                                                }
                                                calcAllTributos()
                                            } else {
                                                ele.value = ""
                                            }
                                            break
                                        }
                                    }
                                }
                            } value={codCidade} options={optionsCidades} isDisabled={isFormDisabled} styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: "#dee2e6"
                                }),
                            }} />
                            <Form.Control name='cod_cidade_aux' type="text" id='cod_cidade_aux' placeholder="..." style={{display:"none"}}/>
                            <Form.Control.Feedback type="invalid">
                                Selecione uma Cidade!
                            </Form.Control.Feedback>
                        </Form.Group>

                    </div>

                    <div className='col-2'>

                        {/* <Form.Label>ISS</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                            <input className='form-control inputPor' name='ISS_cidade' type="text" id='ISS_cidade' placeholder="0.00000"/>
                            <Form.Control.Feedback type="invalid">
                                Informe o valor do ISS!
                            </Form.Control.Feedback>
                        </InputGroup> */}

                        <div className='div_valor_tributo'>

                        <p className={styles.titleValorTributo}>ISS</p>

                        <div className={styles.divInputsTributo}>
                            <p>Aliquota (%)</p>
                            <input name='ISS' type="text" onKeyUp={handleChangeInTributo} id='ISS' disabled={isFormDisabled} placeholder="0.00"/>
                        </div>

                        <div className={styles.divInputsTributo}>
                            <p>Valor (R$)</p>
                            <input name='ISS_V' type="text" id='ISS_V' placeholder="0,00" readOnly disabled/>
                        </div>

                        </div>

                    </div>

                    <div className='col-6'>

                        <Form.Group className="mb-3 mt-0">
                            <Form.Label>Status ISS</Form.Label>
                            <Form.Control name='ISS_status' type="text" id='ISS_status' value={statusIss} disabled readOnly/>
                        </Form.Group>

                    </div>

                </div>

                </div>
                
                {
                    Object.values(nfDados).length === 0 &&
                    <div>
                        <Button variant='success' onClick={handleSubmit} about='criar_emitir_nota' disabled={isLoading} style={{ marginRight: "15px" }}>
                        {
                            (isLoading && (formAction === 'criar_emitir')) ?
                            <div>
                                <Spinner animation="border" role="status" variant="light" size='sm' ></Spinner>
                                <span> Aguarde...</span>
                            </div>
                            :
                            "Criar Nota e Emitir"
                        }
                        </Button>
                        
                        <Button variant='success' onClick={handleSubmit} about='criar_nota' disabled={isLoading}>
                        {
                            (isLoading && (formAction === 'criar')) ?
                            <div>
                                <Spinner animation="border" role="status" variant="light" size='sm' ></Spinner>
                                <span> Aguarde...</span>
                            </div>
                            :
                            "Criar Nota"
                        }
                        </Button>
                    </div>
                }

                {
                    nfDados.n_emitido === 0 &&
                    <div>
                        <Button variant='danger' onClick={handleClickDeletar} about='deletar_nota' disabled={isLoading} style={{ marginRight: "15px" }}>
                            {
                                (isLoading && (formAction === 'deletar')) ?
                                <div>
                                    <Spinner animation="border" role="status" variant="light" size='sm' ></Spinner>
                                    <span> Aguarde...</span>
                                </div>
                                :
                                "Deletar nota não lançada"
                            }
                        </Button>

                        <Button variant='success' onClick={handleSubmit} about='editar_nota' disabled={isLoading} style={{ marginRight: "15px" }}>
                            {
                                (isLoading && (formAction === 'editar')) ?
                                <div>
                                    <Spinner animation="border" role="status" variant="light" size='sm' ></Spinner>
                                    <span> Aguarde...</span>
                                </div>
                                :
                                "Editar dados da Nota"
                            }
                        </Button>

                        <Button variant='success' onClick={handleClickEmitir} about='emitir_nota' disabled={isLoading}>
                            {
                                (isLoading && (formAction === 'emitir')) ?
                                <div>
                                    <Spinner animation="border" role="status" variant="light" size='sm' ></Spinner>
                                    <span> Aguarde...</span>
                                </div>
                                :
                                "Emitir Nota"
                            }
                        </Button>
                    </div>
                }

                {
                    (nfDados.n_emitido === 1 && nfDados.n_status !== "Emitido (Cancelado)") &&
                    <Button variant='danger' onClick={handleClickCancelar} about='cancelar_nota' disabled={isLoading} style={{ marginRight: "15px" }}>
                        {
                            (isLoading && (formAction === 'cancelar')) ?
                            <div>
                                <Spinner animation="border" role="status" variant="light" size='sm' ></Spinner>
                                <span> Aguarde...</span>
                            </div>
                            :
                            "Cancelar Nota"
                        }
                    </Button>
                }

                {
                    nfDados.n_emitido === 1 &&
                    <div>
                        <br></br>
                        <Button variant='primary' onClick={handleConsultarXml} about='xml_nota' disabled={isLoading} style={{ marginRight: "15px" }}>
                            {
                                (isLoading && (formAction === 'consultar_xml')) ?
                                <div>
                                    <Spinner animation="border" role="status" variant="light" size='sm' ></Spinner>
                                    <span> Aguarde...</span>
                                </div>
                                :
                                "Consultar XML"
                            }
                        </Button>

                        <Button variant='primary' onClick={handleDownloadXML} about='xml_nota' disabled={isLoading} style={{ marginRight: "15px" }}>
                            {
                                (isLoading && (formAction === 'download_xml')) ?
                                <div>
                                    <Spinner animation="border" role="status" variant="light" size='sm' ></Spinner>
                                    <span> Aguarde...</span>
                                </div>
                                :
                                "Download XML"
                            }
                        </Button>

                        <Button variant='primary' onClick={handleDownloadPDF} about='pdf_nota' disabled={isLoading} style={{ marginRight: "15px" }}>
                        {
                            (isLoading && (formAction === 'pdf')) ?
                            <div>
                                <Spinner animation="border" role="status" variant="light" size='sm' ></Spinner>
                                <span> Aguarde...</span>
                            </div>
                            :
                            "Download PDF"
                        }
                    </Button>
                    </div>
                }
                
                {/* <Button type='submit' variant='success' >Criar Nota</Button> */}

            </Form>

        </div>
    )

}