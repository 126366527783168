import { FormEvent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, OverlayTrigger, Popover, Row } from "react-bootstrap";
import removeAllErrorMsg from "../../../components/funcRemoveAllErros";
import { getCookie } from "../../../config/cookies";
import { ApiEndpoints } from "../../../config/apiClient";
import { toast } from "react-toastify";
import styles from '../styles/importacao.module.css'
import { defineValueInInput } from "../../../components/funcForInput";

interface iProps {
    show: boolean
    handleClose: any,
    handleAtualizarListaNotas: any,
    empresa: any,
}

export default function NfModalImportarPlanilha ({show, handleClose, handleAtualizarListaNotas, empresa}:iProps) {
    const [loading, setLoading] = useState<boolean>(false)
    const [resultados, setResultados] = useState<any[]>([])

    useEffect(() => {
        // eslint-disable-next-line
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dadosImportar = {
            id_empresa: empresa.id_empresa,
            id_usuario: Number(getCookie('id_user')),
            planilha: form.planilha.files,
        }
        console.log(dadosImportar)

        if (dadosImportar.planilha.length === 0) {
            form.planilha.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (!formValidado) {
            return
        }

        importar(dadosImportar)
    }

    async function importar(dadosImportar:any) {
        setLoading(true)

        const token = getCookie("token")
        let dados = new FormData()
        dados.append('id_empresa', dadosImportar.id_empresa)
        dados.append('id_usuario', dadosImportar.id_usuario)
        dados.append('planilha', dadosImportar.planilha[0])

        try {
            let api:Response
            let apiData = await fetch(
                ApiEndpoints.nfImportarPlanilha.endpoint,
                {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                    body: dados
                }
            ).then(response => {
                api = response
                return response.json()
            })
            if (api!.status === 200) {
                console.log(apiData)
                toast.success("Importação finalizada com sucesso!", {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                setResultados(apiData)
                handleAtualizarListaNotas()
            } else {
                toast.warn(String(apiData.message), {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }
        } catch (error) {
            console.log(error)
            toast.warn('Não foi possível importar, verifique se o layout está correto ou contate o suporte!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        setLoading(false)
    }

    const popover = (
        <Popover id="popover-basic" style={{minWidth: "600px"}}>
            <Popover.Header as="h3">Exemplo Layout</Popover.Header>
            <Popover.Body>
                <Row>
                    <Col className="col-3">
                        <p>cnpj*</p>
                        <p>43.001.569/0007-70</p>
                    </Col>
                    <Col className="col-2">
                        <p>valor*</p>
                        <p>R$ 3.818,72</p>
                    </Col>
                    <Col className="col-2">
                        <p>cod_serv*</p>
                        <p>7.10</p>
                    </Col>
                    <Col className="col-5">
                        <p>descriminacao*</p>
                        <p>REFERENTE A JUNHO/2024</p>
                    </Col>
                </Row>
            </Popover.Body>
        </Popover>
    );

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Criar NFs - Importar planilha de Clientes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id={`formImportar`} onSubmit={handleSubmit} noValidate>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Arquivo:</Form.Label>
                                <Form.Control type="file" name="planilha" id="planilha" aria-label="Selecione a Planilha" accept=".xlsx"></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Selecione o arquivo!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    { resultados.length === 0 && <Button type="submit" disabled={loading} style={{marginRight: "10px"}}>{loading ? "Aguarde..." : "Importar"}</Button> }
                    { resultados.length !== 0 && <Button variant="secondary" disabled={loading} onClick={() => {setResultados([]); defineValueInInput("planilha", "")}} style={{marginRight: "10px"}}>{loading ? "Aguarde..." : "Resetar"}</Button> }
                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                        <Button variant="success">Layout</Button>
                    </OverlayTrigger>
                </Form>
                <br></br>
                {resultados.length !== 0 && <h2 style={{fontWeight: "bold"}}>Resultados da importação:</h2> }
                <div>
                    {
                        resultados.map((reg:any, idx:number) => (
                            <Row key={idx} className={styles.rowResultado}>
                                <Col className="col-4">
                                    <p>Linha ({reg.row})</p>
                                    <p>CNPJ: {reg.cnpj}</p>
                                    <p>Valor: R$ {reg.valor}</p>
                                </Col>
                                {
                                    reg.nota_criada === true &&
                                    <Col className="col-8">
                                        <p>Status</p>
                                        <p>{reg.mensagem}</p>
                                        <a href={`/nfs?id_empresa=${empresa.id_empresa}&id_nota=${reg.nota.id_nota}`} target="_blank" rel="noreferrer">Ir para nota</a>
                                    </Col>
                                }
                                {
                                    reg.nota_criada === false &&
                                    <Col className="col-8">
                                        <p>Status (ERRO)</p>
                                        <p>{reg.mensagem}</p>
                                    </Col>
                                }
                            </Row>
                        ))
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}