import { FormEvent, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import removeAllErrorMsg from "../../../../components/funcRemoveAllErros";
import { ApiEndpoints, reqPut } from "../../../../config/apiClient";
import { toast } from "react-toastify";

interface iPropsModalOcorrencias {
    show: boolean,
    handleClose: any,
    handleNew: any,
    dados: any
}

export default function ApuracaoModalOcorrencias ({show, handleClose, handleNew, dados}: iPropsModalOcorrencias) {

    const [loading, setLoading] = useState<boolean>(false)

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dadosCadastar = {
            id_colaborador: dados.dados.colaborador.id_colaborador,
            data_dia: form.data.value,
            data_hora: form.horario.value,
            id_status: Number(form.status.value),
            id_tipo_ocorrencia: Number(form.tipo.value),
        }

        if (dadosCadastar.data_dia === "") {
            form.data.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dadosCadastar.data_hora === "") {
            form.horario.nextElementSibling.style.display = "block"
            formValidado = false
        }

        console.log(dadosCadastar)

        if (!formValidado) {
            return
        }

        cadastrar(dadosCadastar)
    }

    async function cadastrar(dadosCadastrar:any) {
        setLoading(true)

        const api = await reqPut(ApiEndpoints.apuracaoOcorrenciaCadastrar, dadosCadastrar)

        setLoading(false)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Ocorrência cadastrado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            handleNew(api.data)
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="modalApuracaoRight"
            dialogClassName="modalDialogApuracaoRight"
            contentClassName="modalContentApuracaoRight"
        >
            <Modal.Header closeButton>
                <Modal.Title>Cadastrar ocorrência</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id='formCentroCusto' onSubmit={handleSubmit} noValidate>
                    <Row>
                        <Col className="col-6">
                            <Form.Group>
                                <Form.Label>Data:</Form.Label>
                                <Form.Control name='data' type="date" id='date' placeholder="dd/mm/aaaa"/>
                                <Form.Control.Feedback type="invalid">
                                    Informe a Data!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col className="col-6">
                            <Form.Group>
                                <Form.Label>Horário:</Form.Label>
                                <Form.Control name='horario' type="time" id='horario' placeholder="HH:mm"/>
                                <Form.Control.Feedback type="invalid">
                                    Informe o Horário!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Status:</Form.Label>
                                <Form.Select name="status" id="status" aria-label="Selecione o Status">
                                    {
                                        dados.dados.ocorrencias_dados.status.map((reg:any, index:any) => (
                                            <option key={index} value={reg.id_status}>{reg.descricao}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Tipo de Ocorrência:</Form.Label>
                                <Form.Select name="tipo" id="tipo" aria-label="Selecione o Tipo">
                                    {
                                        dados.dados.ocorrencias_dados.tipos.map((reg:any, index:any) => (
                                            <option key={index} value={reg.id_tipo_pendencia}>{reg.id_tipo_pendencia}-{reg.descricao}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Button type="submit" disabled={loading}>{loading ? "Aguarde..." : "Cadastrar"}</Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )

} 