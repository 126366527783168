import React, { useEffect } from 'react'

function DashboardPage() {

    useEffect(() => {
        document.title = 'Dashboard | OB PORTUS'
    })

    return (
        <div id='main'>
            <p>Dashboard</p>
        </div>
    )

}

export default DashboardPage