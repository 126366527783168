import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { iDiaApuracao } from "../dto/dados-apuracao.dto";
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import ApuracaoPageDia from "./ApuracaoPageDia";
import ApuracaoState from "../ApuracaoState";
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

interface iApuracaoDados {
    calendario: iDiaApuracao[],
    filtro: any,
    dados: any,
    totais: any
}

interface iApuracaoDadosProps {
    dados: iApuracaoDados,
    apuracaoState: ApuracaoState,
    onUpdateState: any
}

export default function ApuracaoPageDados({dados, apuracaoState, onUpdateState}:iApuracaoDadosProps) {
    const divDados:React.CSSProperties = {
        borderBottom: "1px solid black",
        padding: "10px 8px"
    }

    const rowHeader:React.CSSProperties = {
        fontWeight: "bold"
    }

    const [, updateState] = useState<any>();
    const handleForceupdateMethod = useCallback(() => updateState({}), []);

    function handleOnUpdate(dadosNovos:any) {
        handleForceupdateMethod()
        onUpdateState(dadosNovos)
    }

    return (
        <div>
            <p></p>

            <div style={divDados}>
                <Row style={rowHeader}>
                    <Col className="col-1"><p>Dia</p></Col>
                    <Col className="col-3"><p>Horas</p></Col>
                    <Col className="col-1"></Col>
                    <Col className="col-4"><p>Cálculo</p></Col>
                    <Col className="auto"><p>Observações</p></Col>
                </Row>
            </div>
            
            {
                dados.calendario.map((item, index) => (
                    <ApuracaoPageDia key={index} dia={item} dados={dados} apuracaoState={apuracaoState} handleOnUpdate={handleOnUpdate} />
                ))
            }
        </div>
    )

}