import dayjs from 'dayjs'

export function getCookie(cname: string) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

export function setCookie(cname:string, cvalue:string) {

    // var d = new Date()
    // var horas = d.getHours()
    // var minuto = d.getMinutes()

    // var secondsUntilEndOfDate = ((24 * 60) - (horas * 60) + (minuto)) * 60;

    let data_atual = dayjs()
    let num_dia_semana = data_atual.day()
    let qtd_dias = (6-num_dia_semana) + 1
    let data_atual_aux = dayjs(`${data_atual.format("YYYY-MM-DD")}`, "YYYY-MM-DD")
    data_atual_aux = data_atual_aux.add(qtd_dias, 'day')
    data_atual_aux = dayjs(`${data_atual_aux.format("YYYY-MM-DD")} 23:59:59`, "YYYY-MM-DD HH:mm:ss")
    let diff = data_atual_aux.diff(data_atual, 'second')
    var expiresDate = `max-age=${diff-10800}`
    document.cookie = cname + "=" + cvalue + "; " + expiresDate + "; path=/";
}

export function deleteCookie(cname:string) {
    let expires = "expires="+ 0;
    document.cookie = cname + "=;" + expires + ";path=/";
}