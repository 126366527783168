import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import styles from '../../styles/apuracao.module.css'
import dayjs from "dayjs"

interface iProps {
    referencia: any,
    handleDeletar: any
}

export default function ApuracaoPagePainelDetalhesReferencias({referencia, handleDeletar}:iProps) {
    const divStyle:React.CSSProperties = {
        margin: "10px 0px",
    }
    
    return (
        <div style={divStyle}>
            <div className={styles.apuracaoDiaRegs}>
                <Row>
                    <Col className="col-4">
                        <p>Flag #{referencia.id_flag}</p>
                        <p>Criado em {dayjs(referencia.data_criacao).format("DD/MM/YYYY HH:mm:ss")}</p>
                        <br></br>
                        <Button size="sm" variant="danger" onClick={() => handleDeletar(referencia)}>Deletar</Button>
                    </Col>
                    <Col className="col-4">
                        <p>Dia de referência:</p>
                        <p>{dayjs(referencia.id_dia_apuracao.data_dia).format("DD/MM/YYYY")} - Horas Necessárias: {referencia.id_dia_apuracao.horas_necessarios}</p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}