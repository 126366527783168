import { FormEvent, useEffect, useState } from "react"
import { getUrlSearchParams } from "../urlParams"
import removeAllErrorMsg from "../../components/funcRemoveAllErros"
import { toast } from "react-toastify"
import funcSleep from "../../components/funcSleep"
import { ApiEndpoints, reqGetNew, reqPatch, reqPostNew, reqPut } from "../../config/apiClient"
import LoadingModal from "../../components/loadingModal"
import { Button, Col, Form, Row } from "react-bootstrap"
import $ from 'jquery'
import 'jquery-mask-plugin'
import Select from "react-select"
import { defineValueInInput } from "../../components/funcForInput"
import { iNfClientesDadosDto, iNfClientesDadosViewDto } from "./dto/nfClientes"

function formatarCnpj(valor:string):string {
    defineValueInInput("cnpj_aux", valor)
    return $("#cnpj_aux").masked(valor)
}

function formatarTelefone(valor:string):string {
    defineValueInInput("telefone_aux", valor)
    return $("#telefone_aux").masked(valor)
}

function NfClientesFormPage() {
    const [loading, setLoading] = useState<boolean>(false)
    const [cidade, setCidade] = useState<any>({ "value": "", "label": "Não Selecionado" })
    const [cidadesFiltro, setCidadesFiltro] = useState<any[]>([])
    const [empresa, setEmpresa] = useState<any>({ "value": "", "label": "Não Selecionado" })
    const [empresasFiltro, setEmpresasFiltro] = useState<any[]>([])
    const [clienteDados, setClienteDados] = useState<iNfClientesDadosDto | any>({})

    useEffect(() => {
        $('.cnpj').mask('00.000.000/0000-00', {reverse: false});
        $('.telefone').mask('(00) 00000-0000', {reverse: false});

        document.title = "Cadastrar NF Cliente | OB PORTUS"

        const urlDados = getUrlSearchParams()
        const id = urlDados.get("id")

        const getDados = async (id:number) => {
            setLoading(true)

            const apiFiltros = await reqGetNew(ApiEndpoints.nfFiltros)

            if (apiFiltros.status === 200) {
                let cidadesAux = apiFiltros.data.nf_cidades.map((item:any, index:any) => (
                    {
                        "value": `${item.cod_cidade}`,
                        "label": `${item.nome}/${item.uf} - Cep ${item.cep}`
                    }
                ))
                setCidadesFiltro(cidadesAux)
                let empresasAux = apiFiltros.data.nf_empresas.map((item:any, index:any) => (
                    {
                        "value": `${item.id_empresa}`,
                        "label": `${item.nome}`
                    }
                ))
                setEmpresasFiltro(empresasAux)
            }

            if (id === null || id === 0) {
                setLoading(false)
                return
            }

            const dados = {
                id_cliente: Number(id)
            }

            const api = await reqPostNew(ApiEndpoints.nfClientesConsultar, dados)

            if (api.status === 200) {
                const cliente:iNfClientesDadosViewDto = api.data
                setClienteDados(cliente)
                console.log(api.data)

                setCidade({
                    "value": `${cliente.cod_cidade}`,
                    "label": `${cliente.cidade}/${cliente.uf} - Cep ${cliente.cep}`
                })
                setEmpresa({
                    "value": `${cliente.id_empresa}`,
                    "label": `${cliente.empresa}`
                })

                let form:any = document.getElementById("formNfCliente")
                form.cnpj.value = formatarCnpj(cliente.cnpj)
                form.razao_social.value = cliente.nome_fantasia
                form.nome.value = cliente.nome
                form.endereco.value = cliente.logradouro
                form.numero.value = cliente.numero
                form.bairro.value = cliente.bairro
                form.cep.value = cliente.cep
                form.complemento.value = cliente.complemento
                form.telefone.value = cliente.telefone
                form.email.value = cliente.email
                if (cliente.inscricao_municipal !== null) {
                    form.inscricao_municipal.value = cliente.inscricao_municipal
                }
                if (cliente.inscricao_estadual !== null) {
                    form.inscricao_estadual.value = cliente.inscricao_estadual
                }
                form.id_tipo_cliente.value = cliente.id_tipo_cliente
            }

            setLoading(false)
        }

        getDados(Number(id))

        if (id !== null) {
            document.title = "Editar NF Cliente | OB PORTUS"
        }
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dados:iNfClientesDadosDto = {
            id_cliente: Object.values(clienteDados).length > 0 ? clienteDados?.id_cliente : null,
            id_empresa: Number(empresa.value),
            cnpj: $("#cnpj").cleanVal(),
            nome: form.nome.value,
            nome_fantasia: form.razao_social.value,
            logradouro: form.endereco.value,
            numero: form.numero.value,
            complemento: form.complemento.value,
            bairro: form.bairro.value,
            cep: form.cep.value,
            cod_cidade: 0,
            cidade: null,
            uf: null,
            telefone: form.telefone.value,
            email: form.email.value,
            inscricao_municipal: form.inscricao_municipal.value,
            inscricao_estadual: form.inscricao_estadual.value,
            id_tipo_cliente: Number(form.id_tipo_cliente.value)
        }

        if (dados.id_empresa === 0) {
            form.empresa_aux.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.cnpj === "" || dados.cnpj.length < 14) {
            form.cnpj.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.nome_fantasia === "") {
            form.razao_social.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.nome === "") {
            form.nome.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.logradouro === "") {
            form.endereco.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.numero === "") {
            form.numero.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.bairro === "") {
            form.bairro.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.cep === "") {
            form.cep.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.complemento === "") {
            dados.complemento = null
        }

        if (Number(cidade.value) === 0) {
            form.cidade_aux.nextElementSibling.style.display = "block"
            formValidado = false
        } else {
            dados.cod_cidade = Number(cidade.value)
        }

        if (dados.telefone === "") {
            form.telefone.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.email === "") {
            form.email.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.telefone === "") {
            form.telefone.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.inscricao_municipal === "") {
            dados.inscricao_municipal = null
        }

        if (dados.inscricao_estadual === "") {
            dados.inscricao_estadual = null
        }

        if (dados.id_tipo_cliente === 0) {
            form.id_tipo_cliente.nextElementSibling.style.display = "block"
            formValidado = false
        }

        console.info(dados)

        if (!formValidado) {
            return
        }

        if (Object.values(clienteDados).length === 0) {
            cadastrar(dados)
        }

        if (Object.values(clienteDados).length > 0) {
            editar(dados)
        }
    
    }

    async function cadastrar(dados:iNfClientesDadosDto) {

        setLoading(true)

        const api = await reqPut(ApiEndpoints.nfClientesCadastrar, dados)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Cliente cadastrado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)
            setLoading(false)

            window.location.assign(`/nf/clientes`)
        }
        setLoading(false)

    }

    async function editar(dados: iNfClientesDadosDto) {
        setLoading(true)

        const api = await reqPatch(ApiEndpoints.nfClientesEditar, dados)

        setLoading(false)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Cliente editado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/nf/clientes`)
        }
    }

    async function handleProcurarCnpj() {

        let cnpj:string = $("#cnpj").cleanVal()
        if (cnpj.length !== 14) {
            toast.warning('Informe um CNPJ válido!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            return
        }

        setLoading(true)

        let link:string = `https://publica.cnpj.ws/cnpj/${cnpj}`

        let apiEmpresaDados:any = null
        let apiDados:Response

        try {
            const options = {method: 'GET', headers: {Accept: 'application/json'}}
            apiDados = await fetch(link, options)
            apiEmpresaDados = await apiDados.json()
            if (apiDados.status === 200) {
                let form:any = document.getElementById("formNfCliente")
                form.razao_social.value = apiEmpresaDados.razao_social
                form.nome.value = apiEmpresaDados.estabelecimento.nome_fantasia
                form.endereco.value = apiEmpresaDados.estabelecimento.logradouro
                form.numero.value = apiEmpresaDados.estabelecimento.numero
                form.bairro.value = apiEmpresaDados.estabelecimento.bairro
                form.complemento.value = apiEmpresaDados.estabelecimento.complemento
                form.cep.value = apiEmpresaDados.estabelecimento.cep
                let telefone = `${apiEmpresaDados.estabelecimento.ddd1}${apiEmpresaDados.estabelecimento.telefone1}`
                if (apiEmpresaDados.estabelecimento.ddd1 !== null && apiEmpresaDados.estabelecimento.telefone1 !== null) {
                    form.telefone.value = formatarTelefone(telefone)
                }
                if (apiEmpresaDados.estabelecimento.email !== null) {
                    form.email.value = apiEmpresaDados.estabelecimento.email
                }
                toast.success('Dados encontrado: razao_social, nome_fantasia, logradouro, numero, bairro, complemento, cep, telefone e email. Salve para guardar alterações!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark',
                    autoClose: 10000,
                })
            }
        } catch(error) {
            console.log(error)
        }

        console.log(apiEmpresaDados)

        setLoading(false)

    }
    
    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <div className="div-row-space-between">
            <h1 id="titleMain">NF Cliente</h1>
            <Button variant="secondary" onClick={() => {window.location.assign(`/nf/clientes`)}}>Voltar</Button>
            </div>
            <br></br>
            <Form id='formNfCliente' onSubmit={handleSubmit} noValidate>
                <Row className="mb-15-forms">
                    <Col className="col-3">
                            <Form.Group>
                            <Form.Label>Empresa:</Form.Label>
                            <Select name='empresa' id='empresa' options={empresasFiltro} value={empresa} placeholder="Selecione a empresa" onChange={
                                    (choice:any) => {
                                        setEmpresa(choice)
                                    }
                                } styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: "#dee2e6"
                                    }),
                                }} />
                            </Form.Group>
                            <Form.Control name='empresa_aux' type="text" id='empresa_aux' placeholder="" style={{display: "none"}}/>
                            <Form.Control.Feedback type="invalid">
                                Selecione a empresa do Cliente!
                            </Form.Control.Feedback>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>CNPJ:</Form.Label>
                            <Form.Control name='cnpj' type="text" id='cnpj' className="cnpj" placeholder="00.000.000/0000-00"/>
                            <Form.Control.Feedback type="invalid">
                                Informe o CNPJ!
                            </Form.Control.Feedback>
                            <input name='cnpj_aux' type="text" id='cnpj_aux' className="cnpj" style={{display: "none"}} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Procurar CNPJ?</Form.Label><br></br>
                            <Button onClick={() => {handleProcurarCnpj()}}>Pesquisar</Button>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Razão Social:</Form.Label>
                            <Form.Control name='razao_social' type="text" id='razao_social' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe a Razão Social!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Nome:</Form.Label>
                            <Form.Control name='nome' type="text" id='nome' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Nome!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-4">
                        <Form.Group>
                            <Form.Label>Endereço:</Form.Label>
                            <Form.Control name='endereco' type="text" id='endereco' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Endereço!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-1">
                        <Form.Group>
                            <Form.Label>Número:</Form.Label>
                            <Form.Control name='numero' type="text" id='numero' placeholder="0"/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Número!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-1">
                        <Form.Group>
                            <Form.Label>Bairro:</Form.Label>
                            <Form.Control name='bairro' type="text" id='bairro' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Bairro!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-2">
                            <Form.Group>
                            <Form.Label>Cidade:</Form.Label>
                            <Select name='cidade' id='cidade' options={cidadesFiltro} value={cidade} placeholder="Selecione a cidade" onChange={
                                    (choice:any) => {
                                        setCidade(choice)
                                    }
                                } styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: "#dee2e6"
                                    }),
                                }} />
                            </Form.Group>
                            <Form.Control name='cidade_aux' type="text" id='cidade_aux' placeholder="" style={{display: "none"}}/>
                            <Form.Control.Feedback type="invalid">
                                Informe a Cidade!
                            </Form.Control.Feedback>
                    </Col>
                    <Col className="col-2">
                        <Form.Group>
                            <Form.Label>Complemento:</Form.Label>
                            <Form.Control name='complemento' type="text" id='complemento' placeholder=""/>
                        </Form.Group>
                    </Col>
                    <Col className="col-2">
                        <Form.Group>
                            <Form.Label>CEP:</Form.Label>
                            <Form.Control name='cep' type="text" id='cep' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o CEP!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Telefone:</Form.Label>
                            <Form.Control name='telefone' type="text" id='telefone' placeholder="(00) 00000-0000" className="telefone" />
                            <Form.Control.Feedback type="invalid">
                                Informe o Telefone!
                            </Form.Control.Feedback>
                            <Form.Control name='telefone_aux' type="text" id='telefone_aux' className="telefone" style={{display: 'none'}} />
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>E-mail:</Form.Label>
                            <Form.Control name='email' type="email" id='email' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o E-mail!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Inscrição Municipal:</Form.Label>
                            <Form.Control name='inscricao_municipal' type="number" id='inscricao_municipal' placeholder="0"/>
                            <Form.Control.Feedback type="invalid">
                                Informe a Inscrição Municipal!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Inscrição Estadual:</Form.Label>
                            <Form.Control name='inscricao_estadual' type="number" id='inscricao_estadual' placeholder="0"/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-3">
                        <Form.Group>
                            <Form.Label>Tipo Cliente:</Form.Label>
                            <Form.Select name="id_tipo_cliente" id="id_tipo_cliente" aria-label="">
                                <option value="0">Não Selecionado</option>
                                <option value="1">PRIVADO</option>
                                <option value="2">MUNICIPAL / ESTADUAL</option>
                                <option value="3">FEDERAL</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Informe o tipo de cliente!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                {
                    Object.values(clienteDados).length === 0 &&
                    <Button variant="success" type="submit" disabled={loading}>{loading ? 'Aguarde ...' : 'Cadastrar'}</Button>
                }

                {
                    Object.values(clienteDados).length > 0 &&
                    <Button variant="success" type="submit" disabled={loading}>{loading ? 'Aguarde ...' : 'Editar'}</Button>
                }
            </Form>
        </div>
    )
}

export default NfClientesFormPage