import dayjs from 'dayjs'
import styles from '../../styles/apuracao.module.css'
import { iDiaApuracao, iResponseApuracao } from '../../dto/dados-apuracao.dto'
import ApuracaoPagePainelDetalhesOcorrenicas from './ApuracaoOcorrencias'
import ApuracaoPagePainelDetalhesAjustes from './ApuracaoAjustes'
import ApuracaoState from '../../ApuracaoState'
import { ApiEndpoints, reqPatch, reqPostNew } from '../../../../config/apiClient'
import { toast } from 'react-toastify'
import { getCookie } from '../../../../config/cookies'
import LoadingModal from '../../../../components/loadingModal'
import React, { useState } from 'react'
import ApuracaoPagePainelDetalhesMarcacoes from './ApuracaoMarcacoes'
import ApuracaoModalObservacao from '../modals/ApuracaoModalObservacao'
import { Button } from 'react-bootstrap'
import ApuracaoPagePainelDetalhesObservacoes from './ApuracaoObservacoes'
import ApuracaoPagePainelDetalhesTrocasTurno from './ApuracaoTrocasTurno'
import ApuracaoPagePainelDetalhesReferencias from './ApuracaoReferencias'
import formatTime from 'format-hours'
import ApuracaoModalEscala from '../modals/ApuracaoModalEscala'

interface iProps {
    dia: iDiaApuracao,
    visivel: boolean,
    dados: iResponseApuracao,
    apuracaoState: ApuracaoState,
    onUpdate: any
}

export default function ApuracaoPagePainelDetalhes({dia, visivel, dados, apuracaoState, onUpdate}:iProps) {

    const [loading, setLoading] = useState<boolean>(false)
    const [showModalObs, setShowModalObs] = useState<boolean>(false)
    const [showModalAlterarEscala, setShowModalAlterarEscala] = useState<boolean>(false)

    const btnFuncDia:React.CSSProperties = {
        marginLeft: "10px"
    }

    async function calcularDiaApuracao(dadosCalcular:any, dadosAux:iResponseApuracao) {
        const apiCalcular = await reqPostNew(ApiEndpoints.apuracaoCalcularDados, dadosCalcular)
        if (apiCalcular.status === 200) {
            let dadosC = apiCalcular.data
            for (let i in dadosAux.calendario) {
                if (dadosAux.calendario[i].data === dadosCalcular.data_inicial) {
                    dadosAux.calendario[i] = dadosC.calendario[0]
                    console.log('calcular_dia')
                    break
                }
            }
            dadosAux.totais = calcularTotaisApuracao(dadosAux.calendario)
        }
        return dadosAux
    }
    
    async function calcularDiaApuracaoPeriodo(dadosCalcular:any, dadosAux:iResponseApuracao) {
        const apiCalcular = await reqPostNew(ApiEndpoints.apuracaoCalcularDados, dadosCalcular)
        if (apiCalcular.status === 200) {
            let dadosC = apiCalcular.data
            return dadosC
        }
        return dadosAux
    }

    function calcularTotaisApuracao(apuracao:any[]):any {
        let totais:any = {
            horas_necessarias: 0,
            HN: "00:00",
            horas_trabalhadas: 0,
            HT: "00:00",
            horas_extras: 0,
            HE: "00:00",
            horas_faltas: 0,
            HF: "00:00",
            adicional_noturno: 0,
            AN: "00:00",
            intra_jornada: 0,
            HI: "00:00",
            dias_trabalhados: 0
        }

        for (let dia of apuracao) {
            totais.horas_necessarias += dia.horas_necessarias
            totais.horas_trabalhadas += dia.horas_trabalhadas
            totais.horas_extras += dia.horas_extras
            totais.horas_faltas += dia.horas_faltas
            totais.adicional_noturno += dia.adicional_noturno
            totais.intra_jornada += dia.intra_jornada

            if (dia.registros.length > 0) {
                totais.dias_trabalhados += 1
            }
        }

        totais.HN = formatTime(Math.ceil(((totais.horas_necessarias / 60) * 100)) / 100, {fullSize: true})
        totais.HT = formatTime(Math.ceil(((totais.horas_trabalhadas / 60) * 100)) / 100, {fullSize: true})
        totais.HE = formatTime(Math.ceil(((totais.horas_extras / 60) * 100)) / 100, {fullSize: true})
        totais.HF = formatTime(Math.ceil(((totais.horas_faltas / 60) * 100)) / 100, {fullSize: true})
        totais.AN = formatTime(Math.ceil(((totais.adicional_noturno / 60) * 100)) / 100, {fullSize: true})
        totais.HI = formatTime(Math.ceil(((totais.intra_jornada / 60) * 100)) / 100, {fullSize: true})
        
        return totais
    }

    async function handleOcorrenciaCancelar(reg:any) {
        setLoading(true)
        let dadosOcorrencia = { id_validacao: reg.id_validacao }
        let api = await reqPatch(ApiEndpoints.apuracaoOcorrenciaCancelar, dadosOcorrencia)
        if (api.status === 200) {
            console.log(api.data)
            let novaOcorrencia = api.data
            let dadosAux = dados
            for (let i in dadosAux.dados.ocorrencias) {
                if (dadosAux.dados.ocorrencias[i].id_validacao === novaOcorrencia.id_validacao) {
                    dadosAux.dados.ocorrencias[i] = novaOcorrencia
                    console.log('cancelar_ocorrencia')
                    break
                }
            }
            apuracaoState.atualizarApuracaoState(dadosAux)
            toast.success('Ocorrência cancelada!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            onUpdate(dadosAux)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    async function handleAjuste(ajuste:any, id_status:number) {
        setLoading(true)
        const dadosAjuste = {
            id_ajuste: ajuste.a_id_ajuste,
            id_status: id_status,
            id_usuario: Number(getCookie('id_user'))
        }
        let api = await reqPatch(ApiEndpoints.apuracaoAjusteEditar, dadosAjuste)
        if (api.status === 200) {
            console.log(api.data)
            let novoAjuste = api.data
            let dadosAux = dados
            for (let i in dadosAux.dados.ajustes) {
                if (dadosAux.dados.ajustes[i].a_id_ajuste === novoAjuste.a_id_ajuste) {
                    dadosAux.dados.ajustes[i] = novoAjuste
                    console.log('analisar_ajuste')
                    break
                }
            }
            if (id_status === 2 || id_status === 1) {
                const dadosCalcular = {
                    id_colaborador: ajuste.a_id_colaborador,
                    data_inicial: ajuste.horarios[0].data_ajuste,
                    data_final: ajuste.horarios[0].data_ajuste,
                }
                dadosAux = await calcularDiaApuracao(dadosCalcular, dadosAux)
            }
            apuracaoState.atualizarApuracaoState(dadosAux)
            toast.success('Ajuste analisado!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            onUpdate(dadosAux)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    async function handleNewObservacao(obs:any) {
        setShowModalObs(false)
        setLoading(true)
        let dadosAux = dados
        if (listarObservacoesDia(dia) === null) {
            dadosAux.dados.observacoes.push(obs)
            console.log('add_editar_obs')
        }
        if (obs.id_observacao !== null) {
            for (let i in dadosAux.dados.observacoes) {
                if (dadosAux.dados.observacoes[i].id_observacao === obs.id_observacao) {
                    dadosAux.dados.observacoes[i] = obs
                    console.log('editar_obs')
                    break
                }
            }
        }
        const dadosCalcular = {
            id_colaborador: obs.id_colaborador,
            data_inicial: obs.data_dia,
            data_final: obs.data_dia,
        }
        dadosAux = await calcularDiaApuracao(dadosCalcular, dadosAux)
        apuracaoState.atualizarApuracaoState(dadosAux)
        onUpdate(dadosAux)
        setLoading(false)
    }

    async function handleDeleteObservacao(obs:any) {
        setShowModalObs(false)
        setLoading(true)
        let dadosAux = dados
        dadosAux.dados.observacoes = dadosAux.dados.observacoes.filter((reg) => reg.id_observacao !== obs.id_observacao)
        console.log('deletar_obs')
        const dadosCalcular = {
            id_colaborador: obs.id_colaborador,
            data_inicial: obs.data_dia,
            data_final: obs.data_dia,
        }
        dadosAux = await calcularDiaApuracao(dadosCalcular, dadosAux)
        apuracaoState.atualizarApuracaoState(dadosAux)
        onUpdate(dadosAux)
        setLoading(false)
    }

    async function handleMarcacoesAtualizar() {
        if (!window.confirm(`Tem certeza que deseja atualizar as marcações do dia ${dayjs(dia.data).format("DD/MM/YYYY")} - ${dia.data_dia_nome}?`)) {
            return
        }

        const dadosMarcacoes = {
            id_colaborador: dados.dados.colaborador.id_colaborador,
            data_dia: dia.data
        }

        setLoading(true)
        const api = await reqPostNew(ApiEndpoints.apuracaoMarcacoesAtualizar, dadosMarcacoes)
        if (api.status === 200) {
            toast.success('Marcações atualizadas com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            console.log('atualizar_marcacoes')
            const dadosCalcular = {
                id_colaborador: dadosMarcacoes.id_colaborador,
                data_inicial: dadosMarcacoes.data_dia,
                data_final: dadosMarcacoes.data_dia,
            }
            let dadosAux = dados
            dadosAux = await calcularDiaApuracao(dadosCalcular, dadosAux)
            apuracaoState.atualizarApuracaoState(dadosAux)
            onUpdate(dadosAux)
        }
        setLoading(false)
    }

    async function handleTrocaTurnoEditar(dadosEditar:any, troca_turno:any) {
        setLoading(true)
        const api = await reqPatch(ApiEndpoints.apuracaoTrocaTurnoEditar, dadosEditar)
        let dadosAux = dados
        if (api.status === 200) {
            for (let i in dadosAux.dados.trocas_turno) {
                if (dadosAux.dados.trocas_turno[i].id === dadosEditar.id) {
                    dadosAux.dados.trocas_turno[i] = api.data
                    console.log('editar_troca_turno')
                    break
                }
            }
        }
        const dadosCalcular1 = {
            id_colaborador: dados.dados.colaborador.id_colaborador,
            data_inicial: troca_turno.data_dia,
            data_final: troca_turno.data_dia,
        }
        dadosAux = await calcularDiaApuracao(dadosCalcular1, dadosAux)
        const dadosCalcular2 = {
            id_colaborador: dados.dados.colaborador.id_colaborador,
            data_inicial: troca_turno.data_dia_aux,
            data_final: troca_turno.data_dia_aux,
        }
        dadosAux = await calcularDiaApuracao(dadosCalcular2, dadosAux)
        apuracaoState.atualizarApuracaoState(dadosAux)
        onUpdate(dadosAux)
        setLoading(false)
    }

    async function handleDeletarReferencia(referencia:any) {
        setLoading(true)
        const dadosDeletar = {
            id_flag: referencia.id_flag
        }
        const api = await reqPostNew(ApiEndpoints.apuracaoReferenciaDeletar, dadosDeletar)
        let dadosAux = dados
        if (api.status === 200) {
            dadosAux.dados.flags = dadosAux.dados.flags.filter((reg) => reg.id_flag !== referencia.id_flag)
            console.log('deletar_flag')
            const dadosCalcular = {
                id_colaborador: dados.dados.colaborador.id_colaborador,
                data_inicial: referencia.data_dia,
                data_final: referencia.data_dia,
            }
            dadosAux = await calcularDiaApuracao(dadosCalcular, dadosAux)
            apuracaoState.atualizarApuracaoState(dadosAux)
            onUpdate(dadosAux)
            toast.success('Referência deletada!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }
        setLoading(false)
    }
    
    async function handleNewHistoricoEscala() { 
      setLoading(true)
      
      let dadosAux = dados
      const dadosCalcular = {
          id_colaborador: dados.dados.colaborador.id_colaborador,
          data_inicial: dados.filtro.data_inicial,
          data_final: dados.filtro.data_final,
      }
      let dadosC = await calcularDiaApuracaoPeriodo(dadosCalcular, dadosAux)
      apuracaoState.atualizarApuracaoState(dadosC)
      onUpdate(dadosC)
      
      setShowModalAlterarEscala(false)
      setLoading(false)
    }

    return (
        <div className={styles.apuracaoDiaDetalhes} style={{ display: visivel ? 'block' : 'none' }}>
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <div className='div-row'>
                <Button style={btnFuncDia} onClick={() => setShowModalAlterarEscala(true)} >Alterar Escala</Button>
                <Button style={btnFuncDia} onClick={() => setShowModalObs(true)} >Adicionar Observação</Button>
                <Button style={btnFuncDia} onClick={() => handleMarcacoesAtualizar()} >Atualizar Marcações</Button>
            </div>
            <br></br>
            <p>Detalhes</p>
            {
                listarAjustesDia(dia).length > 0 &&
                <ApuracaoPagePainelDetalhesAjustes dadosAjustes={listarAjustesDia(dia)} handleAjuste={handleAjuste} />
            }
            {
                listarObservacoesDia(dia) !== null &&
                <ApuracaoPagePainelDetalhesObservacoes obs={listarObservacoesDia(dia)} />
            }
            {
                listarFlagsDia(dia) !== null &&
                <ApuracaoPagePainelDetalhesReferencias referencia={listarFlagsDia(dia)} handleDeletar={handleDeletarReferencia} />
            }
            {
                listarTrocaTurnoDia(dia) !== null &&
                <ApuracaoPagePainelDetalhesTrocasTurno dia={dia} handleEditar={handleTrocaTurnoEditar} troca_turno={listarTrocaTurnoDia(dia)} />
            }
            {
                listarMarcacoesDia(dia).length > 0 &&
                <ApuracaoPagePainelDetalhesMarcacoes dadosMarcacoes={listarMarcacoesDia(dia)} dia={dia} colaborador={dados.dados.colaborador} />
            }
            {
                listarOcorrenciasDia(dia).length > 0 &&
                <ApuracaoPagePainelDetalhesOcorrenicas dadosOcorrencias={listarOcorrenciasDia(dia)} handleOcorrenciaCancelar={handleOcorrenciaCancelar} />
            }
        <ApuracaoModalObservacao show={showModalObs} handleClose={() => setShowModalObs(false)} handleNew={handleNewObservacao} handleDelete={handleDeleteObservacao} dados={dados} data_dia={dia.data} dia={dia} observacao={listarObservacoesDia(dia)} />
        
        <ApuracaoModalEscala show={showModalAlterarEscala} handleClose={() => setShowModalAlterarEscala(false)} handleNew={handleNewHistoricoEscala} data_dia={dia.data} dados={dados.dados} />
        
        </div>
    )

    function listarOcorrenciasDia(dia:iDiaApuracao):any[] {
        let ocorrencias:any[] = []

        let data_inicial = dayjs(`${dia.data} 00:00:00`)
        let data_final = dayjs(`${dia.data} 23:59:59`)
        if (dia.escala.tipo_diurna_noturna === 1) {
            data_inicial = dayjs(`${dia.data} 12:00:00`)
            data_final = data_inicial.add(1, 'day')
        }

        for (let reg of dados.dados.ocorrencias) {
            let data_criacao_marcacao = dayjs(reg.data_criacao)
            if (data_criacao_marcacao.isSameOrAfter(data_inicial) && data_criacao_marcacao.isSameOrBefore(data_final)) {
                ocorrencias.push(reg)
            }
        }
        
        return ocorrencias.sort((a,b) => a.id_validacao - b.id_validacao)
    }

    function listarAjustesDia(dia:iDiaApuracao):any[] {
        let ajusteDia:any[] = []

        let data_inicial = dayjs(`${dia.data} 00:00:00`)
        let data_final = dayjs(`${dia.data} 23:59:59`)
        if (dia.escala.tipo_diurna_noturna === 1) {
            data_inicial = dayjs(`${dia.data} 12:00:00`)
            data_final = data_inicial.add(1, 'day')
        }

        for (let ajuste of dados.dados.ajustes) {
            let horarios = ajuste.horarios
            for (let reg of horarios) {
                let data_hora_ajuste = dayjs(`${reg.data_ajuste} ${reg.hora_ajuste}`)
                if (data_hora_ajuste.isSameOrAfter(data_inicial) && data_hora_ajuste.isSameOrBefore(data_final)) {
                    ajusteDia.push(ajuste)
                    break
                }
            }
        }

        return ajusteDia
    }

    function listarMarcacoesDia(dia:iDiaApuracao):any[] {
        let marcacoes:any[] = []

        let data_inicial = dayjs(`${dia.data} 00:00:00`)
        let data_final = dayjs(`${dia.data} 23:59:59`)
        if (dia.escala.tipo_diurna_noturna === 1) {
            data_inicial = dayjs(`${dia.data} 12:00:00`)
            data_final = data_inicial.add(1, 'day')
        }

        for (let reg of dados.dados.marcacoes) {
            let data_criacao_marcacao = dayjs(reg.data_criacao)
            if (data_criacao_marcacao.isSameOrAfter(data_inicial) && data_criacao_marcacao.isSameOrBefore(data_final)) {
                marcacoes.push(reg)
            }
        }

        return marcacoes
    }

    function listarObservacoesDia(dia:iDiaApuracao):any {
        let obs:any = null
        for (let reg of dados.dados.observacoes) {
            if (reg.data_dia === dia.data) {
                obs = reg
                break
            }
        }
        return obs
    }

    function listarTrocaTurnoDia(dia:iDiaApuracao):any {
        let troca_turno:any = null
        for (let reg of dados.dados.trocas_turno) {
            if (reg.data_dia === dia.data) {
                troca_turno = reg
                break
            }
            if (reg.data_dia_aux === dia.data) {
                troca_turno = reg
                break
            }
        }
        return troca_turno
    }

    function listarFlagsDia(dia:iDiaApuracao):any | null {
        let flag = null
        for (let reg of dados.dados.flags) {
            if (reg.data_dia === dia.data) {
                flag = reg
                break
            }
        }
        return flag
    }
}
