import { FormEvent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import removeAllErrorMsg from "../../../../components/funcRemoveAllErros";
import styles from '../../styles/apuracao.module.css'
import { ApiEndpoints, reqPut } from "../../../../config/apiClient";
import { toast } from "react-toastify";
import { getCookie } from "../../../../config/cookies";
import dayjs from "dayjs";
import { getValueFromInput, defineValueInInput } from "../../../../components/funcForInput";
import Select from 'react-select'

interface iPropsModal {
    show: boolean,
    handleClose: any,
    handleNew: any,
    dados: any
}

export default function ApuracaoModalAjustes ({show, handleClose, handleNew, dados}: iPropsModal) {

    const [loading, setLoading] = useState<boolean>(false)
    const [justificativaSelecionada, setJustificativaSelecionada] = useState<any>({
        "value": "0",
        "label": "Selecione o Tipo"
    })
    const [justificativaFiltro, setJustificativaFiltro] = useState<any[]>([])
    const [cancelar, setCancelar] = useState<number>(0)

    useEffect(() => {

        let filtroAux = dados.dados.ocorrencias_dados.justificativas.map((item:any, index:any) => (
            {
                "value": `${item.id_justificativa}`,
                "label": `${item.id_justificativa} - ${item.descricao}`
            }
        ))
        setJustificativaFiltro(filtroAux)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()
    }

    function handleSubmitClick(event:any, id_status_ajuste:number):void {
        removeAllErrorMsg()

        const form:any = event.target.form
        let formValidado = true

        let dadosCadastar:any = {
            id_colaborador: dados.dados.colaborador.id_colaborador,
            id_status: id_status_ajuste,
            id_justificativa: Number(justificativaSelecionada.value),
            observacao: form.observacao.value,
            id_solicitante: Number(getCookie('id_user')),
            id_usuario: Number(getCookie('id_user')),
            cancelar: Number(form.cancelar.value)
        }

        if (dadosCadastar.id_justificativa === 0) {
            form.justificativa_aux.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dadosCadastar.observacao === "" || String(dadosCadastar.observacao).length <= 20) {
            form.observacao.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dadosCadastar.cancelar === 0) {
            let horarios = []

            if (dados.dados.colaborador.id_escala.intervalo_registrar === 0) {
                horarios.push({
                    id_tipo_marcacao: 0,
                    data_ajuste: form.data1.value,
                    hora_ajuste: form.horario1.value + ":00",
                    intervalo: null
                })
                horarios.push({
                    id_tipo_marcacao: 1,
                    data_ajuste: form.data2.value,
                    hora_ajuste: form.horario2.value + ":00",
                    intervalo: form.intervalo.checked === true ? 1 : 0,
                })
            }

            if (dados.dados.colaborador.id_escala.intervalo_registrar === 1) {
                horarios.push({
                    id_tipo_marcacao: 0,
                    data_ajuste: form.data1.value,
                    hora_ajuste: form.horario1.value + ":00",
                    intervalo: null
                })
                horarios.push({
                    id_tipo_marcacao: 1,
                    data_ajuste: form.data2.value,
                    hora_ajuste: form.horario2.value + ":00",
                    intervalo: null
                })
                horarios.push({
                    id_tipo_marcacao: 0,
                    data_ajuste: form.data3.value,
                    hora_ajuste: form.horario3.value + ":00",
                    intervalo: null
                })
                horarios.push({
                    id_tipo_marcacao: 1,
                    data_ajuste: form.data4.value,
                    hora_ajuste: form.horario4.value + ":00",
                    intervalo: null
                })
            }

            dadosCadastar['horarios'] = horarios
            let formValidadoAux = validarHorarios(horarios)
            if (formValidadoAux === false) {
                formValidado = false
            }
            
        }

        if (dadosCadastar.cancelar === 1) {

            let data_cancelar = form.data_cancelar.value

            if (data_cancelar === "") {
                form.observacao.nextElementSibling.style.display = "block"
                formValidado = false
            } else {
                let horarios = [
                    {
                        id_tipo_marcacao: 0,
                        data_ajuste: data_cancelar,
                        hora_ajuste: "00:00:00",
                        intervalo: null
                    },
                    {
                        id_tipo_marcacao: 1,
                        data_ajuste: data_cancelar,
                        hora_ajuste: "00:00:00",
                        intervalo: null
                    },
                    {
                        id_tipo_marcacao: 0,
                        data_ajuste: data_cancelar,
                        hora_ajuste: "00:00:00",
                        intervalo: null
                    },
                    {
                        id_tipo_marcacao: 1,
                        data_ajuste: data_cancelar,
                        hora_ajuste: "00:00:00",
                        intervalo: null
                    }
                ]
                dadosCadastar['horarios'] = horarios
            }

        }

        console.log(dadosCadastar)

        if (!formValidado) {
            return
        }

        cadastrar(dadosCadastar)
    }

    function validarHorarios(horarios:any[]):boolean {
        if (dados.dados.colaborador.id_escala.intervalo_registrar === 0) {
            let campo_vazio = false
            let campos_iguais = false
            for (let i = 0; i < horarios.length; i++) {
                if (horarios[i]['data_ajuste'].length < 10 || horarios[i]['hora_ajuste'].length < 5) {
                    campo_vazio = true
                }
        
                if (horarios[i]['data_ajuste'] === horarios[i]['hora_ajuste']) {
                    campos_iguais = true
                }
            }
        
            if (campo_vazio === true) {
                toast.error('Informe todos os campos de data e hora!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                return false
            }
        
            if (campos_iguais === true) {
                toast.error('As datas não podem ser iguais!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                return false
            }

            let d_entrada_1 = dayjs(`${horarios[0].data_ajuste} ${horarios[0].hora_ajuste}`)
            let d_saida_1 = dayjs(`${horarios[1].data_ajuste} ${horarios[1].hora_ajuste}`)
    
            let d_atual = dayjs()
    
            let difEntradaSaida = d_saida_1.diff(d_entrada_1, "minutes", true)
    
            if (d_entrada_1.isAfter(d_saida_1)) {
                toast.error('Data de entrada não deve ser depois que a data de saida!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                return false
            }
    
            if (d_saida_1.isAfter(d_atual)) {
                toast.error('Data de saida não deve ser depois que a data atual!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                return false
            }
    
            if (difEntradaSaida >= 1440 || difEntradaSaida <= -1440) {
                toast.error('O tempo entre as duas datas, não deve ser maior que 1 dia!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                return false
            }
        }

        if (dados.dados.colaborador.id_escala.intervalo_registrar === 1) {
            let campo_vazio = false
            let campos_iguais = false
            for (let i = 0; i < horarios.length; i++) {
                if (horarios[i]['data_ajuste'].length < 10 || horarios[i]['hora_ajuste'].length < 5) {
                    campo_vazio = true
                }
    
                if (horarios[i]['data_ajuste'] === horarios[i]['hora_ajuste']) {
                    campos_iguais = true
                }
            }
    
            if (campo_vazio === true) {
                toast.error('Informe todos os campos de data e hora!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                return false
            }
    
            if (campos_iguais === true) {
                toast.error('As datas não podem ser iguais!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                return false
            }

            let d_entrada_1 = dayjs(`${horarios[0].data_ajuste} ${horarios[0].hora_ajuste}`)
            let d_saida_1 = dayjs(`${horarios[1].data_ajuste} ${horarios[1].hora_ajuste}`)
            let d_entrada_2 = dayjs(`${horarios[2].data_ajuste} ${horarios[2].hora_ajuste}`)
            let d_saida_2 = dayjs(`${horarios[3].data_ajuste} ${horarios[3].hora_ajuste}`)
    
            let difEntradaSaida = d_saida_2.diff(d_entrada_1, "minutes", true)

            let d_atual = dayjs()
    
            if (d_entrada_1.isAfter(d_saida_1) || d_entrada_2.isAfter(d_saida_2)) {
                toast.error('Data de entrada não deve ser depois que a data de saida!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                return false
            }
    
            if (d_saida_1.isAfter(d_atual) || d_saida_2.isAfter(d_atual)) {
                toast.error('Data de saida não deve ser depois que a data atual!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                return false
            }
    
            if (difEntradaSaida >= 1440 || difEntradaSaida <= -1440) {
                toast.error('O tempo entre as duas datas, não deve ser maior que 1 dia!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                return false
            }
    
            if (d_entrada_2.isBefore(d_entrada_1) || d_entrada_2.isBefore(d_saida_1)) {
                toast.error('O 2º registro de entrada precisa ser após a entrada e saida do 1º registro!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                return false
            }
    
            if (d_saida_2.isBefore(d_entrada_1) || d_saida_2.isBefore(d_saida_1)) {
                toast.error('O 2º registro de saida precisa ser após a entrada e saida do 1º registro!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                return false
            }
        }

        return true
    }

    async function cadastrar(dadosCadastrar:any) {
        setLoading(true)

        const api = await reqPut(ApiEndpoints.apuracaoAjusteCadastrar, dadosCadastrar)

        setLoading(false)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Ajuste cadastrado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            handleNew(api.data)
            setJustificativaSelecionada({"value": "0","label": "Selecione o Tipo"})
        }
    }

    function handleChangeData(event:any): void {
        let data1 = getValueFromInput('data1')

        if (data1 === "") {
            return
        }

        defineValueInInput('data2', data1)
        defineValueInInput('data3', data1)
        defineValueInInput('data4', data1)

    }

    function handleChangeJustificativa(id_justificativa:number): void {
        if (id_justificativa === 14) {
            defineValueInInput("observacao", "Removendo registros duplicados.")
        }
        if (id_justificativa === 16) {
            defineValueInInput("observacao", "Removendo registros FT.")
        }
        if (id_justificativa === 18) {
            defineValueInInput("observacao", "Removendo registros Inválido.")
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => {handleClose();setJustificativaSelecionada({"value": "0","label": "Selecione o Tipo"})}}
            backdrop="static"
            keyboard={false}
            className="modalApuracaoRight"
            dialogClassName="modalDialogApuracaoRight"
            contentClassName="modalContentApuracaoRight"
        >
            <Modal.Header closeButton>
                <Modal.Title>Cadastrar Ajuste</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id='formAjuste' onSubmit={handleSubmit} noValidate>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Justificativa:</Form.Label>
                                <Select name='justificativa' id='justificativa' value={justificativaSelecionada} options={justificativaFiltro} placeholder="Selecione o Tipo" onChange={
                                    (choice:any) => {
                                        setJustificativaSelecionada(choice)
                                        handleChangeJustificativa(Number(choice.value))
                                    }
                                } styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: "#dee2e6"
                                    }),
                                }} />
                                <Form.Control name='justificativa_aux' type="text" id='justificativa_aux' placeholder="..." style={{display:"none"}}/>
                                <Form.Control.Feedback type="invalid">
                                    Informe a Justificativa!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Cancelar marcações do dia?</Form.Label>
                                <Form.Select name="cancelar" id="cancelar" aria-label="Selecione" onChange={(event) => {
                                    if (event.target.value === "0") {
                                        setCancelar(0)
                                    }
                                    if (event.target.value === "1") {
                                        setCancelar(1)
                                    }
                                }}>
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <div style={{ display: cancelar === 1 ? "none" : "block" }}>
                    <div className={styles.groupFormRegistros}>
                        <Row>
                            <p className={styles.groupFormRegistrosTipo}><b>Entrada</b></p>
                            <Col className="col-6">
                                <Form.Group>
                                    <Form.Label>Data</Form.Label>
                                    <Form.Control name='data1' type="date" id='data1' placeholder="dd/mm/aaaa" onChange={handleChangeData}/>
                                    <Form.Control.Feedback type="invalid">
                                        Informe a Data!
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div style={{ display: dados.dados.colaborador.id_escala.intervalo_registrar === 0 ? "block" : "none" }}>
                                    <br/>
                                    <label style={{marginRight: "10px"}}>Intervalo Realizado?</label>
                                    <input name='intervalo' type="checkbox" id='intervalo' defaultChecked/>
                                </div>
                            </Col>
                            <Col className="col-6">
                                <Form.Group>
                                    <Form.Label>Horário</Form.Label>
                                    <Form.Control name='horario1' type="time" id='horario1' placeholder="HH:mm"/>
                                    <Form.Control.Feedback type="invalid">
                                        Informe o Horário!
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <p className={styles.groupFormRegistrosTipo}><b>Saída</b></p>
                            <Col className="col-6">
                                <Form.Group>
                                    <Form.Label>Data</Form.Label>
                                    <Form.Control name='data2' type="date" id='data2' placeholder="dd/mm/aaaa"/>
                                    <Form.Control.Feedback type="invalid">
                                        Informe a Data!
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col className="col-6">
                                <Form.Group>
                                    <Form.Label>Horário</Form.Label>
                                    <Form.Control name='horario2' type="time" id='horario2' placeholder="HH:mm"/>
                                    <Form.Control.Feedback type="invalid">
                                        Informe o Horário!
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <br/>
                    <div className={styles.groupFormRegistros} style={{ display: dados.dados.colaborador.id_escala.intervalo_registrar === 1 ? "block" : "none" }}>
                        <Row>
                            <p className={styles.groupFormRegistrosTipo}><b>Entrada</b></p>
                            <Col className="col-6">
                                <Form.Group>
                                    <Form.Label>Data</Form.Label>
                                    <Form.Control name='data3' type="date" id='data3' placeholder="dd/mm/aaaa"/>
                                    <Form.Control.Feedback type="invalid">
                                        Informe a Data!
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col className="col-6">
                                <Form.Group>
                                    <Form.Label>Horário</Form.Label>
                                    <Form.Control name='horario3' type="time" id='horario3' placeholder="HH:mm"/>
                                    <Form.Control.Feedback type="invalid">
                                        Informe o Horário!
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <p className={styles.groupFormRegistrosTipo}><b>Saída</b></p>
                            <Col className="col-6">
                                <Form.Group>
                                    <Form.Label>Data</Form.Label>
                                    <Form.Control name='data4' type="date" id='data4' placeholder="dd/mm/aaaa"/>
                                    <Form.Control.Feedback type="invalid">
                                        Informe a Data!
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col className="col-6">
                                <Form.Group>
                                    <Form.Label>Horário</Form.Label>
                                    <Form.Control name='horario4' type="time" id='horario4' placeholder="HH:mm"/>
                                    <Form.Control.Feedback type="invalid">
                                        Informe o Horário!
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <br style={{ display: dados.dados.colaborador.id_escala.intervalo_registrar === 1 ? "block" : "none" }} />
                    </div>
                    <div style={{ display: cancelar === 0 ? "none" : "block" }}>
                        <Row>
                            <Col className="col-12">
                                <Form.Group>
                                    <Form.Label>Informe a data que será cancelado as marcações:</Form.Label>
                                    <Form.Control name='data_cancelar' type="date" id='data_cancelar' placeholder="dd/mm/aaaa"/>
                                    <Form.Control.Feedback type="invalid">
                                        Informe a Data para cancelar!
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br/>
                    </div>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Observação:</Form.Label>
                                <Form.Control name="observacao" id="observacao" as="textarea" aria-label="" />
                                <Form.Control.Feedback type="invalid">
                                    Informe a Justificativa!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Button variant="success" onClick={(e) => {handleSubmitClick(e, 2)}} disabled={loading} style={{marginRight: '5px'}}>{loading ? "Aguarde..." : "Cadastrar E Aprovar"}</Button>
                    <Button variant="warning" onClick={(e) => {handleSubmitClick(e, 1)}} disabled={loading}>{loading ? "Aguarde..." : "Cadastrar"}</Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {handleClose();setJustificativaSelecionada({"value": "0","label": "Selecione o Tipo"})}}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )

} 