import { Modal, Spinner } from "react-bootstrap";

interface iPropsLoadingModal {
    show: boolean
    handleClose: any
}

export default function LoadingModal(props:iPropsLoadingModal) {
    return (
        <Modal show={props.show} onHide={props.handleClose} centered={true} backdrop={"static"} keyboard={false}>
            {/* <Modal.Header>
            <Modal.Title style={{fontSize: "16px"}}>Aguarde</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                <div className="div-row">
                    <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    <p style={{marginLeft: "16px", fontWeight: 'bold'}}>Carregando...</p>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
            <Button variant="secondary" onClick={props.handleClose}>
                Fechar
            </Button>
            </Modal.Footer> */}
        </Modal>
    )
}