import React , { FormEvent, useEffect, useState } from 'react'
import styles from '../styles/inicial.module.css'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import removeAllErrorMsg from '../../../components/funcRemoveAllErros'
import Select from "react-select"
import { ApiEndpoints, reqGetNew, reqPatch, reqPostNew, reqPut } from '../../../config/apiClient'
import $ from 'jquery'
import 'jquery-mask-plugin'
import { defineValueInInput } from '../../../components/funcForInput'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { NfNotasCadastrarDto } from '../dto/nf_notas'
import LoadingModal from '../../../components/loadingModal'
import { toast } from 'react-toastify'
import NfModalEmitir from './NfModalEmitir'
import NfModalDownload from './NfModalDownload'
import funcSleep from '../../../components/funcSleep'

interface iProps {
    empresa: any,
    notaDados: any | null,
    modelo: any | null,
    handleClose: any,
    handleUpdateNfs: any
}

interface iFiltros {
    nf_cidades: any[],
    nf_empresas: any[],
    nf_tipos_clientes: any[],
    nf_servicos: any[],
    nf_servicos_empresas: any[],
    nf_clientes: any[],
}

const divControls:React.CSSProperties = {
    width: "auto",
    position: "fixed",
    right: "40px",
    bottom: "20px",
}

const divButtonsControls:React.CSSProperties = {
    marginRight: "10px",
}

function formatarDinheiro(valor:string):string {
    defineValueInInput("dinheiro_aux", valor)
    return $("#dinheiro_aux").masked(valor)
}

function formatarPorcentagem(valor:string):string {
    defineValueInInput("porcentagem_aux", valor)
    return $("#porcentagem_aux").masked(valor)
}

function getInputPorcentagem(id:string):string {
    let input:any = document.getElementById(id)
    let value:string = input.value
    if (value === "" || value === "0") {
        value = "0.0"
    }
    return value
}
function getInputDinheiro(id:string):string {
    let input:any = document.getElementById(id)
    let value:string = input.value
    if (value === "") {
        value = "0,0"
    }
    return value.replaceAll(".", "").replaceAll(",", ".")
}

const customSelectStylesNfs = {
    control: (baseStyles:any, state:any) => ({
        ...baseStyles,
        borderColor: "#dee2e6",
        backgroundColor: state.isDisabled ? "#E9ECEF" : "white",
    }),
    singleValue: (provided:any, state:any) => ({
        ...provided,
        color: state.isDisabled ? "black" : "black",
    }),
}

function ModalMessageXMLError(props:any) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            // centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <b>Erro</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <textarea id="modal_msg_xml_erro" className='form-control' style={{height: "512px"}} value={props.xml} readOnly></textarea>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function NfView({ empresa, notaDados, modelo, handleClose, handleUpdateNfs }:iProps) {

    const [codServ, setCodServ] = useState<any>({ "value": "0", "label": "Não Selecionado" })
    const [codServFiltro, setCodServFiltro] = useState<any[]>([])
    const [codCidade, setCodCidade] = useState<any>({ "value": "0", "label": "Não Selecionado" })
    const [codCidadeFiltro, setCodCidadeFiltro] = useState<any[]>([])
    const [idCliente, setIdCliente] = useState<any>({ "value": "0", "label": "Não Selecionado" })
    const [idClienteFiltro, setIdClienteFiltro] = useState<any[]>([])

    const [filtros, setFiltros] = useState<iFiltros>({
        nf_cidades: [],
        nf_empresas: [],
        nf_tipos_clientes: [],
        nf_servicos: [],
        nf_servicos_empresas: [],
        nf_clientes: [],
    })
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [showModalEmissao, setShowModalEmissao] = useState<boolean>(false)
    const [showModalDownload, setShowModalDownload] = useState<boolean>(false)
    const [showModalXMLError, setShowModalXMLError] = useState<boolean>(false)
    const [XMLModalError, setXMLModalError] = useState<boolean>(false)
    const [nota, setNotaDados] = useState<any>(0)

    useEffect(() => {
        $('.dinheiro').mask('###.###.###.###.###.###.##0,00', {reverse: true, placeholder: "0,00"});
        $('.porcentagem').mask('000.00', {reverse: true, placeholder: "0.00"});
        getFiltros()
        setNotaDados(notaDados)
        if (modelo !== null) {
            loadModelo(modelo)
        }
        // eslint-disable-next-line
    }, [])

    function loadModelo(modelo: any) {
        let form:any = document.getElementById("formNota")
        console.log("build nFVIEW load_modelo")
        setIdCliente({
            "value": modelo.id_cliente.id_cliente,
            "label": `${modelo.id_cliente.id_cliente} ${modelo.id_cliente.nome} - CNPJ ${modelo.id_cliente.cnpj}`
        })
        defineValueInInput("descricao", `NFe ###0 - ${modelo.id_cliente.nome}`)
        setCodServ({
            "value": modelo.cod_serv.cod_serv,
            "label": `${modelo.cod_serv.cod_serv} - ${modelo.cod_serv.descricao} - ${modelo.cod_serv.iss_incidencia_municipio_prestador === 1 ? "Retido" : "Tributado"}`
        })
        setCodCidade({
            "value": modelo.cod_cidade.cod_cidade,
            "label": `${modelo.cod_cidade.nome}/${modelo.cod_cidade.uf} - Cep ${modelo.cod_cidade.cep}`
        })

        form.valor.value = formatarDinheiro(modelo.valor)
        form.IRRF.value = formatarPorcentagem(modelo.IRRF)
        form.IRRF_V.value = formatarDinheiro(modelo.IRRF_V)

        form.INSS.value = formatarPorcentagem(modelo.INSS)
        form.INSS_V.value = formatarDinheiro(modelo.INSS_V)

        form.COFINS.value = formatarPorcentagem(modelo.COFINS)
        form.COFINS_V.value = formatarDinheiro(modelo.COFINS_V)

        form.CSLL.value = formatarPorcentagem(modelo.CSLL)
        form.CSLL_V.value = formatarDinheiro(modelo.CSLL_V)

        form.PIS.value = formatarPorcentagem(modelo.PIS)
        form.PIS_V.value = formatarDinheiro(modelo.PIS_V)

        form.ISS.value = formatarPorcentagem(modelo.ISS)
        form.ISS_V.value = formatarDinheiro(modelo.ISS_V)

        form.situacao.value = modelo.cod_serv.iss_incidencia_municipio_prestador === 1 ? "Retido" : "Tributado"

        handleCalcTributos()
        defineValueInInput("BASE_V", formatarDinheiro(modelo.valor))

        let dia_data_servico = String(modelo.dia_data_servico).length === 1 ? `0${modelo.dia_data_servico}` : modelo.dia_data_servico
        let dia_data_competencia = String(modelo.dia_data_competencia).length === 1 ? `0${modelo.dia_data_competencia}` : modelo.dia_data_competencia
        let ano_mes = dayjs().format("YYYY-MM")
        form.data_servico.value = ano_mes + `-${dia_data_servico}`
        form.data_competencia.value = ano_mes + `-${dia_data_competencia}`

        let descriminacao:string = modelo.descriminacao

        if (modelo.referencia === 1) {
          let ref:string = dayjs().startOf('month').subtract(1, 'day').locale('pt-br').format("MMMM/YYYY").toUpperCase()
          descriminacao = descriminacao.replace("#ref#", ref)
        }

        if (modelo.referencia === 2) {
          let ref:string = dayjs().locale('pt-br').format("MMMM/YYYY").toUpperCase()
          descriminacao = descriminacao.replace("#ref#", ref)
        }

        form.descriminacao.value = descriminacao

        toast.info(`Modelo carregado com sucesso: ${modelo.nome}!`, {
            containerId: 1,
            position: 'top-center',
            theme: 'dark'
        })
    }

    function loadNota(nota:any) {

        let form:any = document.getElementById("formNota")
        // console.log(form)

        setIdCliente({
            "value": nota.id_cliente.id_cliente,
            "label": `${nota.id_cliente.id_cliente} ${nota.id_cliente.nome} - CNPJ ${nota.id_cliente.cnpj}`
        })
        setCodServ({
            "value": nota.cod_serv.cod_serv,
            "label": `${nota.cod_serv.cod_serv} - ${nota.cod_serv.descricao} - ${nota.cod_serv.iss_incidencia_municipio_prestador === 1 ? "Retido" : "Tributado"}`
        })
        setCodCidade({
            "value": nota.cod_cidade.cod_cidade,
            "label": `(${nota.cod_cidade.cod_cidade}) ${nota.cod_cidade.nome}/${nota.cod_cidade.uf} - Cep ${nota.cod_cidade.cep}`
        })

        form.descricao.value = nota.descricao
        
        form.valor.value = formatarDinheiro(nota.valor_servicos)
        form.IRRF.value = formatarPorcentagem(nota.IRRF)
        form.IRRF_V.value = formatarDinheiro(nota.IRRF_V)

        form.INSS.value = formatarPorcentagem(nota.INSS)
        form.INSS_V.value = formatarDinheiro(nota.INSS_V)

        form.COFINS.value = formatarPorcentagem(nota.COFINS)
        form.COFINS_V.value = formatarDinheiro(nota.COFINS_V)

        form.CSLL.value = formatarPorcentagem(nota.CSLL)
        form.CSLL_V.value = formatarDinheiro(nota.CSLL_V)

        form.PIS.value = formatarPorcentagem(nota.PIS)
        form.PIS_V.value = formatarDinheiro(nota.PIS_V)

        form.ISS.value = formatarPorcentagem(nota.ISS)
        form.ISS_V.value = formatarDinheiro(nota.ISS_V)

        form.data_servico.value = nota.data_servico
        form.data_competencia.value = nota.data_competencia
        form.descriminacao.value = nota.descriminacao
        form.situacao.value = nota.cod_serv.iss_incidencia_municipio_prestador === 1 ? "Retido" : "Tributado"

        handleCalcTributos()
        defineValueInInput("BASE_V", formatarDinheiro(nota.valor_servicos))

        document.getElementById("status")!.textContent = nota.status + " | " + nota.ambiente.ambiente
        document.getElementById("data_emissao")!.textContent = nota.data_emissao === null ? '' : dayjs(nota.data_emissao).format("DD/MM/YYYY HH:mm:ss")
        document.getElementById("numero_nf")!.textContent = nota.numero_nf
        document.getElementById("numero_lote")!.textContent = nota.numero_lote
        document.getElementById("numero_protocolo")!.textContent = nota.numero_protocolo
        document.getElementById("codigo_verificacao")!.textContent = nota.codigo_verificacao
        document.getElementById("valor_base")!.textContent = nota.valor_base === null ? '' : `R$ ${formatarDinheiro(nota.valor_base)}`
        document.getElementById("valor_liquido")!.textContent = nota.valor_liquido === null ? '' : `R$ ${formatarDinheiro(nota.valor_liquido)}`

        if (nota.status === "Emitido") {
            form.BASE_V.value = formatarDinheiro(nota.valor_base)
            form.LIQUIDO_V.value = formatarDinheiro(nota.valor_liquido)
        }

        if (nota.status === "Emitido" || nota.status === "Emitido (Cancelado)") {
            setIsFormDisabled(true)
        }

    }

    async function getFiltros():Promise<void> {
        const apiFiltros = await reqGetNew(ApiEndpoints.nfFiltros)
        if (apiFiltros.status === 200) {
            setFiltros(apiFiltros.data)

            let clientesFiltro:any[] = apiFiltros.data.nf_clientes.filter((reg:any) => reg.id_empresa === empresa.id_empresa)
            let clientesAux = clientesFiltro.map((item:any, index:any) => (
                {
                    "value": `${item.id_cliente}`,
                    "label": `${item.id_cliente} ${item.nome} - CNPJ ${item.cnpj}`
                }
            ))
            setIdClienteFiltro(clientesAux)
            let cidadesAux = apiFiltros.data.nf_cidades.map((item:any, index:any) => (
                {
                    "value": `${item.cod_cidade}`,
                    "label": `(${item.cod_cidade}) ${item.nome}/${item.uf} - Cep ${item.cep}`
                }
            ))
            setCodCidadeFiltro(cidadesAux)
            let nf_servicos_empresa = getServicosEmpresa(apiFiltros.data)
            console.log("servicos_empresa")
            console.log(nf_servicos_empresa)
            let codServsAux = nf_servicos_empresa.map((item:any, index:any) => (
                {
                    "value": `${item.cod_serv}`,
                    "label": `${item.cod_serv} - ${item.descricao} - ${item.iss_incidencia_municipio_prestador === 1 ? "Retido" : "Tributado"}`
                }
            ))
            setCodServFiltro(codServsAux)
        }

        if (notaDados !== 0) {
            loadNota(notaDados)
        }

        if (empresa.optante_simples_nacional === 1) {
            defineValueInInput("ISS", empresa.ISS)
            document.getElementById("ISS_descricao")!.textContent = "ISS (Optante Simples Nacional)"
        }
    }

    function getServicosEmpresa(dados:iFiltros):any[] {
        let servicos:any[] = []
        // let 
        let cod_serv_ids:string[] = []

        for (let reg of dados.nf_servicos_empresas) {
            if (reg.id_empresa === empresa.id_empresa) {
                cod_serv_ids.push(reg.cod_serv)
            }
        }

        if (cod_serv_ids.length === 0) {
            return dados.nf_servicos
        }

        for (let cod_serv of cod_serv_ids) {
            servicos.push(dados.nf_servicos.filter((reg:any) => reg.cod_serv === cod_serv)[0])
        }
        
        return servicos
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>):void {

        event.preventDefault()
        event.stopPropagation()
        removeAllErrorMsg()
        const form:any = event.target
        let formValidado = true

        let dados:NfNotasCadastrarDto = {
            id_nota: nota === 0 ? null : nota.id_nota,
            id_contrato: nota === 0 ? null : nota.id_contrato,
            status: nota === 0 ? null : nota.status,
            descricao: form.descricao.value,
            id_empresa: empresa.id_empresa,
            id_cliente: Number(idCliente.value),
            data_servico: form.data_servico.value,
            data_competencia: form.data_competencia.value,
            valor_servicos: getInputDinheiro("valor"),
            cod_serv: codServ.value,
            cod_cidade: Number(codCidade.value),
            exigibilidade_iss: nota === 0 ? null : nota.exigibilidade_iss,
            PIS: getInputPorcentagem("PIS"),
            PIS_V: getInputDinheiro("PIS_V"),
            COFINS: getInputPorcentagem("COFINS"),
            COFINS_V: getInputDinheiro("COFINS_V"),
            INSS: getInputPorcentagem("INSS"),
            INSS_V: getInputDinheiro("INSS_V"),
            IRRF: getInputPorcentagem("IRRF"),
            IRRF_V: getInputDinheiro("IRRF_V"),
            CSLL: getInputPorcentagem("CSLL"),
            CSLL_V: getInputDinheiro("CSLL_V"),
            ISS: getInputPorcentagem("ISS"),
            ISS_V: getInputDinheiro("ISS_V"),
            descriminacao: form.descriminacao.value,
            emitido: nota === 0 ? 0 : nota.emitido,
            ambiente: nota === 0 ? null : nota.ambiente.ambiente
        }
        if (modelo !== null) {
            dados.id_contrato = modelo.id_modelo
        }

        console.log(dados)

        if (dados.id_cliente === 0) {
            form.id_cliente_aux.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.valor_servicos === "") {
            form.valor.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.data_servico === "") {
            form.data_servico.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.data_competencia === "") {
            form.data_competencia.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.cod_serv === "0") {
            form.cod_serv_aux.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.descriminacao === "") {
            form.descriminacao.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.cod_cidade === 0) {
            form.cod_cidade_aux.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (!formValidado) {
            return
        }

        if (nota === 0) {
            cadastrar(dados)
            return
        }

        if (nota !== 0) {
            editar(dados)
            return
        }

    }

    async function cadastrar(dados:NfNotasCadastrarDto) {
        setLoading(true)
        const api = await reqPut(ApiEndpoints.nfsCadastrar, dados)
        if (api.status === 201) {
            console.log(api)
            toast.success('Nota Fiscal cadastrada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            handleUpdateNfs()
            loadNota(api.data)
            setNotaDados(api.data)
            window.history.replaceState("OB PORTUS .NET", "OB PORTUS EMPRESA NF", `/nfs?id_empresa=${empresa.id_empresa}&id_nota=${api.data.id_nota}`)
        }
        setLoading(false)
    }

    async function editar(dados:NfNotasCadastrarDto) {
        setLoading(true)
        const api = await reqPatch(ApiEndpoints.nfsEditar, dados)
        if (api.status === 200) {
            console.log(api)
            toast.success('Nota Fiscal editada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            handleUpdateNfs()
            loadNota(api.data)
            setNotaDados(api.data)
        }
        setLoading(false)
    }

    function handleChangeInValor(event:any): void {
        handleCalcTributos()
        defineValueInInput("BASE_V", event.target.value)
    }
    function handleChangeInTributo(event:any): void {
        handleCalcTributo(event.target.id)
        handleCalcValorLiquido()
    }
    function handleCalcTributos() {
        let input_valor:any = document.getElementById("valor")
        if (input_valor.value === "") {
            return
        }
        handleCalcTributo("IRRF")
        handleCalcTributo("INSS")
        handleCalcTributo("COFINS")
        handleCalcTributo("CSLL")
        handleCalcTributo("PIS")
        handleCalcTributo("ISS")
        handleCalcValorLiquido()
    }
    function handleCalcTributo(id_input_tributo:string) {
        let input_tributo:any = document.getElementById(id_input_tributo)
        let input_valor:any = document.getElementById("valor")
        if (input_valor.value === "") {
            return
        }
        let valor_nota_string:string = String(input_valor.value)
        let valor_nota:number = Number(valor_nota_string.replaceAll(".", "").replaceAll(",", "."))
        let valor_tributo:number = valor_nota * (Number(input_tributo.value) / 100)
        let input_tributo_valor:any = document.getElementById(`${id_input_tributo}_V`)
        input_tributo_valor.value = formatarDinheiro(String(valor_tributo.toFixed(2)))
    }
    function handleCalcValorLiquido() {
        let input_valor:any = document.getElementById("valor")
        if (input_valor.value === "") {
            return
        }
        let valor_nota_string:string = String(input_valor.value)
        let valor_nota:number = Number(valor_nota_string.replaceAll(".", "").replaceAll(",", "."))
    
        let inputIRRF:any = document.getElementById("IRRF")
        let IRRF:number = valor_nota * (Number(inputIRRF.value) / 100)
    
        let inputINSS:any = document.getElementById("INSS")
        let INSS:number = valor_nota * (Number(inputINSS.value) / 100)
    
        let inputCOFINS:any = document.getElementById("COFINS")
        let COFINS:number = valor_nota * (Number(inputCOFINS.value) / 100)
    
        let inputCSLL:any = document.getElementById("CSLL")
        let CSLL:number = valor_nota * (Number(inputCSLL.value) / 100)
    
        let inputPIS:any = document.getElementById("PIS")
        let PIS:number = valor_nota * (Number(inputPIS.value) / 100)
    
        let inputISS:any = document.getElementById("ISS")
        let ISS:number = valor_nota * (Number(inputISS.value) / 100)
    
        let ISS_status:any = document.getElementById("situacao")
    
        let valor_impostos:number = (IRRF + INSS + COFINS + CSLL + PIS + ISS)
        valor_impostos = valor_impostos >= valor_nota ? valor_nota : valor_impostos
        let valor_liquido:number = valor_nota - (valor_impostos)
    
        if (ISS_status.value === "Tributado") {
            valor_liquido = valor_nota
        }
    
        let input_valor_liquido:any = document.getElementById("LIQUIDO_V")
        input_valor_liquido.value = formatarDinheiro(String(valor_liquido.toFixed(2)))
    }

    async function emissao() {
        if (!window.confirm("Tem certeza que deseja emitir essa NF?")) {
            return
        }

        setLoading(true)
        const api = await reqPostNew(ApiEndpoints.nfsEmissao, {id_nota: nota.id_nota})
        if (api.status === 200) {
            console.log(api)
            if (api.data.status === "ERROR") {
                toast.error(api.data.msg, {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                setXMLModalError(api.data.error)
                setShowModalXMLError(true)
            }
            if (api.data.status === "OK") {
                toast.success('Nota Fiscal EMITIDA!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }
            handleUpdateNfs()
            loadNota(api.data.nota)
            setNotaDados(api.data.nota)
        }
        setLoading(false)
    }
    async function cancelarNF() {
        if (!window.confirm("Tem certeza que deseja cancelar essa NF?")) {
            return
        }

        setLoading(true)
        const api = await reqPostNew(ApiEndpoints.nfsCancelar, {id_nota: nota.id_nota})
        if (api.status === 200) {
            console.log(api)
            if (api.data.status === "ERROR") {
                toast.error(api.data.msg, {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
                setXMLModalError(api.data.error)
                setShowModalXMLError(true)
            }
            if (api.data.status === "OK") {
                toast.success('Solicitação de cancelamento feita com sucesso!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }
            handleUpdateNfs()
            loadNota(api.data.nota)
            setNotaDados(api.data.nota)
        }
        setLoading(false)
    }

    async function deletarNF() {
        if (!window.confirm("Tem certeza que deseja deletar essa NF?")) {
            return
        }

        setLoading(true)
        const api = await reqPostNew(ApiEndpoints.nfsDeletar, {id_nota: nota.id_nota})
        if (api.status === 200) {
            console.log(api)
            toast.success('NF dados deletado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            handleUpdateNfs()
            await funcSleep(1000)
            handleClose()
        }
        setLoading(false)
    }

    return (
        <div style={{zIndex: 20}}>
            <div className="div-row-space-between">
                {/* <h1 id="titleMain">{empresa.nome}</h1> */}
                
            </div>

            <ModalMessageXMLError
                show={showModalXMLError}
                onHide={() => setShowModalXMLError(false)}
                xml={XMLModalError}
            />

            <NfModalEmitir dados={nota} handleClose={() => {setShowModalEmissao(false)}} show={showModalEmissao} />
            <NfModalDownload nfDados={nota} handleClose={() => {setShowModalDownload(false)}} show={showModalDownload} />

            <LoadingModal show={loading} handleClose={(event:any) => { setLoading(!loading) }} />

            <Form id="formNota" onSubmit={handleSubmit} noValidate>

            <Row className="mb-15-forms">
                <Col className="col-6">
                    <div className={styles.divEmpresaItemNota} onClick={(e) => { handleClose() }}>
                        <img src={empresa.logo} alt="banner_empresa" width={80}></img>
                        <div>
                            <div className='div-row'>
                                <p style={{marginRight: "-5px"}}>{empresa.nome} -</p>
                                <p style={{marginRight: "10px"}}>{nota === 0 ? "Nova Nota Fiscal" : "Editar Nota Fiscal"}</p>
                            </div>
                            {/* <p className={styles.pDescricaoNota}>NF #####</p> */}
                        </div>                        
                    </div>
                    <br></br>
                    <Form.Group style={{width: "100%"}}>
                        <Form.Label>Cliente:</Form.Label>
                        <Select name='id_cliente' id='id_cliente' options={idClienteFiltro} value={idCliente} isDisabled={isFormDisabled} placeholder="Selecione o Cliente"     onChange={
                                (choice:any) => {
                                    setIdCliente(choice)
                                    let cliente = filtros.nf_clientes.filter(reg => reg.id_cliente === Number(choice.value))[0]
                                    setCodCidade(
                                        {
                                            "value": `${cliente.cod_cidade.cod_cidade}`,
                                            "label": `${cliente.cod_cidade.nome}/${cliente.cod_cidade.uf} - Cep ${cliente.cod_cidade.cep}`
                                        }
                                    )
                                    if (empresa.optante_simples_nacional === 2) {
                                        let tipo_cliente = filtros.nf_tipos_clientes.filter(reg => reg.id_tipo_cliente === cliente.id_tipo_cliente)[0]
                                        defineValueInInput("IRRF", formatarPorcentagem(tipo_cliente.IRRF))
                                        handleCalcTributo("IRRF")
                                        defineValueInInput("INSS", formatarPorcentagem(tipo_cliente.INSS))
                                        handleCalcTributo("INSS")
                                        defineValueInInput("COFINS", formatarPorcentagem(tipo_cliente.COFINS))
                                        handleCalcTributo("COFINS")
                                        defineValueInInput("CSLL", formatarPorcentagem(tipo_cliente.CSLL))
                                        handleCalcTributo("CSLL")
                                        defineValueInInput("PIS", formatarPorcentagem(tipo_cliente.PIS))
                                        handleCalcTributo("PIS")
                                        defineValueInInput("ISS", formatarPorcentagem(cliente.cod_cidade.iss))
                                        handleCalcTributo("ISS")
                                        handleCalcValorLiquido()
                                        toast.info(`Tipo de Cliente selecionado: ${tipo_cliente.nome}!`, {
                                            containerId: 1,
                                            position: 'top-center',
                                            theme: 'dark',
                                            autoClose: 2000,
                                        })
                                    }
                                    if (nota !== 0) {
                                        if (nota.status !== "Emitido" && nota.status !== "Emitido (Cancelado)") {
                                            defineValueInInput("descricao", `NFe ###0 - ${cliente.nome}`)
                                        }
                                    } else {
                                        defineValueInInput("descricao", `NFe ###0 - ${cliente.nome}`)
                                    }
                                }
                        } styles={customSelectStylesNfs} />
                        <Form.Control name='id_cliente_aux' type="text"id='id_cliente_aux' placeholder="" style={{display:"none"}}/>
                        <Form.Control.Feedback type="invalid">
                            Informe o Cliente!
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className='col-6'>
                    <div style={{backgroundColor: "#e6e5e5", borderRadius: "5px", padding: "10px", height: "84px"}}>
                        <Row>
                            <Col className='col-6'>
                                <p>Status: <span id='status' style={{fontWeight: "bold"}}></span></p>
                            </Col>
                            <Col className='col-6'>
                                <p>Data emissão: <span id='data_emissao'></span></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='col-6'>
                                <p>Numero Nota:	<span id='numero_nf'></span></p>
                            </Col>
                            <Col className='col-6'>
                                <p>Numero Lote:	<span id='numero_lote'></span></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='col-6'>
                                <p>Protocolo: <span id='numero_protocolo'></span></p>
                            </Col>
                            <Col className='col-6'>
                                <p>Código verificação: <span id='codigo_verificacao'></span></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='col-6'>
                                <p>Valor Base: <span id='valor_base'></span></p>
                            </Col>
                            <Col className='col-6'>
                                <p>Valor líquido: <span id='valor_liquido'></span></p>
                            </Col>
                        </Row>
                    </div>
                    <br></br>
                    <Form.Group>
                        <Form.Label>Descrição:</Form.Label>
                        <Form.Control name='descricao' type="text" id='descricao' disabled={isFormDisabled} readOnly/>
                    </Form.Group>
                </Col>
            </Row>
            
            <br></br>

                <Row className="mb-15-forms">
                    <Col className="col-2">
                        <Form.Group>
                            <Form.Label>Valor:</Form.Label>
                            <Form.Control name='valor' type="text" id='valor' onKeyUp={handleChangeInValor} className='dinheiro' autoComplete='off' placeholder="0,00" disabled={isFormDisabled} style={{fontWeight: "bold"}}/>
                            <Form.Control.Feedback type="invalid">
                                Informe o valor da NF!
                            </Form.Control.Feedback>
                            <Form.Control name='dinheiro_aux' type="text" id='dinheiro_aux' className='dinheiro' placeholder="0,00" style={{display: "none"}} />
                            <Form.Control name='porcentagem_aux' type="text" id='porcentagem_aux' className='porcentagem' placeholder="0.00" style={{display: "none"}} />
                        </Form.Group>
                    </Col>
                    <Col className="col-2">
                        <Form.Group>
                            <Form.Label>Data Serviço:</Form.Label>
                            <Form.Control name='data_servico' type="date" id='data_servico' disabled={isFormDisabled} />
                            <Form.Control.Feedback type="invalid">
                                Informe a data do serviço!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-2">
                        <Form.Group>
                            <Form.Label>Data Competência:</Form.Label>
                            <Form.Control name='data_competencia' type="date" id='data_competencia' disabled={isFormDisabled} />
                            <Form.Control.Feedback type="invalid">
                                Informe a data da competência!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className='col-6'>
                        <Form.Group>
                            <Form.Label>Serviço:</Form.Label>
                            <Select name='cod_serv' id='cod_serv' options={codServFiltro} value={codServ} isDisabled={isFormDisabled} placeholder="Selecione o serviço" onChange={
                                    (choice:any) => {
                                        setCodServ(choice)
                                        let servico = filtros.nf_servicos.filter(reg => reg.cod_serv === choice.value)[0]
                                        defineValueInInput("situacao", servico.iss_incidencia_municipio_prestador === 1 ? "Retido" : "Tributado")
                                        handleCalcTributos()
                                    }
                                } styles={customSelectStylesNfs} />
                            <Form.Control name='cod_serv_aux' type="text"id='cod_serv_aux' placeholder="" style={{display:"none"}}/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Serviço!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <br></br>
                <Row className="mb-15-forms">
                    <Col className='col-2'>
                        <p className={styles.titleValorTributo}>IRRF</p>
                        <div className={styles.divInputsTributo}>
                            <p>Aliquota (%)</p>
                            <input name='IRRF' type="text" id='IRRF' className='porcentagem' autoComplete='off' onKeyUp={handleChangeInTributo} disabled={isFormDisabled} placeholder="0.00"/>
                        </div>
                        <div className={styles.divInputsTributo}>
                            <p>Valor (R$)</p>
                            <input name='IRRF_V' type="text" id='IRRF_V' placeholder="0,00" readOnly disabled/>
                        </div>
                    </Col>
                    <Col className='col-2'>
                        <p className={styles.titleValorTributo}>INSS</p>
                        <div className={styles.divInputsTributo}>
                            <p>Aliquota (%)</p>
                            <input name='INSS' type="text" id='INSS' className='porcentagem' autoComplete='off' onKeyUp={handleChangeInTributo} disabled={isFormDisabled} placeholder="0.00"/>
                        </div>
                        <div className={styles.divInputsTributo}>
                            <p>Valor (R$)</p>
                            <input name='INSS_V' type="text" id='INSS_V' placeholder="0,00" readOnly disabled/>
                        </div>
                    </Col>
                    <Col className='col-2'>
                        <p className={styles.titleValorTributo}>COFINS</p>
                        <div className={styles.divInputsTributo}>
                            <p>Aliquota (%)</p>
                            <input name='COFINS' type="text" id='COFINS' className='porcentagem' autoComplete='off' onKeyUp={handleChangeInTributo} disabled={isFormDisabled} placeholder="0.00"/>
                        </div>
                        <div className={styles.divInputsTributo}>
                            <p>Valor (R$)</p>
                            <input name='COFINS_V' type="text" id='COFINS_V' placeholder="0,00" readOnly disabled/>
                        </div>
                    </Col>
                    <Col className='col-2'>
                        <p className={styles.titleValorTributo}>CSLL</p>
                        <div className={styles.divInputsTributo}>
                            <p>Aliquota (%)</p>
                            <input name='CSLL' type="text" id='CSLL' className='porcentagem' autoComplete='off' onKeyUp={handleChangeInTributo} disabled={isFormDisabled} placeholder="0.00"/>
                        </div>
                        <div className={styles.divInputsTributo}>
                            <p>Valor (R$)</p>
                            <input name='CSLL_V' type="text" id='CSLL_V' placeholder="0,00" readOnly disabled/>
                        </div>
                    </Col>
                    <Col className='col-2'>
                        <p className={styles.titleValorTributo}>PIS</p>
                        <div className={styles.divInputsTributo}>
                            <p>Aliquota (%)</p>
                            <input name='PIS' type="text" id='PIS' className='porcentagem' autoComplete='off'onKeyUp={handleChangeInTributo} disabled={isFormDisabled} placeholder="0.00"/>
                        </div>
                        <div className={styles.divInputsTributo}>
                            <p>Valor (R$)</p>
                            <input name='PIS_V' type="text" id='PIS_V' placeholder="0,00" readOnly disabled/>
                        </div>
                    </Col>
                    <Col className='col-2'>
                        <p className={styles.titleValorTributoResumo}>RESUMO</p>
                        <div className={styles.divInputsTributo}>
                            <p>Base (R$)</p>
                            <input name='BASE_V' type="text" id='BASE_V' placeholder="0,00" className='dinheiro' style={{fontWeight: "bold"}} readOnly disabled/>
                        </div>
                        <div className={styles.divInputsTributo}>
                            <p>Líquido (R$)</p>
                            <input name='LIQUIDO_V' type="text" id='LIQUIDO_V' placeholder="0,00" className='dinheiro' style={{fontWeight: "bold"}} readOnly disabled/>
                        </div>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className='col-6'>
                        <Form.Group>
                            <Form.Label>Cidade:</Form.Label>
                            <Select name='cod_cidade' id='cod_cidade' options={codCidadeFiltro} value={codCidade} isDisabled={isFormDisabled} placeholder="Selecione a cidade" onChange={
                                    choice => {
                                        setCodCidade(choice)
                                        let cidade:any = filtros.nf_cidades.filter(reg => reg.cod_cidade === Number(choice.value))[0]
                                        if (empresa.optante_simples_nacional === 2) {
                                            defineValueInInput("ISS", formatarPorcentagem(cidade.iss))
                                            handleCalcTributo("ISS")
                                            handleCalcValorLiquido()
                                        }
                                    }
                                } styles={customSelectStylesNfs} />
                            <Form.Control name='cod_cidade_aux' type="text"id='cod_cidade_aux' placeholder="" style={{display:"none"}}/>
                            <Form.Control.Feedback type="invalid">
                                Informe a Cidade!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className='col-2'>
                        <p className={styles.titleValorTributo} id='ISS_descricao'>ISS</p>
                        <div className={styles.divInputsTributo}>
                            <p>Aliquota (%)</p>
                            <input name='ISS' type="text" id='ISS' className='porcentagem' autoComplete='off' onKeyUp={handleChangeInTributo} disabled={empresa.optante_simples_nacional === 2 ? isFormDisabled : true} placeholder="0.00"/>
                        </div>
                        <div className={styles.divInputsTributo}>
                            <p>Valor (R$)</p>
                            <input name='ISS_V' type="text" id='ISS_V' placeholder="0,00" readOnly disabled/>
                        </div>
                    </Col>
                    <Col className='col-4'>
                        <Form.Group>
                            <Form.Control name='situacao' type="text" id='situacao' readOnly disabled/>
                            <Form.Control.Feedback type="invalid">
                                Informe a situação da NF!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className='col-12'>
                        <Form.Group className="mt-0 mb-3">
                            <Form.Label>Informações da Nota</Form.Label>
                            <Form.Control as="textarea" rows={8} name='descriminacao' id='descriminacao' autoComplete='off' disabled={isFormDisabled} />
                            <Form.Control.Feedback type="invalid">
                                Preencha as Informações	da Nota!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <div className="div-row" style={divControls}>
                    { nota === 0 && <Button type='submit' onClick={() => {}} variant='success' style={divButtonsControls}>Cadastrar</Button> }
                    { (nota !== 0 && (nota.status !== "Emitido" && nota.status !== "Emitido (Cancelado)") ) && <Button type='submit' onClick={() => {}} variant='success' style={divButtonsControls}>Editar</Button> }
                    { (nota !== 0 && (nota.status !== "Emitido" && nota.status !== "Emitido (Cancelado)") ) && <Button onClick={() => { emissao() }} variant='warning' style={divButtonsControls}>Emissão</Button> }
                    { (nota !== 0 && (nota.status !== "Emitido" && nota.status !== "Emitido (Cancelado)") ) && <Button onClick={() => { deletarNF() }} variant='danger' style={divButtonsControls}>Deletar</Button> }
                    { (nota !== 0 && (nota.status === "Emitido" || nota.status === "Emitido (Cancelado)") ) && <Button onClick={() => { setShowModalDownload(true) }} variant='primary' style={divButtonsControls}>Download</Button> }
                    { (nota !== 0 && (nota.status === "Emitido") ) && <Button onClick={() => { cancelarNF() }} variant='danger' style={divButtonsControls}>Cancelar</Button> }
                </div>

            </Form>
        </div>
    )

}
