import { Button, Form } from 'react-bootstrap'
import Select from 'react-select'
import consultarStyle from './styles/consultar.module.css'
import { useEffect, useState } from 'react'
import { getValueFromInput, defineValueInInput } from '../../components/funcForInput'
import { iApuracaoConsultarDadosDto } from './dto/consultar-filtro.dto'
import { ApiEndpoints, reqGetNew, reqPostNew } from '../../config/apiClient'
import { iApuracaoConsultarDadosFiltroDto } from './dto/dados-consultar-filtro.dto'
import dayjs from 'dayjs'
import { ColumnDef } from '@tanstack/react-table'
import { iColaboradorConsultaApuracao, iColaboradorDataTable } from './dto/dados-consultar.dto'
import LoadingModal from '../../components/loadingModal'
import DateTableApuracao from './DataTableApuracao'

// const customSelectStyles = {
//     control: (baseStyles:any, state:any) => ({
//         ...baseStyles,
//         width: '256px',
//         borderColor: "#dee2e6",
//         height: '40px',
//         marginRight: '5px'
//     }),
//     valueContainer: (provided:any, state:any) => ({
//         ...provided,
//         height: '30px',
//         overflowY: 'auto',
//     }),
//     input: (provided:any, state:any) => ({
//         ...provided,
//         margin: '0px',
//     }),
//     indicatorSeparator: (state:any) => ({
//         display: 'none',
//     }),
//     indicatorsContainer: (provided:any, state:any) => ({
//         ...provided,
//         height: '40px',
//     }),
// }

const customSelectStylesConsultarPontoDigital = {
    control: (baseStyles:any, state:any) => ({
        ...baseStyles,
        width: '200px',
        borderColor: "#dee2e6",
        minHeight: '39px',
        maxHeight: '120px',
        marginRight: '5px',
        fontSize: '14px'
    }),
    valueContainer: (provided:any, state:any) => ({
        ...provided,
        minHeight: '30px',
        maxHeight: '120px',
        overflowY: 'auto',
        fontSize: '14px'
    }),
    input: (provided:any, state:any) => ({
        ...provided,
        margin: '0px',
        fontSize: '14px'
    }),
    indicatorSeparator: (state:any) => ({
        display: 'none',
        fontSize: '14px'
    }),
    indicatorsContainer: (provided:any, state:any) => ({
        ...provided,
        minHeight: '39px',
        maxHeight: '120px',
        fontSize: '14px'
    }),
    menu: (provided:any, state:any) => ({
        ...provided,
        fontSize: '14px'
    }),
}

const customSelectStylesConsultarPontoDigitalLarge = {
    control: (baseStyles:any, state:any) => ({
        ...baseStyles,
        width: '400px',
        borderColor: "#dee2e6",
        minHeight: '39px',
        maxHeight: '120px',
        marginRight: '5px',
        fontSize: '14px'
    }),
    valueContainer: (provided:any, state:any) => ({
        ...provided,
        minHeight: '30px',
        maxHeight: '120px',
        overflowY: 'auto',
        fontSize: '14px'
    }),
    input: (provided:any, state:any) => ({
        ...provided,
        margin: '0px',
        fontSize: '14px'
    }),
    indicatorSeparator: (state:any) => ({
        display: 'none',
        fontSize: '14px',
    }),
    indicatorsContainer: (provided:any, state:any) => ({
        ...provided,
        minHeight: '39px',
        maxHeight: '120px',
        fontSize: '14px'
    }),
    menu: (provided:any, state:any) => ({
        ...provided,
        fontSize: '14px'
    }),
}

// const columnHelper = createColumnHelper<iColaboradorConsultaApuracao>()

// const defaultColumns = [
//     columnHelper.accessor(row => `${row.matricula} - ${row.nome_completo}`, {
//         header: 'Colaborador',
//     }),
//     columnHelper.accessor('empresa', {
//         header: 'Empresa',
//         size: 30,
//         minSize: 30,
//         maxSize: 30,
//     }),
//     columnHelper.accessor(row => `${row.id_centro_custo} - ${row.centro_custo}`, {
//         header: 'Centro de Custo',
//     }),
//     columnHelper.accessor(row => `${row.id_escala} ${row.escala}`, {
//         header: 'Escala',
//     }),
//     columnHelper.accessor(row => `DT - ${row.dt} | HT - ${row.ht} | HE - ${row.he} | HF - ${row.hf} | HN - ${row.hn} | HI - ${row.hi}`, {
//         header: 'Ponto Digital',
//     }),
//     columnHelper.accessor(row => `<span style="color: orange;">099</span>`, {
//         header: 'Teste',
//     })
// ]

const cols:ColumnDef<iColaboradorDataTable>[] = [
    {
        header: 'Colaborador',
        accessorKey: 'colaborador',
        cell: ({row, getValue}) => (<div>{getValue<string>()}</div>),
    },
    {
        header: 'Ponto Digital',
        accessorKey: 'dt',
        cell: ({row, getValue}) => (<div>{getValue<string>()}</div>),
        size: 175
    },
    {
        header: 'Escala',
        accessorKey: 'escala',
        cell: ({row, getValue}) => (<div>{getValue<string>()}</div>),
    },
    {
        header: 'Centro de Custo',
        accessorKey: 'centro_custo',
        cell: ({row, getValue}) => (<div>{getValue<string>()}</div>),
    },
    {
        header: 'Empresa',
        accessorKey: 'empresa',
        cell: ({row, getValue}) => (<div>{getValue<string>()}</div>),
        size: 30,
        minSize: 30,
        maxSize: 30
    },
]

const defaultOrder:any = []

function ConsultarApuracaoPage() {

    useEffect(() => {
        document.title = "Consultar Apuração | OB PORTUS"

        const getDadosFiltros = async () => {
            const data_atual = dayjs()
            const data_inicial = data_atual.startOf('month').format("YYYY-MM-DD")
            const data_final = data_atual.endOf('month').format("YYYY-MM-DD")
            defineValueInInput('data_inicial', data_inicial)
            defineValueInInput('data_final', data_final)

            const api = await reqGetNew(ApiEndpoints.apuracaoConsultarFiltroDados)
            if (api.status === 200) {
                const dados:iApuracaoConsultarDadosFiltroDto = api.data
                setDadosFiltro(dados)
                console.log(dados)
                let empresasAux = dados.empresas.map((item:any, index:any) => (
                    {
                        "value": `${item.codigo}`,
                        "label": `${item.codigo} - ${item.nome}`
                    }
                ))
                setEmpresasFiltro(empresasAux)

                let centrosAux = dados.centro_custo.map((item:any, index:any) => (
                    {
                        "value": `${item.codigo}`,
                        "label": `${item.codigo} - ${item.centro_custo}`
                    }
                ))
                setCentrosCustoFiltro(centrosAux)

                let colaboradoresAux = dados.colaboradores.map((item:any, index:any) => (
                    {
                        "value": `${item.id_colaborador}`,
                        "label": `${item.matricula} - ${item.nome_completo}`
                    }
                ))
                setColaboradoresFiltro(colaboradoresAux)
            }

            setLoading(false)
        }

        getDadosFiltros()
    }, [])

    const [dadosFiltro, setDadosFiltro] = useState<iApuracaoConsultarDadosFiltroDto>({centro_custo: [], colaboradores: [], empresas: [], ocorrencias_status: [], ocorrencias_tipo: [],})
    const [dadosApuracao, setDadosApuracao] = useState<iColaboradorDataTable[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const [empresasFiltro, setEmpresasFiltro] = useState<any>([])
    const [empresasFiltroSelected, setEmpresasFiltroSelected] = useState<any>([])

    const [centrosCustoFiltro, setCentrosCustoFiltro] = useState<any[]>([])
    const [centrosCustoFiltroSelected, setCentrosCustoFiltroSelected] = useState<any[]>([])

    const [colaboradoresFiltro, setColaboradoresFiltro] = useState<any>([])
    const [colaboradoresFiltroSelected, setColaboradoresFiltroSelected] = useState<any>([])

    async function getDados(centrosCusto:any[]):Promise<void> {
        const dados:iApuracaoConsultarDadosDto = {
            data_inicial: getValueFromInput("data_inicial"),
            data_final: getValueFromInput("data_final"),
            id_empresa: empresasFiltroSelected.map((item:any, index:any) => (Number(item.value))),
            id_centro_custo: centrosCusto.map((item:any, index:any) => (Number(item.value))),
            id_colaborador: colaboradoresFiltroSelected.map((item:any, index:any) => (Number(item.value))),
        }

        if (dados.id_centro_custo.length === 0 && dados.id_empresa.length === 0 && dados.id_colaborador.length === 0) {
            if (!window.confirm("Não foi selecionado um filtro, tem certeza que deseja continuar?")) {
                return
            }
        }

        getDadosApi(dados)
    }

    async function getDadosApi(dados:iApuracaoConsultarDadosDto) {
        setLoading(true)
        const api = await reqPostNew(ApiEndpoints.apuracaoConsultarDadosApuracao, dados)
        setLoading(false)

        if (api.status === 200) {
            console.log(api.data)
            const dados:iColaboradorConsultaApuracao[] = api.data
            let dadosAux:iColaboradorDataTable[] = dados.map((item:iColaboradorConsultaApuracao, index:any) => ({
                id_colaborador: item.id_colaborador,
                centro_custo: `${item.id_centro_custo} ${item.centro_custo}`,
                colaborador: `${item.matricula} ${item.nome_completo}`,
                empresa: item.empresa,
                escala: `${item.id_escala} ${item.escala}`,
                dt: `DT ${item.dt} HT ${item.ht} HE ${item.he} HF ${item.hf} HN ${item.hn} HI ${item.hi}`,
                qtd_ajustes: item.qtd_ajustes,
                qtd_ocorrencias: item.qtd_ocorrencias,
                destaque: item.hen > 0 ? "he" : item.hfn > 0 ? "hf" : "normal"
            }))
            setDadosApuracao(dadosAux)
        }
    }

    function tableMiddleClickhandler(event:any) {
        const dados = {
            data_inicial: getValueFromInput("data_inicial"),
            data_final: getValueFromInput("data_final"),
        }

        const idx = Number(event.target.parentElement.getAttribute('about'))
        const registro = dadosApuracao[idx]
        const link = `/ponto/apuracao?id=${registro.id_colaborador}&inicial=${dados.data_inicial}&final=${dados.data_final}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const dados = {
            data_inicial: getValueFromInput("data_inicial"),
            data_final: getValueFromInput("data_final"),
        }

        const idx = Number(event.target.parentElement.getAttribute('about'))
        const registro = dadosApuracao[idx]
        const link = `/ponto/apuracao?id=${registro.id_colaborador}&inicial=${dados.data_inicial}&final=${dados.data_final}`

        if (event.button === 0) {
            window.open(link, '_blank')
        }
    }

    function handleLoopDatasFiltro(acao:'proximo' | 'voltar') {
        let data_inicial = getValueFromInput('data_inicial')
        let data_final = getValueFromInput('data_final')
        if (data_inicial === '' || data_final === '') {
            return
        }

        if (acao === 'proximo') {
            let inicial = dayjs(data_inicial).add(1, 'month').startOf('month')
            let final = dayjs(data_final).add(1, 'month').endOf('month')
            defineValueInInput('data_inicial', inicial.format("YYYY-MM-DD"))
            defineValueInInput('data_final', final.format("YYYY-MM-DD"))
            mensagemInfo(`${inicial.format("DD/MM/YYYY")} até ${final.format("DD/MM/YYYY")}, necessário filtrar novamente!`)
        }

        if (acao === 'voltar') {
            let inicial = dayjs(data_inicial).subtract(1, 'month').startOf('month')
            let final = dayjs(data_final).subtract(1, 'month').endOf('month')
            defineValueInInput('data_inicial', inicial.format("YYYY-MM-DD"))
            defineValueInInput('data_final', final.format("YYYY-MM-DD"))
            mensagemInfo(`${inicial.format("DD/MM/YYYY")} até ${final.format("DD/MM/YYYY")}, necessário filtrar novamente!`)
        }
    }

    function handleLoopCentroCusto(acao:'proximo' | 'voltar') {

        if (dadosFiltro.centro_custo.length === 0) {
            return
        }

        if (centrosCustoFiltroSelected.length > 1) {
            mensagemInfo(`Muitos Centros de Custos Selecionados!`)
            return
        }

        if (acao === 'proximo') {

            if (centrosCustoFiltroSelected.length === 0) {
                let cc_selecionado_index = 0
                let cc_selecionado = dadosFiltro.centro_custo[cc_selecionado_index]
                let cc_selecionados = [                    {
                    "value": `${cc_selecionado.codigo}`,
                    "label": `${cc_selecionado.codigo} - ${cc_selecionado.centro_custo}`
                }]
                setCentrosCustoFiltroSelected(cc_selecionados)
                mensagemInfo(`Centro de Custo ${cc_selecionado_index + 1}/${dadosFiltro.centro_custo.length}`)
                getDados(cc_selecionados)
            }

            if (centrosCustoFiltroSelected.length > 0) {
                let cc_selecionado = dadosFiltro.centro_custo.filter((reg:any) => reg.codigo === Number(centrosCustoFiltroSelected[0].value))[0]
                let cc_selecionado_index = dadosFiltro.centro_custo.findIndex((reg:any) => reg.codigo === Number(cc_selecionado.codigo))
                if ((cc_selecionado_index + 1) < dadosFiltro.centro_custo.length) {
                    cc_selecionado_index = cc_selecionado_index + 1
                    cc_selecionado = dadosFiltro.centro_custo[cc_selecionado_index]
                    let cc_selecionados = [                    {
                        "value": `${cc_selecionado.codigo}`,
                        "label": `${cc_selecionado.codigo} - ${cc_selecionado.centro_custo}`
                    }]
                    setCentrosCustoFiltroSelected(cc_selecionados)
                    mensagemInfo(`Centro de Custo ${cc_selecionado_index + 1}/${dadosFiltro.centro_custo.length}`)
                    getDados(cc_selecionados)
                }
            }

        }

        if (acao === 'voltar') {

            if (centrosCustoFiltroSelected.length > 0) {
                let cc_selecionado = dadosFiltro.centro_custo.filter((reg:any) => reg.codigo === Number(centrosCustoFiltroSelected[0].value))[0]
                let cc_selecionado_index = dadosFiltro.centro_custo.findIndex((reg:any) => reg.codigo === Number(cc_selecionado.codigo))
                if ((cc_selecionado_index - 1) >= 0) {
                    cc_selecionado_index = cc_selecionado_index - 1
                    cc_selecionado = dadosFiltro.centro_custo[cc_selecionado_index]
                    let cc_selecionados = [                    {
                        "value": `${cc_selecionado.codigo}`,
                        "label": `${cc_selecionado.codigo} - ${cc_selecionado.centro_custo}`
                    }]
                    setCentrosCustoFiltroSelected(cc_selecionados)
                    mensagemInfo(`Centro de Custo ${cc_selecionado_index + 1}/${dadosFiltro.centro_custo.length}`)
                    getDados(cc_selecionados)
                }
            }

        }

    }

    function mensagemInfo(mensagem:string) {
        var alerta:any = document.createElement("div")
        alerta.innerHTML =
                `
                    <div class="alert alert-primary" role="alert" style="position:fixed; left: 0; right: 0; margin: 0 auto; width: 420px; text-align: center; z-index: 10; top: 10px;">
                        <div>
                            ${mensagem}
                        </div>
                    </div>
                `
                
        alerta.setAttribute("id", "alertaDiv")
        document.querySelector("body")?.appendChild(alerta)
                
        setTimeout(function() {
            document.getElementById("alertaDiv")?.remove()
        }, 5000)
    }

    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <h1 id='titleMain'>Consultar Apuração</h1>
            <br></br>
            <div className='div-row'>
                <div className={consultarStyle.divInputDatas}>
                    <Form.Control name='data_inicial' type="date" id='data_inicial' placeholder="Data Inicial" className={consultarStyle.inputDatas}/>
                    <div className={consultarStyle.divInputArrows}>
                        <Button size='sm' variant='secondary' className='div-row' style={{height: '30px', margin: '0px 5px 0px 0px', padding: '0px 10px'}} onClick={() => handleLoopDatasFiltro('voltar')}>
                            <span className="material-symbols-outlined" style={{fontSize: '14px'}}>
                                arrow_back
                            </span><span style={{fontSize: '12px'}}> Voltar</span>
                        </Button>
                    </div>
                </div>
                <div className={consultarStyle.divInputDatas}>
                    <Form.Control name='data_final' type="date" id='data_final' placeholder="Data Final" className={consultarStyle.inputDatas}/>
                    <div className={consultarStyle.divInputArrows}>
                        <Button size='sm' variant='secondary' className='div-row' style={{height: '30px', margin: '0px', padding: '0px 10px'}} onClick={() => handleLoopDatasFiltro('proximo')}>
                            <span style={{fontSize: '12px'}}>Proximo </span><span className="material-symbols-outlined" style={{fontSize: '14px'}}>
                            arrow_forward
                            </span>
                        </Button>
                    </div>
                </div>
                <Select name='ids_empresas' id='ids_empresas' options={empresasFiltro} defaultValue={empresasFiltroSelected} placeholder="Empresas" isMulti closeMenuOnSelect={false} onChange={
                    (choice) => {
                        // let selectValuesAux = selectValues
                        // selectValuesAux['id_empresa'] = choice as iChoice
                        // setSelectValues(selectValuesAux)
                        setEmpresasFiltroSelected(choice)
                        return
                    }
                } styles={customSelectStylesConsultarPontoDigital} />
                <div style={{position: 'relative'}}>
                    <Select name='ids_centro_custo' id='ids_centro_custo' options={centrosCustoFiltro} defaultValue={centrosCustoFiltroSelected} value={centrosCustoFiltroSelected} placeholder="Centros de Custo" isMulti closeMenuOnSelect={false} onChange={
                        (choice:any) => {
                            // let selectValuesAux = selectValues
                            // selectValuesAux['id_empresa'] = choice as iChoice
                            // setSelectValues(selectValuesAux)
                            console.log(choice)
                            setCentrosCustoFiltroSelected(choice)
                            return
                        }
                    } styles={customSelectStylesConsultarPontoDigitalLarge} />
                    <div className='div-row-space-between' style={{position: 'absolute', marginTop: '5px'}}>
                        <Button size='sm' variant='secondary' className='div-row' style={{height: '30px', margin: '0px 5px 0px 0px', padding: '0px 10px'}} onClick={() => handleLoopCentroCusto('voltar')}>
                        <span className="material-symbols-outlined" style={{fontSize: '14px'}}>
                            arrow_back
                        </span><span className={consultarStyle.iconArrowsText}> Voltar</span>
                        </Button>
                        <Button size='sm' variant='secondary' className='div-row' style={{height: '30px', margin: '0px', padding: '0px 10px'}} onClick={() => handleLoopCentroCusto('proximo')}>
                            <span className={consultarStyle.iconArrowsText}>Proximo </span><span className="material-symbols-outlined" style={{fontSize: '14px'}}>
                            arrow_forward
                            </span>
                        </Button>
                    </div>
                </div>
                <Select name='ids_colaboradores' id='ids_colaboradores' options={colaboradoresFiltro} defaultValue={colaboradoresFiltroSelected} placeholder="Colaboradores" isMulti closeMenuOnSelect={false} onChange={
                    (choice) => {
                        // let selectValuesAux = selectValues
                        // selectValuesAux['id_empresa'] = choice as iChoice
                        // setSelectValues(selectValuesAux)
                        setColaboradoresFiltroSelected(choice)
                        return
                    }
                } styles={customSelectStylesConsultarPontoDigitalLarge} />
                <Button onClick={() => { getDados(centrosCustoFiltroSelected) }} variant='primary' style={{height: '40px'}} className='div-row-space-between'>Filtrar <span className="material-symbols-outlined">filter_alt</span></Button>
            </div>
            <br></br>
            <br></br>
            <br></br>
            {!loading && <DateTableApuracao dados={dadosApuracao} tableSize={'auto'} defaultColumns={cols} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}
            <br></br>
            <br></br>
            <br></br>
            <a href={`${ApiEndpoints.apiOld}/dashboard/ponto/consultar`} rel="noreferrer" target="_blank">Ir para página de consulta antiga</a>
        </div>
    )
}

export default ConsultarApuracaoPage