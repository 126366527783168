import { FormEvent, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import removeAllErrorMsg from "../../../../components/funcRemoveAllErros";
import { ApiEndpoints, reqPatch } from "../../../../config/apiClient";
import { toast } from "react-toastify";
import { iResponseApuracao } from "../../dto/dados-apuracao.dto";
import dayjs from "dayjs";
import { getCookie } from "../../../../config/cookies";

interface iPropsModal {
    show: boolean,
    handleNew: any,
    handleClose: any,
    dados: iResponseApuracao
}

export default function ApuracaoModalReferencias ({show, handleNew, handleClose, dados}: iPropsModal) {

    const [loading, setLoading] = useState<boolean>(false)

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dadosCadastar = {
            id_acao: 1,
            data_dia: form.data.value,
            id_usuario: Number(getCookie('id_user')),
            id_colaborador: dados.dados.colaborador.id_colaborador,
            id_dia_apuracao: Number(form.id_dia_apuracao.value)
        }

        if (dadosCadastar.data_dia === "") {
            form.data.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dadosCadastar.id_dia_apuracao === 0) {
            form.id_dia_apuracao.nextElementSibling.style.display = "block"
            formValidado = false
        }

        console.log(dadosCadastar)

        if (!formValidado) {
            return
        }

        cadastrar(dadosCadastar)
    }

    async function cadastrar(dadosCadastrar:any) {
        setLoading(true)

        const api = await reqPatch(ApiEndpoints.apuracaoReferenciaSalvar, dadosCadastrar)

        setLoading(false)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Referência cadastrada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            handleNew(api.data)
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="modalApuracaoRight"
            dialogClassName="modalDialogApuracaoRight"
            contentClassName="modalContentApuracaoRight"
        >
            <Modal.Header closeButton>
                <Modal.Title>Definir Referência</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id='formReferencia' onSubmit={handleSubmit} noValidate>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Data:</Form.Label>
                                <Form.Control name='data' type="date" id='date' placeholder="dd/mm/aaaa"/>
                                <Form.Control.Feedback type="invalid">
                                    Informe a Data!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Referência:</Form.Label>
                                <Form.Select name="id_dia_apuracao" id="id_dia_apuracao" aria-label="Selecione as Horas Necessárias">
                                    <option value="0">Selecione as Horas Necessárias</option>
                                    {
                                        dados.calendario.map((reg:any, index:any) => (
                                            reg.id_dia_apuracao !== null &&
                                            <option key={index} value={reg.id_dia_apuracao}>{dayjs(reg.data).format("DD/MM/YYYY")} {reg.data_dia_nome} - Horas Necessárias: {reg.HN}</option>
                                        ))
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Informe as horas necessárias!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Button type="submit" disabled={loading}>{loading ? "Aguarde..." : "Salvar"}</Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )

} 