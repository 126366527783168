import * as React from 'react'
import '../../styles/home/header.css'
import logo from '../../img/ob-logo.png'
import iconUser from '../../img/home/user.svg'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { deleteCookie, getCookie } from '../../config/cookies'
import { ApiEndpoints, reqPost } from '../../config/apiClient'

function Header() {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event:any) => {
        setAnchorEl(null)

        const action = event.target.role

        if (action === 'deslogar') {
            const id_user = getCookie('id_user')
            if (id_user !== '') {
                reqPost(ApiEndpoints.logoff, [])
                deleteCookie('id_user')
                deleteCookie('id_colaborador')
                deleteCookie('nome')
                deleteCookie('email')
                deleteCookie('apelido')
                deleteCookie('token')
                window.location.assign("/login")
            }
        }
    };

    return (
        <div id='header'>
            <div className='header-sub-group'>
                <img id='header-logo' src={logo} alt='imagem_nao_encontrada'></img>
                <h1 id='header-title'>OB PORTUS</h1>
            </div>

            <div
                className='header-sub-group'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <p id='header-username'>{getCookie('nome')}</p>
                <img id='header-user-foto' src={iconUser} alt='imagem_nao_encontrada'></img>
            </div>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose} role='deslogar'>Deslogar</MenuItem>
            </Menu>
        </div>
    )

}

export default Header