import removeAllErrorMsg from "../../../components/funcRemoveAllErros"
import { FormEvent, useEffect, useState } from "react"
import { getUrlSearchParams } from "../../urlParams"
import { Button, Col, Form, Row } from "react-bootstrap"
import { ApiEndpoints, reqPatch, reqPostNew, reqPut } from "../../../config/apiClient"
import { toast } from "react-toastify"
import funcSleep from "../../../components/funcSleep"
import LoadingModal from "../../../components/loadingModal"
import iUsuarioDadosDto from "./dto/dados-usuarios.dto"
import iUsuarioOneDto from "./dto/one-usuarios.dto"
import iUsuarioCadastrarDto from "./dto/cadastrar-usuarios.dto"

function UsuariosFormPage() {

    const [loading, setLoading] = useState<boolean>(false)
    const [usuarioD, setUsuarioD] = useState<iUsuarioDadosDto>()

    useEffect(() => {
        document.title = "Cadastrar Usuário | OB PORTUS"

        const urlDados = getUrlSearchParams()
        const id = urlDados.get("id")

        const consultar = async (id:number) => {

            setLoading(true)

            const dados:iUsuarioOneDto = {
                id: Number(id)
            }

            const api = await reqPostNew(ApiEndpoints.painelControle.usuarios.consultar, dados)

            if (api.status === 200) {
                const reg:iUsuarioDadosDto = api.data
                setUsuarioD(reg)

                let form:any = document.getElementById("form")
                form.nome.value = reg.nome
                form.apelido.value = reg.apelido
                form.email.value = reg.email
                form.situacao.value = reg.situacao
            }

            setLoading(false)

        }

        if (id !== null) {
            document.title = "Editar Usuário | OB PORTUS"
            consultar(Number(id))
        }
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        // throw new Error("Function not implemented.")
        const form:any = event.target
        let formValidado = true

        let dados:iUsuarioCadastrarDto = {
            email: form.email.value,
            senha: form.senha.value,
            nome: form.nome.value,
            apelido: form.apelido.value,
            situacao: Number(form.situacao.value)
        }

        if (dados.nome === "") {
            form.nome.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.email === "") {
            form.email.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.senha === "" && usuarioD !== undefined) {
            dados.senha = usuarioD.senha
        }

        if (usuarioD === undefined) {
            if (dados.senha === "") {
                form.senha.nextElementSibling.style.display = "block"
                formValidado = false
            }
        }

        if (!formValidado) {
            return
        }

        console.log(dados)

        if (usuarioD === undefined) {
            cadastrar(dados)
        }

        if (usuarioD !== undefined) {
            editar(dados)
        }

    }

    async function cadastrar(dados:iUsuarioCadastrarDto) {

        setLoading(true)

        const api = await reqPut(ApiEndpoints.painelControle.usuarios.cadastrar, dados)

        setLoading(false)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Usuário cadastrado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(1500)

            window.location.assign(`/painelControle/usuarios`)
        }

    }

    async function editar(dados: iUsuarioCadastrarDto) {
        setLoading(true)

        let dadosAux:any = dados
        dadosAux['id'] = usuarioD?.id

        const api = await reqPatch(ApiEndpoints.painelControle.usuarios.editar, dadosAux)

        setLoading(false)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Usuário editado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/painelControle/usuarios`)
        }
    }


    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />

            <h1 id="titleMain">Usuário</h1>

            <br></br>

            <Form id='form' onSubmit={handleSubmit} noValidate>
                
                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Nome:</Form.Label>
                            <Form.Control name='nome' type="text" id='nome' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o Nome!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Apelido:</Form.Label>
                            <Form.Control name='apelido' type="text" id='apelido' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe a Apelido!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>E-mail:</Form.Label>
                            <Form.Control name='email' type="email" id='email' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe o E-mail!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Senha:</Form.Label>
                            <Form.Control name='senha' type="password" id='senha' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe a senha!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Situação:</Form.Label>
                            <Form.Select name="situacao" id="situacao" aria-label="Selecione a situação">
                                <option value="1">Ativo</option>
                                <option value="0">Desativado</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                
                {
                    usuarioD === undefined &&
                    <Button variant="success" type="submit">Cadastrar</Button>
                }

                {
                    usuarioD !== undefined &&
                    <Button variant="success" type="submit">Editar</Button>
                }

            </Form>

        </div>
    )

}

export default UsuariosFormPage