import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import styles from '../../styles/apuracao.module.css'
import dayjs from "dayjs"

interface iProps {
    dadosAjustes: any[],
    handleAjuste: any
}

export default function ApuracaoPagePainelDetalhesAjustes({dadosAjustes, handleAjuste}:iProps) {
    const divStyle:React.CSSProperties = {
        margin: "10px 0px",
    }
    
    return (
        <div style={divStyle}>
            <p>Ajustes</p>
            {
                dadosAjustes.map((ajuste, index) => (
                    <div key={index} style={divStyle} className={styles.apuracaoDiaRegs}>
                        <Row>
                            <Col className="col-4">
                                <p>#{ajuste.a_id_ajuste} {ajuste.a_status}</p>
                                <p>Cadastrado em {dayjs(ajuste.a_data_criacao).format("DD/MM/YYYY HH:mm:ss")}</p>
                                <p><b>Por {ajuste.a_solicitante_nome}</b></p>
                                <br/>
                                {
                                    ajuste.a_id_status === "1" &&
                                    <div className="div-row">
                                        <Button variant="success" onClick={() => handleAjuste(ajuste, 2)} style={{marginRight: "10px"}}>Aprovar</Button>
                                        <Button variant="danger" onClick={() => handleAjuste(ajuste, 3)}>Reprovar</Button>
                                    </div>
                                }
                                {
                                    ajuste.a_id_status !== "1" &&
                                    <div>
                                        <p><b>{ajuste.a_status} por {ajuste.a_usuario_aprovou}</b></p>
                                        <p><b>{dayjs(ajuste.a_data_concluido).format("DD/MM/YYYY HH:mm:ss")}</b></p>
                                        <div style={{display: dayjs(ajuste.a_data_concluido).isSame(dayjs(), 'month') === true ? 'block' : 'none',}}>
                                            <br></br>
                                            <Button variant="primary" size="sm" onClick={() => handleAjuste(ajuste, 1)}>Desfazer</Button>
                                        </div>
                                    </div>
                                }
                            </Col>
                            <Col className="col-4">
                                <p>Justificativa</p>
                                <p>{ajuste.a_justificativa}</p>
                                <br/>
                                <p>Ajuste Feito</p>
                                {
                                    ajuste.horarios.filter((reg:any) => reg.hora_ajuste === "23:59:59").length === ajuste.horarios.length &&
                                    <p>Dia Cancelado</p>
                                }
                                {
                                    ajuste.horarios.filter((reg:any) => reg.hora_ajuste === "23:59:00").length === ajuste.horarios.length &&
                                    <p>Dia Cancelado</p>
                                }
                                {
                                    ajuste.horarios.filter((reg:any) => reg.hora_ajuste === "00:00:00").length === ajuste.horarios.length &&
                                    <p>Dia Cancelado</p>
                                }
                                {
                                    (
                                        ajuste.horarios.filter((reg:any) => reg.hora_ajuste === "23:59:59").length !== ajuste.horarios.length &&
                                        ajuste.horarios.filter((reg:any) => reg.hora_ajuste === "23:59:00").length !== ajuste.horarios.length &&
                                        ajuste.horarios.filter((reg:any) => reg.hora_ajuste === "00:00:00").length !== ajuste.horarios.length
                                    ) &&
                                    ajuste.horarios.map((horario:any, index:any) => (
                                        <p key={index}>{horario.id_tipo_marcacao === 0 ? 'Entrada' : 'Saída'} {dayjs(horario.data_hora_ajuste).format("DD/MM/YYYY HH:mm:ss")}</p>
                                    ))
                                }
                            </Col>
                            <Col className="col-4">
                                <p>Observação:</p>
                                <p>{ajuste.a_observacao}</p>
                            </Col>
                        </Row>
                    </div>
                ))
            }
        </div>
    )
}