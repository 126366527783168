import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { iDiaApuracao, iDiaApuracaoRegistro, iResponseApuracao } from "../../dto/dados-apuracao.dto";
import dayjs from "dayjs";
import { getCookie } from "../../../../config/cookies";
import { toast } from "react-toastify";
import { ApiEndpoints, reqPut } from "../../../../config/apiClient";

interface iProps {
    show: boolean,
    handleClose: any,
    handleNew: any,
    dados: iResponseApuracao
}

export default function ApuracaoModalDuplicados ({show, handleClose, handleNew, dados}:iProps) {

    const [loading, setLoading] = useState<boolean>(false)

    function ajustarDiasDuplicados():any[] {

        if (dados.dados.colaborador.id_escala.id_tipo !== 2 && dados.dados.colaborador.id_escala.intervalo_registrar !== 1) {
            console.log('escala_nao_compativel')
            return []
        }

        let dias_ajustes = []
        for (let dia of dados.calendario) {
            let qtd_marcacoes = verificarDiaPossuiMarcacoes(dia)
            if (qtd_marcacoes <= 4) {
                continue
            }
            let marcacoes = getMarcacoesDia(dia)
            let marcacoes_aux = getMarcacoesNaoDuplicadas(marcacoes)
            console.log(`${dia.data} qtd_marcacoes ${qtd_marcacoes}`)
            console.log(marcacoes_aux)

            if (marcacoes_aux.length !== 4) {
                continue
            }
    
            if (dia.registros[0].tipo === 2) {
                continue
            }


            let ajuste:any = {
                "id_colaborador": dados.dados.colaborador.id_colaborador,
                "id_status": 2,
                "id_justificativa": "14",
                "observacao": "Removendo registros duplicados.",
                "id_solicitante": Number(getCookie('id_user')),
                "id_usuario": Number(getCookie('id_user')),
                "cancelar": 0,
                "horarios": [],
            }

            for (let reg of marcacoes_aux) {

                let data_criacao = dayjs(reg.data_registro)
    
                ajuste.horarios.push({
                    "id_tipo_marcacao": reg.tipo_registro,
                    "data_ajuste": data_criacao.format("YYYY-MM-DD"),
                    "hora_ajuste": data_criacao.format("HH:mm"),
                    "intervalo": null
                })
    
            }

            dias_ajustes.push(ajuste)
        }

        if (dias_ajustes.length === 0) {
            
            return []
        }

        console.log(dias_ajustes)

        return dias_ajustes

    }

    function verificarDiaPossuiMarcacoes(dia:iDiaApuracao) {
        if (dia.registros.length === 0) {
            return 0
        }
        let qtd_marcacoes = 0
        for (let reg of dia.registros) {
            if (reg.tipo === 1) {
                qtd_marcacoes++
            }
        }
        return qtd_marcacoes
    }

    function getMarcacoesDia(dia:iDiaApuracao):iDiaApuracaoRegistro[] {
        let marcacoes:iDiaApuracaoRegistro[] = []
        for (let reg of dia.registros) {
            if (reg.tipo === 1) {
                marcacoes.push(reg)
            }
        }
        return marcacoes
    }

    function getMarcacoesNaoDuplicadas(marcacoes:iDiaApuracaoRegistro[]) {
        let dados = []
        let tipo = null
        for (let reg of marcacoes) {
            if (tipo === null) {
                tipo = reg.tipo_registro
                dados.push(reg)
                continue
            }
            if (tipo === reg.tipo_registro) {
                continue
            }
            tipo = reg.tipo_registro
            dados.push(reg)
        }
        return dados
    }

    async function handleVerificarMarcacoesDuplicadas() {

        let ajustes = ajustarDiasDuplicados()

        if (ajustes.length === 0) {
            toast.warning('Não foi possível encontrar marcações duplicadas!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            return
        }

        if (!window.confirm("Tem certeza que deseja remover registros duplicados?")) {
            return
        }

        setLoading(true)

        let ajustesDados = []
        let dias = ""

        for (let ajuste of ajustes) {
            const api = await reqPut(ApiEndpoints.apuracaoAjusteCadastrar, ajuste)
            if (api.status === 201) {
                ajustesDados.push(api.data)
                dias = dias + `${dayjs(ajuste.horarios[0].data_ajuste).format("DD/MM")} `
            }
        }

        toast.success(`Ajustes cadastrados com sucesso, ${dias}!`, {
            containerId: 1,
            position: 'top-center',
            theme: 'dark',
            autoClose: 15000,
        })

        setLoading(false)

        handleNew(ajustesDados)

    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Remover registros duplicados:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button onClick={(e) => {handleVerificarMarcacoesDuplicadas()}} disabled={loading}>{loading ? "Aguarde..." : "Verificar Dias Duplicados"}</Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}