import React, { useEffect, useState } from "react"
import getUrlSearchParams from "../../components/funcGetUrlSearchParams"
import LoadingModal from "../../components/loadingModal"
import { ApiEndpoints, reqPostNew } from "../../config/apiClient"
import ApuracaoPageColaborador from "./componentes/ApuracaoPageColaborador"
import ApuracaoPageDados from "./componentes/ApuracaoPageDados"
import { Button } from "react-bootstrap"
import ApuracaoModalOcorrencias from "./componentes/modals/ApuracaoModalOcorrencia"
import { observer } from "mobx-react-lite"
import ApuracaoState from "./ApuracaoState"
import ApuracaoModalAjustes from "./componentes/modals/ApuracaoModalAjuste"
import { toast } from "react-toastify"
import ApuracaoModalReferencias from "./componentes/modals/ApuracaoModalReferencia"
import ApuracaoPageTotais from "./componentes/ApuracaoPageTotais"
import dayjs from "dayjs"
import formatTime from 'format-hours'
import ApuracaoModalDownload from "./componentes/modals/ApuracaoModalDownload"
import { iResponseApuracao } from "./dto/dados-apuracao.dto"
import ApuracaoModalDuplicados from "./componentes/modals/ApuracaoModalDuplicados"
import ApuracaoModalHistoricoEscalas from "./componentes/modals/ApuracaoModalHistoricoEscalas"

const apuracaoState = new ApuracaoState()

interface iFiltroApuracao {
    id_colaborador: number
    data_inicial: string
    data_final: string
}

interface iPropsApuracaoStateView {
    apuracaoState: ApuracaoState,
    onUpdate: any
}

const ApuracaoStateView = observer(({ apuracaoState, onUpdate }:iPropsApuracaoStateView) => {
    return (
        <div>
            <ApuracaoPageTotais dados={apuracaoState.dados} />
            <br></br>
            <ApuracaoPageDados dados={apuracaoState.dados} apuracaoState={apuracaoState} onUpdateState={onUpdate} />
        </div>
        // <div>
        //     <ApuracaoPageDados dados={apuracaoState.dados} apuracaoState={apuracaoState} />
        //     <span>Seconds passed: {apuracaoState.secondsPassed}</span>
        // </div>
    )
})

function ApuracaoPage() {
    useEffect(() => {
        document.title = "Apuracao | OB PORTUS"

        const getDados = async () => {
            const url = getUrlSearchParams()
            const dados:any = {
                "id_colaborador": Number(url.get('id')),
                "data_inicial": url.get('inicial'),
                "data_final": url.get('final'),
            }
            setFiltroApuracao(dados)

            console.log(dados)

            const api = await reqPostNew(ApiEndpoints.apuracaoCalcularConsultarDados, dados)
            setLoading(false)

            if (api.status === 200) {
                console.log(api.data)
                setDadosApuracao(api.data)
                document.title = `Apuracao ${api.data.dados.colaborador.nome_completo} | OB PORTUS`
                apuracaoState.atualizarApuracaoState(api.data)
            }
        }

        getDados()
    }, [])

    const [showModalOcorrencias, setShowModalOcorrencias] = useState<boolean>(false)
    const [showModalAjustes, setShowModalAjustes] = useState<boolean>(false)
    const [showModalReferencias, setShowModalReferencias] = useState<boolean>(false)
    const [showModalDuplicados, setShowModalDuplicados] = useState<boolean>(false)
    const [showModalDownload, setShowModalDownload] = useState<boolean>(false)
    const [showModalHistoricoEscalas, setShowModalHistoricoEscalas] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [dadosApuracao, setDadosApuracao] = useState<any>({})
    const [filtroApuracao, setFiltroApuracao] = useState<iFiltroApuracao>({id_colaborador: 0, data_inicial: "", data_final: ""})

    const divControls:React.CSSProperties = {
        width: "auto",
        position: "fixed",
        right: "40px",
        bottom: "20px",
        // backgroundColor: "grey",
    }

    const divButtonsControls:React.CSSProperties = {
        marginRight: "10px",
    }

    function handleNovaOcorrencia(ocorrenciaNova:any) {
        setShowModalOcorrencias(false)
        let dadosAux = dadosApuracao
        dadosAux.dados.ocorrencias.push(ocorrenciaNova)
        // dadosAux.calendario[3].HT = "12:22"
        apuracaoState.atualizarApuracaoState(dadosAux)
        setDadosApuracao(dadosAux)
    }

    async function handleNovaAjuste(ajusteNovo:any) {
        setShowModalAjustes(false)
        setLoading(true)
        let dadosAux = dadosApuracao
        dadosAux.dados.ajustes.push(ajusteNovo)
        if (ajusteNovo.a_id_status === "2") {
            const dadosCalcular = {
                id_colaborador: ajusteNovo.a_id_colaborador,
                data_inicial: ajusteNovo.horarios[0].data_ajuste,
                data_final: ajusteNovo.horarios[0].data_ajuste,
            }
            dadosAux = await calcularDiaApuracao(dadosCalcular, dadosAux)
        }
        apuracaoState.atualizarApuracaoState(dadosAux)
        setDadosApuracao(dadosAux)
        setLoading(false)
    }

    async function handleNewAjusteDuplicados(ajustesNovos:any[]) {
        setShowModalDuplicados(false)
        setLoading(true)

        let dadosAux = dadosApuracao
        for (let ajuste of ajustesNovos) {
            dadosAux.dados.ajustes.push(ajuste)
            const dadosCalcular = {
                id_colaborador: ajuste.a_id_colaborador,
                data_inicial: ajuste.horarios[0].data_ajuste,
                data_final: ajuste.horarios[0].data_ajuste,
            }
            dadosAux = await calcularDiaApuracao(dadosCalcular, dadosAux)
        }

        apuracaoState.atualizarApuracaoState(dadosAux)
        setDadosApuracao(dadosAux)
        setLoading(false)
    }

    async function calcularDiaApuracao(dadosCalcular:any, dadosAux:iResponseApuracao) {
        const apiCalcular = await reqPostNew(ApiEndpoints.apuracaoCalcularDados, dadosCalcular)
        if (apiCalcular.status === 200) {
            let dadosC = apiCalcular.data
            for (let i in dadosAux.calendario) {
                if (dadosAux.calendario[i].data === dadosCalcular.data_inicial) {
                    dadosAux.calendario[i] = dadosC.calendario[0]
                    console.log('calcular_dia')
                    break
                }
            }
            dadosAux.totais = calcularTotaisApuracao(dadosAux.calendario)
        }
        return dadosAux
    }

    async function handleNovaReferencia(flag:any) {
        setShowModalReferencias(false)
        let dadosAux = dadosApuracao
        dadosAux.dados.flags = dadosAux.dados.flags.filter((reg:any) => reg.id_flag !== flag.id_flag)
        dadosAux.dados.flags.push(flag)
        const dados = {
            "id_colaborador": flag.id_colaborador,
            "data_inicial": flag.data_dia,
            "data_final": flag.data_dia,
        }
        const api = await reqPostNew(ApiEndpoints.apuracaoCalcularDados, dados)
        if (api.status === 200) {
            let dadosC = api.data
            for (let i in dadosAux.calendario) {
                if (dadosAux.calendario[i].data === dados.data_inicial) {
                    dadosAux.calendario[i] = dadosC.calendario[0]
                    console.log('calcular_dia')
                    break
                }
            }
            dadosAux.totais = calcularTotaisApuracao(dadosAux.calendario)
        }
        apuracaoState.atualizarApuracaoState(dadosAux)
        setDadosApuracao(dadosAux)
    }

    function calcularTotaisApuracao(apuracao:any[]):any {
        let totais:any = {
            horas_necessarias: 0,
            HN: "00:00",
            horas_trabalhadas: 0,
            HT: "00:00",
            horas_extras: 0,
            HE: "00:00",
            horas_faltas: 0,
            HF: "00:00",
            adicional_noturno: 0,
            AN: "00:00",
            intra_jornada: 0,
            HI: "00:00",
            dias_trabalhados: 0
        }

        for (let dia of apuracao) {
            totais.horas_necessarias += dia.horas_necessarias
            totais.horas_trabalhadas += dia.horas_trabalhadas
            totais.horas_extras += dia.horas_extras
            totais.horas_faltas += dia.horas_faltas
            totais.adicional_noturno += dia.adicional_noturno
            totais.intra_jornada += dia.intra_jornada

            if (dia.registros.length > 0) {
                totais.dias_trabalhados += 1
            }
        }

        totais.HN = formatTime(Math.ceil(((totais.horas_necessarias / 60) * 100)) / 100, {fullSize: true})
        totais.HT = formatTime(Math.ceil(((totais.horas_trabalhadas / 60) * 100)) / 100, {fullSize: true})
        totais.HE = formatTime(Math.ceil(((totais.horas_extras / 60) * 100)) / 100, {fullSize: true})
        totais.HF = formatTime(Math.ceil(((totais.horas_faltas / 60) * 100)) / 100, {fullSize: true})
        totais.AN = formatTime(Math.ceil(((totais.adicional_noturno / 60) * 100)) / 100, {fullSize: true})
        totais.HI = formatTime(Math.ceil(((totais.intra_jornada / 60) * 100)) / 100, {fullSize: true})
        
        return totais
    }

    async function handleRecalcular(func_data:"proximo" | "anterior" | null) {
        setLoading(true)

        let linkApiAux = ApiEndpoints.apuracaoCalcularDados
        let calcular = true

        const url = getUrlSearchParams()
        let dados:any = {
            "id_colaborador": Number(url.get('id')),
            "data_inicial": url.get('inicial'),
            "data_final": url.get('final'),
        }

        if (func_data === "proximo") {
            linkApiAux = ApiEndpoints.apuracaoCalcularConsultarDados
            calcular = false
            let data_inicial_aux = dados.data_inicial
            dados.data_inicial = dayjs(data_inicial_aux).add(1, `month`).startOf('month').format("YYYY-MM-DD")
            dados.data_final = dayjs(data_inicial_aux).add(1, `month`).endOf('month').format("YYYY-MM-DD")
            window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESA APURACAO", `/ponto/apuracao?id=${dados.id_colaborador}&inicial=${dados.data_inicial}&final=${dados.data_final}`)
            toast.info(`Próximo Mês: ${dayjs(dados.data_inicial).format("DD/MM/YYYY")} até ${dayjs(dados.data_final).format("DD/MM/YYYY")}!`, {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        if (func_data === "anterior") {
            linkApiAux = ApiEndpoints.apuracaoCalcularConsultarDados
            calcular = false
            let data_inicial_aux = dados.data_inicial
            dados.data_inicial = dayjs(data_inicial_aux).subtract(1, `month`).startOf('month').format("YYYY-MM-DD")
            dados.data_final = dayjs(data_inicial_aux).subtract(1, `month`).endOf('month').format("YYYY-MM-DD")
            window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESA APURACAO", `/ponto/apuracao?id=${dados.id_colaborador}&inicial=${dados.data_inicial}&final=${dados.data_final}`)
            toast.info(`Mês Anterior: ${dayjs(dados.data_inicial).format("DD/MM/YYYY")} até ${dayjs(dados.data_final).format("DD/MM/YYYY")}!`, {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
        }

        setFiltroApuracao(dados)
        console.log(dados)
        const api = await reqPostNew(linkApiAux, dados)
        if (api.status === 200) {
            console.log(api.data)
            setDadosApuracao(api.data)
            apuracaoState.atualizarApuracaoState(api.data)
            if (calcular) {
                toast.success('Calculado com sucesso!', {
                    containerId: 1,
                    position: 'top-center',
                    theme: 'dark'
                })
            }
        }

        setLoading(false)
    }

    function handleOnUpdateState(dadosNovos:any) {
        setDadosApuracao(dadosNovos)
    }

    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />
            <h1 id="titleMain">Apuração {
                Object.values(dadosApuracao).length > 0 &&
                <span style={{fontSize: '16px'}}>{dayjs(dadosApuracao.filtro.data_inicial).format("DD/MM/YYYY")} até {dayjs(dadosApuracao.filtro.data_final).format("DD/MM/YYYY")}</span>
            }</h1>
            { Object.values(dadosApuracao).length > 0 && <ApuracaoPageColaborador dados={dadosApuracao.dados} filtro={dadosApuracao.filtro} /> }
            <br></br>
            {/* { Object.values(dadosApuracao).length > 0 && <ApuracaoPageDados dados={dadosApuracao} /> } */}
            { Object.values(dadosApuracao).length > 0 && <ApuracaoStateView apuracaoState={apuracaoState} onUpdate={handleOnUpdateState} /> }

            <div className="div-row" style={divControls}>
                <Button onClick={() => setShowModalOcorrencias(true)} style={divButtonsControls}>Nova Ocorrência</Button>
                <Button onClick={() => setShowModalAjustes(true)} style={divButtonsControls}>Ajustar</Button>
                <Button onClick={() => setShowModalReferencias(true)} style={divButtonsControls}>Referência</Button>
                <Button onClick={() => setShowModalDuplicados(true)} style={divButtonsControls}>Remover Duplicados</Button>
                <Button onClick={() => handleRecalcular("anterior")} style={divButtonsControls} variant="warning" className='div-row-space-between'><span className="material-symbols-outlined div-row" style={{}}>arrow_back</span></Button>
                <Button onClick={() => handleRecalcular(null)} style={divButtonsControls} variant="warning">Recalcular</Button>
                <Button onClick={() => handleRecalcular("proximo")} style={divButtonsControls} variant="warning" className='div-row-space-between'><span className="material-symbols-outlined div-row" style={{}}>arrow_forward</span></Button>
                <Button onClick={() => setShowModalDownload(true)} style={divButtonsControls} variant="success">Download</Button>
                {/* <Button onClick={() => handleNovaOcorrencias()} style={divButtonsControls}>Teste</Button> */}
            </div>

            { Object.values(dadosApuracao).length > 0 && <ApuracaoModalOcorrencias show={showModalOcorrencias} handleClose={() => setShowModalOcorrencias(false)} handleNew={handleNovaOcorrencia} dados={dadosApuracao} /> }
            { Object.values(dadosApuracao).length > 0 && <ApuracaoModalAjustes show={showModalAjustes} handleClose={() => setShowModalAjustes(false)} handleNew={handleNovaAjuste} dados={dadosApuracao} /> }
            { Object.values(dadosApuracao).length > 0 && <ApuracaoModalReferencias show={showModalReferencias} handleNew={handleNovaReferencia} handleClose={() => setShowModalReferencias(false)} dados={dadosApuracao} /> }
            { Object.values(dadosApuracao).length > 0 && <ApuracaoModalDuplicados show={showModalDuplicados} handleClose={() => setShowModalDuplicados(false)} handleNew={handleNewAjusteDuplicados} dados={dadosApuracao} /> }
            { Object.values(dadosApuracao).length > 0 && <ApuracaoModalDownload show={showModalDownload} handleClose={() => setShowModalDownload(false)} dados={dadosApuracao} /> }
            {Object.values(dadosApuracao).length > 0 && <ApuracaoModalHistoricoEscalas show={showModalHistoricoEscalas} handleClose={() => setShowModalHistoricoEscalas(false)} dados={dadosApuracao} handleUpdate={() => { 
              setShowModalHistoricoEscalas(false)
              handleRecalcular(null)
            }} /> }
            <br></br>
            { Object.values(dadosApuracao).length > 0 && <Button onClick={() => { setShowModalHistoricoEscalas(true) }} variant="outline-secondary" >Ver histórico completo de escalas</Button> }
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <a href={`${ApiEndpoints.apiOld}/dashboard/ponto/consultar/id=${filtroApuracao.id_colaborador}&inicial=${filtroApuracao.data_inicial}&final=${filtroApuracao.data_final}`} rel="noreferrer" target="_blank">Ir para página de consulta antiga</a>
        </div>
    )
}

export default ApuracaoPage