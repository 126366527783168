import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap'
import styles from './styles/consulta.module.css'
import { iEmpresa } from './dto/nfInterfaces'
import { ApiEndpoints, reqPost } from '../../config/apiClient'
import { getUrlSearchParams } from '../urlParams'

import $ from 'jquery'
import 'jquery-mask-plugin'
import dayjs from 'dayjs'

import iconAdd from '../../img/home/add.svg'

import {
    createColumnHelper,
  } from '@tanstack/react-table'
import ListaNF from './Lista'

interface iNF {
    "id_nota": number,
    "descricao": string,
    "data_competencia": string,
    "numero_nf": number,
    "data_servico": string,
    "data_emissao": string,
    "status": string,
    "cod_serv": string,
    "valor_servicos": string,
    "id_cliente": number,
    "cliente": string,
    "iss_nota": number
}

interface iCompetenciaFiltro {
    "id_empresa": number,
    "mes": number,
    "ano": number
}

const columnHelper = createColumnHelper<iNF>()

const defaultColumns = [
    columnHelper.accessor(row => dayjs(row.data_servico).format("MM/YYYY"), {
        header: 'Compet.'
    }),
    columnHelper.accessor('descricao', {
        header: 'Descrição'
    }),
    columnHelper.accessor('status', {
        header: 'Status'
    }),
    columnHelper.accessor(row => row.iss_nota === 0 ? 'Tributada' : 'Retida', {
        header: 'Situação'
    }),
    columnHelper.accessor('numero_nf', {
        header: 'NF'
    }),
    columnHelper.accessor(row => dayjs(row.data_servico).format("DD/MM/YYYY"), {
        header: 'Data Serv.'
    }),
    columnHelper.accessor('cod_serv', {
        header: 'Serv.',
    }),
    columnHelper.accessor('valor_servicos', {
        header: 'R$ Vl.'
    }),
    columnHelper.accessor('cliente', {
        header: 'Tomador'
    }),
]

const defaultOrder = [
    {
        id: 'status',
        desc: true
    },
    {
        id: 'numero_nf',
        desc: false
    }
]

export default function NFConsulta(props:any) {

    const empresa:iEmpresa = props.empresaDados

    const [nfsDados, setNfsDados] = useState<iNF[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [competenciaFiltro, setCompetenciaFiltro] = useState<iCompetenciaFiltro>({
        "id_empresa": 0,
        "mes": 0,
        "ano": 0
    })

    useEffect(() => {

        if (empresa.id_empresa !== 0) {
            document.title = `NF ${empresa.nome} | OB PORTUS`

            const getNfs = async () => {

                let competencia_state:any = document.getElementById("competencia_state")
                let competencia_filtro:any = document.getElementById("competencia")

                if (competencia_state.value === "") {
                    competencia_state.value = dayjs().format("MM/YYYY")
                    competencia_filtro.value = competencia_state.value
                } else {
                    competencia_filtro.value = competencia_state.value
                }

                const competencia = String(competencia_filtro.value).split("/")

                const dados = {
                    "id_empresa": empresa.id_empresa,
                    "mes": Number(competencia[0]),
                    "ano": Number(competencia[1])
                }

                setCompetenciaFiltro(dados as iCompetenciaFiltro)

                const api = await reqPost(ApiEndpoints.nfConsultarEmpresa, dados)
        
                if (api.status === 200) {

                    let nfsDadosAux:iNF[] = api.data

                    for (let x in nfsDadosAux) {

                        nfsDadosAux[x].valor_servicos = $('.inputMoney').mask('#.##0,00', {reverse: true }).masked(nfsDadosAux[x].valor_servicos)

                    }
        
                    setNfsDados(nfsDadosAux)
                    setLoading(false)
        
                }

            }

            getNfs()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    $('#competencia').mask('00/0000', {
        reverse: false
    })

    async function getNotas(dados:any) {

        setLoading(true)
        setCompetenciaFiltro(dados as iCompetenciaFiltro)

        const api = await reqPost(ApiEndpoints.nfConsultarEmpresa, dados)

        if (api.status === 200) {

            let nfsDadosAux:iNF[] = api.data

            for (let x in nfsDadosAux) {

                nfsDadosAux[x].valor_servicos = $('.inputMoney').mask('#.##0,00', {reverse: true }).masked(nfsDadosAux[x].valor_servicos)

            }

            setNfsDados(nfsDadosAux)

        }

        setLoading(false)

    }

    function handleClick(event:any): void {
        
        props.currentView("new")

    }

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const urlParams = getUrlSearchParams()
        const id_empresa = urlParams.get("id_empresa")

        if (event.button === 1) {
            window.open(`/nf?id_empresa=${id_empresa}&id_nota=${nfsDados[idx].id_nota}`)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        // document.getElementById("valor")?.setAttribute()
        // console.log(event)

        if (event.button === 0 && event.ctrlKey === true) {

            // console.log(event.target.parentElement.getAttribute("inlist"))

            if (event.target.parentElement.getAttribute("inlist") === "0" || event.target.parentElement.getAttribute("inlist") === null) {
                event.target.parentElement.setAttribute("inlist", "1")
                event.target.parentElement.style.backgroundColor = "rgba(0,0,0,.15)"
            } else if (event.target.parentElement.getAttribute("inlist") === "1") {
                event.target.parentElement.setAttribute("inlist", "0")
                event.target.parentElement.style.backgroundColor = "transparent"
            }
            
        }

        if (event.button === 0 && event.ctrlKey === false) {
            props.clickNf(nfsDados[idx])
        }

        // console.log(nfsDados[idx])

        // if (event.button === 0) {
        //     props.clickNf(nfsDados[idx])
        // }

        // if (event.button === 0) {
        //     window.location.assign(`/colaborador?id=${dados[idx].id_colaborador}`)
        // }
    }

    function handlePesquisaCompetencia(event:any) {

        let competencia = String(event.target.value)

        if (competencia.length === 7) {

            const arrayComp = competencia.split("/")

            const dados:iCompetenciaFiltro = {
                "id_empresa": empresa.id_empresa,
                "mes": Number(arrayComp[0]),
                "ano": Number(arrayComp[1])
            }

            if (dados.mes < 0 || dados.mes >= 13) {
                return
            }

            if (dados.ano <= 2023 || dados.ano >= Number(dayjs().add(1, 'year').add(1, 'year').format("YYYY"))) {
                return
            }

            if (dados.mes === competenciaFiltro.mes && dados.ano === competenciaFiltro.ano) {
                return
            }

            console.log(dados)
            getNotas(dados)

            let competencia_state:any = document.getElementById("competencia_state")
            competencia_state.value = `${arrayComp[0]}/${arrayComp[1]}`

        }

        if (competencia === "") {
            const dados = {
                "id_empresa": empresa.id_empresa,
                "mes": 0,
                "ano": 0
            }
            console.log(dados)
            getNotas(dados)
            let competencia_state:any = document.getElementById("competencia_state")
            competencia_state.value = competencia
            return
        }

    }

    return (
        <div id='div_nf_query' style={{marginTop: "10px"}}>

            <input type='text' className='inputMoney' readOnly style={{ display: "none" }}></input>

            <div className={styles.div_header_consulta}>
                <Form.Control type="text" id='competencia' onKeyUp={handlePesquisaCompetencia} placeholder="Competência" style={{width: "150px", marginRight: "10px", fontWeight: "bold"}}/>

                <Button
                    onClick={handleClick}
                    style={{
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <img src={iconAdd} alt='icon_add' className={styles.icon_add}></img>
                    <p>Nova</p>
                </Button>

            </div>

            <br></br>

            {!loading && <ListaNF dados={nfsDados} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}

        </div>
    )

}