import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { createColumnHelper } from "@tanstack/react-table"
import { iEmpresaDto } from "./dto/nfInterfaces"
import { ApiEndpoints, reqGetNew } from "../../config/apiClient"
import DataTable from "../../components/dataTable/DataTable"

const columnHelper = createColumnHelper<iEmpresaDto>()

const defaultColumns = [
    columnHelper.accessor('id_empresa', {
        header: 'ID',
        size: 10,
        minSize: 10,
        maxSize: 150
    }),
    columnHelper.accessor('nome', {
        header: 'Razão Social'
    }),
    columnHelper.accessor('cnpj', {
        header: 'CNPJ'
    }),
    columnHelper.accessor(row => `${row.logradouro}, ${row.numero}, ${row.bairro}, ${row.cidade} / ${row.cep} / ${row.uf}`, {
        header: 'Endereço',
    }),
    columnHelper.accessor(row => row.ambiente === 'ah' ? 'HOMOLOGAÇÃO' : 'PRODUÇÃO', {
        header: 'Ambiente Sistema'
    }),
]

const defaultOrder:any = []

function NfEmpresasPage() {
    const [empresas, setEmpresas] = useState<iEmpresaDto[]>([])

    useEffect(() => {
        document.title = "NF Empresas | OB PORTUS"

        const listarDados = async () => {
            const api = await reqGetNew(ApiEndpoints.nfEmpresas)

            if (api.status === 200) {
                const empresasAux:iEmpresaDto[] = api.data
                setEmpresas(empresasAux)
            }
        }

        listarDados()
    }, [])

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const reg = empresas[idx]
        const link = `/nf/empresas/consultar?id=${reg.id_empresa}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const local = empresas[idx]
        const link = `/nf/empresas/consultar?id=${local.id_empresa}`

        if (event.button === 0) {
            window.location.assign(link)
        }
    }

    return (
        <div id="main">
            <div className="div-row-space-between">
                <h1 id="titleMain">NF Empresas</h1>
                <Button onClick={() => {
                    // window.open(`/painelControle/empresas/cadastrar`)
                    window.location.assign(`/nf/empresas/consultar`)
                }}>Cadastrar</Button>
            </div>
            <br></br>
            {<DataTable dados={empresas} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}
        </div>
    )
}

export default NfEmpresasPage