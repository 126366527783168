import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { ApiEndpoints, reqGetNew } from "../../../config/apiClient"
import iCentroCustoDadosDto from "./dto/dados-centro_custo.dto"
import DataTable from "../../../components/dataTable/DataTable"
import { createColumnHelper } from "@tanstack/react-table"

const columnHelper = createColumnHelper<iCentroCustoDadosDto>()

const defaultColumns = [
    columnHelper.accessor('codigo', {
        header: 'ID',
        size: 10,
        minSize: 10,
        maxSize: 150
    }),
    columnHelper.accessor('centro_custo', {
        header: 'Centro de Custo'
    }),
    columnHelper.accessor(row => `${row.endereco}, ${row.numero}, ${row.bairro}, ${row.cidade}, ${row.estado}, ${row.cep}`, {
        header: 'Endereço',
    }),
    columnHelper.accessor(row => row.situacao === 1 ? 'Ativo' : 'Inativo', {
        header: 'Situação'
    }),
]

const defaultOrder:any = []

function CentroCustoPage() {
    const [centros_custo, setCentrosCusto] = useState<iCentroCustoDadosDto[]>([])

    useEffect(() => {
        document.title = "Centros de Custo | OB PORTUS"

        const listarCentrosCusto = async () => {
            const api = await reqGetNew(ApiEndpoints.painelControle.centros.todos)

            if (api.status === 200) {
                const centrosCustoAux:iCentroCustoDadosDto[] = api.data
                setCentrosCusto(centrosCustoAux)
            }
        }

        listarCentrosCusto()
    }, [])

    function tableMiddleClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const centro_custo = centros_custo[idx]
        const link = `/painelControle/centroCusto/editar?id=${centro_custo.id_centro_custo}`

        if (event.button === 1) {
            window.open(link)
        }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))
        const centro_custo = centros_custo[idx]
        const link = `/painelControle/centroCusto/editar?id=${centro_custo.id_centro_custo}`

        if (event.button === 0) {
            window.location.assign(link)
        }
    }

    return (
        <div id="main">
            <div className="div-row-space-between">
                <h1 id="titleMain">Centros de Custo</h1>
                <Button onClick={() => {
                    // window.open(`/painelControle/empresas/cadastrar`)
                    window.location.assign(`/painelControle/centroCusto/cadastrar`)
                }}>Cadastrar</Button>
            </div>
            <br></br>
            {<DataTable dados={centros_custo} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />}
        </div>
    )
}

export default CentroCustoPage