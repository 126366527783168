import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import Select from 'react-select'
import { reqGet, reqPost, ApiEndpoints } from '../../../config/apiClient'
import ModalCusto from './ModalCusto'
import dayjs from 'dayjs'
import { createColumnHelper } from '@tanstack/react-table'
import DataTable from '../../../components/dataTable/DataTable'
import $ from 'jquery'
import 'jquery-mask-plugin'

const customSelectStyles = {
    control: (baseStyles:any, state:any) => ({
        ...baseStyles,
        width: '256px',
        borderColor: "#dee2e6",
        height: '40px',
        marginRight: '5px'
    }),
    valueContainer: (provided:any, state:any) => ({
        ...provided,
        height: '30px',
        overflowY: 'auto',
    }),
    input: (provided:any, state:any) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: (state:any) => ({
        display: 'none',
    }),
    indicatorsContainer: (provided:any, state:any) => ({
        ...provided,
        height: '40px',
    }),
}

interface iCusto {
    "id_custo": number,
    "data_hora_custo": string,
    "tipo_custo": string,
    "veiculo": string,
    "cliente": string,
    "valor_total": string,
    "usuario": string,
    "itens": string,
    "hodometro": number
}

const columnHelper = createColumnHelper<iCusto>()

const defaultColumns = [
    columnHelper.accessor(row => dayjs(row.data_hora_custo).format("DD/MM/YYYY - HH:mm:ss") + ` - ID${row.id_custo}`, {
        header: 'Data',
        size: 120,
        minSize: 120,
        maxSize: 120
    }),
    columnHelper.accessor('tipo_custo', {
        header: 'Tipo',
        size: 160,
        minSize: 160
    }),
    columnHelper.accessor('veiculo', {
        header: 'Veículo',
        size: 200,
        minSize: 200
    }),
    columnHelper.accessor('valor_total', {
        header: 'Valor',
        size: 100,
        minSize: 100,
        maxSize: 150
    }),
    columnHelper.accessor('cliente', {
        header: 'Cliente',
        size: 200,
        minSize: 200
    }),
    columnHelper.accessor('itens', {
        header: 'Itens',
        size: 200,
        minSize: 150
    }),
    columnHelper.accessor('usuario', {
        header: 'Usuário',
        size: 180,
        minSize: 180,
        maxSize: 180
    }),
]

const defaultOrder:any = []

export default function FrotaDespesasPage() {

    $('.inputMoney').mask('#.##0,00', {
        reverse: true,
        placeholder: '0,00'
    })

    $('#competencia').mask('00/0000', {
        reverse: false
    })

    function defineValueInInput(id:string, value:any) {
        let ele :any = document.getElementById(id)
        if (ele === null) {
            return
        }
        ele.value = value
    }

    function formatarStringInReal(valor:string):string {
        let num = Number(valor).toFixed(2)
        defineValueInInput("valor_aux_view_custos", num)
        return "R$ " + $("#valor_aux_view_custos").masked(num)
    }

    const [competenciaFiltro, setCompetenciaFiltro] = useState({
        "mes": 0,
        "ano": 0
    })

    const [custosDados, setCustosDados] = useState<iCusto[]>([])
    const [custoS, setCustoS] = useState({})
    // const [veiculosDados, setVeiculosDados] = useState<iFrotaVeiculo[]>([])
    const [veiculosFiltro, setVeiculosFiltros] = useState<any>({})
    // const [tiposCustosDados, setTiposCustosDados] = useState<iTipoCusto[]>([])
    const [tiposCustosFiltro, setTiposCustosFiltro] = useState<any>({})
    // const [tiposManutencaoDados, setTiposManutencaoDados] = useState<iTipoManutencao[]>([])
    const [tiposManutencaoFiltro, setTiposManutencaoFiltro] = useState<any>({})
    const [clientesFiltro, setClientesFiltro] = useState<any>({})
    const [modalCustoShow, setModalCustoShow] = useState<boolean>(false);

    useEffect(() => {

        document.title = `Despesas Frota | OB PORTUS`

        let competencia_filtro:any = document.getElementById("competencia")
        competencia_filtro.value = dayjs().format("MM/YYYY")

        const getFiltrosDados = async () => {

            const api = await reqGet(ApiEndpoints.frotaFiltros)

            if (api.status === 200) {

                // setVeiculosDados(api.data.veiculos)
                let veiculosFiltroAux = api.data.veiculos.map((item:any, index:any) => (
                    {
                        "value": `${item.id_veiculo}`,
                        "label": `${item.veiculo} - ${item.placa}`
                    }
                ))
                setVeiculosFiltros(veiculosFiltroAux)

                // setTiposCustosDados(api.data.tipos_custos)
                let tiposCustosDadosAux = api.data.tipos_custos.map((item:any, index:any) => (
                    {
                        "value": `${item.id_tipo_despesa}`,
                        "label": `${item.tipo_despesa}`
                    }
                ))
                setTiposCustosFiltro(tiposCustosDadosAux)

                // setTiposManutencaoDados(api.data.tipos_manutencoes)
                let tiposManutencoesDadosAux = api.data.tipos_manutencoes.map((item:any, index:any) => (
                    {
                        "value": `${item.id_tipo_manutencao}`,
                        "label": `${item.tipo_manutencao}`
                    }
                ))
                setTiposManutencaoFiltro(tiposManutencoesDadosAux)

                let clientesDadosAux = api.data.clientes.map((item:any, index:any) => (
                    {
                        "value": `${item.id_cliente}`,
                        "label": `(${item.id_centro_custo}) ${item.descricao}`
                    }
                ))
                setClientesFiltro(clientesDadosAux)

                getLista()

            }

        }

        getFiltrosDados()
        // eslint-disable-next-line
    }, [])

    function definirDescricaoColunaItens(itens:any[]):string {
        let item = ''
        for (let reg of itens) {
            if (reg.id_categoria === 1) {
                if (item === '') {
                    item = reg.tipo_despesa
                } else {
                    item = `${item}/${reg.tipo_despesa}`
                }
                continue
            }
            if (reg.id_categoria === 2) {
                if (item === '') {
                    item = reg.tipo_manutencao
                } else {
                    item = `${item}/${reg.tipo_manutencao}`
                }
                continue
            }
        }
        return item
    }

    async function getLista() {

        let dados = {
            "mes": 0,
            "ano": 0
        }

        let competencia_filtro:any = document.getElementById("competencia")
        if (competencia_filtro.value !== "") {
            const competencia = String(competencia_filtro.value).split("/")
            dados = {
                "mes": Number(competencia[0]),
                "ano": Number(competencia[1])
            }
        }

        setCompetenciaFiltro(dados)
        
        const api = await reqPost(ApiEndpoints.frotaListar, dados)

        if (api.status === 200) {
            // console.log(api.data)

            let custos:any[] = api.data
            let custos_list:iCusto[] = []

            for (let i in custos) {

                let reg = custos[i]

                let item:iCusto = {
                    id_custo: reg.id_custo,
                    data_hora_custo: reg.data_hora_custo,
                    tipo_custo: reg.tipo_custo,
                    veiculo: reg.veiculo,
                    cliente: reg.cliente,
                    valor_total: formatarStringInReal(reg.valor_total),
                    usuario: reg.usuario,
                    itens: definirDescricaoColunaItens(reg.itens),
                    hodometro: reg.hodometro
                }
                custos_list.push(item)

            }

            setCustosDados(custos_list)

        }

    }

    async function getCustoDados(dados:any) {

        const api = await reqPost(ApiEndpoints.frotaCusto, dados)

        if (api.status === 200) {
            setCustoS(api.data)
        }

    }

    function tableMiddleClickhandler(event:any) {
        // const idx = Number(event.target.parentElement.getAttribute('about'))
        // const urlParams = getUrlSearchParams()
        // const id_empresa = urlParams.get("id_empresa")

        // if (event.button === 1) {
        //     window.open(`/nf?id_empresa=${id_empresa}&id_nota=${nfsDados[idx].id_nota}`)
        // }
    }

    function tableClickhandler(event:any) {
        const idx = Number(event.target.parentElement.getAttribute('about'))

        setModalCustoShow(true)
        getCustoDados(custosDados[idx])
    }

    function handlePesquisaCompetencia(event:any) {

        let competencia = String(event.target.value)

        if (competencia.length === 7) {

            const arrayComp = competencia.split("/")

            const dados = {
                "mes": Number(arrayComp[0]),
                "ano": Number(arrayComp[1])
            }

            if (dados.mes < 0 || dados.mes >= 13) {
                return
            }

            if (dados.ano < 2020) {
                return
            }

            if (dados.mes === competenciaFiltro.mes && dados.ano === competenciaFiltro.ano) {
                return
            }

            console.log(dados)
            getLista()
            return

        }

        if (competencia === "") {
            const dados = {
                "mes": 0,
                "ano": 0
            }
            console.log(dados)
            getLista()
            return
        }

    }

    return (
        <div id='main'>
            <input type='text' name='valor_aux_view_custos' id='valor_aux_view_custos' className='inputMoney' style={{display:'none'}} />
            <ModalCusto show={modalCustoShow} custoDados={custoS} onHide={() => {
                setModalCustoShow(false)
                setCustoS({})
            }} veiculosFiltro={veiculosFiltro} tiposCustosFiltro={tiposCustosFiltro} tiposManutencaoFiltro={tiposManutencaoFiltro} clientesFiltro={clientesFiltro} onUpdate={getLista} />
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    
                }}>
                    <h1 id='titleMain'>Despesas Frota</h1>
                    <Button variant='secondary' style={{height: '40px', display: 'flex', alignItems: 'center', margin: '0px 15px 0px 15px'}} onClick={() => {
                        setModalCustoShow(true)
                    }} ><span>Criar</span> <span className="material-symbols-outlined">add</span></Button>
                    <Form.Control type="text" id='competencia' onKeyUp={handlePesquisaCompetencia} placeholder="Competência" style={{width: "150px", marginRight: "10px"}}/>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }}>
                    <Select name='ids_veiculos' id='ids_veiculos' options={veiculosFiltro} placeholder="Veículos" isMulti closeMenuOnSelect={false} onChange={
                        (choice) => {
                            // let selectValuesAux = selectValues
                            // selectValuesAux['id_empresa'] = choice as iChoice
                            // setSelectValues(selectValuesAux)
                        }
                    } styles={customSelectStyles} />
                    <Select name='ids_tipos_custos' id='ids_tipos_custos' options={tiposCustosFiltro} placeholder="Tipos Custos" isMulti closeMenuOnSelect={false} onChange={
                        (choice) => {
                            // let selectValuesAux = selectValues
                            // selectValuesAux['id_empresa'] = choice as iChoice
                            // setSelectValues(selectValuesAux)
                        }
                    } styles={customSelectStyles} />
                    <Select name='ids_tipos_manutencoes' id='ids_tipos_manutencoes' options={tiposManutencaoFiltro} placeholder="Tipos Manutenções" isMulti closeMenuOnSelect={false} onChange={
                        (choice) => {
                            // let selectValuesAux = selectValues
                            // selectValuesAux['id_empresa'] = choice as iChoice
                            // setSelectValues(selectValuesAux)
                        }
                    } styles={customSelectStyles} />
                    <Button variant='secondary' style={{height: '40px'}}><span className="material-symbols-outlined">filter_alt</span></Button>
                </div>
            </div>
            <br></br>
            <DataTable dados={custosDados} tableSize={'auto'} defaultColumns={defaultColumns} tableMiddleClickhandler={tableMiddleClickhandler} tableClickhandler={tableClickhandler} defaultOrder={defaultOrder} />
        </div>
    )
}