import React from "react"

export default function ApuracaoPageTotais({dados}:any) {

    const pStyle:React.CSSProperties = {
        marginRight: '5px',
    }

    return (
        <div>
            <div className="div-row">
                <p style={pStyle}><b>Totais: </b></p>
                <p style={pStyle}>DT: {dados.totais.dias_trabalhados} |</p>
                <p style={pStyle}>Horas Trabalhadas: {dados.totais.HT} |</p>
                <p style={pStyle}>Horas Extras: {dados.totais.HE} |</p>
                <p style={pStyle}>Horas Faltas: {dados.totais.HF} |</p>
                <p style={pStyle}>Horas Noturno: {dados.totais.AN} |</p>
                <p style={pStyle}>Horas Intrajornada: {dados.totais.HI}</p>
            </div>
        </div>
    )

}