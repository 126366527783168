import React from "react"
import { Col, Row } from "react-bootstrap"
import styles from '../../styles/apuracao.module.css'
import dayjs from "dayjs"

interface iProps {
    obs: any
}

export default function ApuracaoPagePainelDetalhesObservacoes({obs}:iProps) {
    const divStyle:React.CSSProperties = {
        margin: "10px 0px",
    }
    
    return (
        <div style={divStyle}>
            <div className={styles.apuracaoDiaRegs}>
                <Row>
                    <Col className="col-4">
                        <p>Observação #{obs.id_observacao}</p>
                        <p>Registrado em {dayjs(obs.data_criacao).format("DD/MM/YYYY HH:mm:ss")}</p>
                    </Col>
                    <Col className="col-4">
                        <p>Justificativa</p>
                        {
                            obs.id_motivo === null &&
                            <p>Em branco</p>
                        }
                        {
                            obs.id_motivo !== null &&
                            <p>{obs.id_motivo.descricao}</p>
                        }
                    </Col>
                    <Col className="col-4">
                        <p>Observação:</p>
                        <p>{obs.observacao}</p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}