import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { ApiEndpoints, reqPatch } from "../../../../config/apiClient";
import { toast } from "react-toastify";
import dayjs from "dayjs";

interface iPropsModal {
  show: boolean;
  handleClose: any;
  dados: any;
  handleUpdate: any;
}

export default function ApuracaoModalHistoricoEscalas({
  show,
  handleClose,
  dados,
  handleUpdate,
}: iPropsModal) {

  const [escalasHistorico, setEscalasHistorico] = useState<any[]>([]);

  useEffect(() => {
    const getDados = async () => {
      
      console.log(dados.dados.escalas_historico)
      if (dados.dados.escalas_historico.length > 0) {
        setEscalasHistorico(dados.dados.escalas_historico);
      }

    };

    getDados();
    // eslint-disable-next-line
  }, [dados]);

  async function handleChangeHistorico(event: any, historico: any) {
    let dados: any = {
      id_historico: historico.id,
      ativo: Number(event.target.value),
    };
    console.log(dados);
    
    const api = await reqPatch(
      ApiEndpoints.colaboradorEscalaHistoricoAlterar,
      dados,
    );

    if (api.status === 200) {
      toast.success("Escala alterada com sucesso!", {
        containerId: 1,
        position: "top-center",
        theme: "dark",
      });
      handleUpdate();
    }
    
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg" >
      <Modal.Header closeButton>
        <Modal.Title>Histórico de Escalas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row style={{fontWeight: 'bold'}}>
            <Col className="col-4">Escala</Col>
            <Col className="auto">Data Início</Col>
            <Col className="auto">Data Alteração</Col>
            <Col className="auto">Retroativo?</Col>
            <Col className="auto">Ativo</Col>
        </Row>
        <br></br>
        {escalasHistorico.map((reg: any, idx: any) => (
          <Row key={idx} style={{ alignItems: "center" }}>
            <Col className="col-4">
              {reg.escala_nova.codigo} - {reg.escala_nova.descricao}
            </Col>
            <Col className="auto">
              {dayjs(reg.data_inicio).format("DD/MM/YYYY")}
            </Col>
            <Col className="auto">
              {dayjs(reg.data_alteracao).format("DD/MM/YYYY HH:mm:ss")}
            </Col>
            <Col className="auto">{reg.retroativo === 1 ? "Sim" : "Não"}</Col>
            <Col className="auto">
              <Form.Select
                value={reg.ativo === 1 ? "1" : "0"}
                onChange={(event: any) => handleChangeHistorico(event, reg)}
                aria-label="Selecione"
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </Form.Select>
            </Col>
          </Row>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
