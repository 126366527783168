import { FormEvent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import removeAllErrorMsg from "../../components/funcRemoveAllErros";
import Select from 'react-select'
import { ApiEndpoints, reqGetNew, reqPatch, reqPostNew } from "../../config/apiClient";
import dayjs from "dayjs";
import { getCookie } from "../../config/cookies";
import { toast } from "react-toastify";

interface iProps {
    show: boolean,
    colaboradorDados: any,
    handleClose: any,
    handleUpdate: any
}

export default function ColaboradorModalEscala({show, colaboradorDados, handleClose, handleUpdate}:iProps) {
    const [loading, setLoading] = useState<boolean>(false)
    const [escalaNova, setEscalaNova] = useState({
        "value": "0",
        "label": "Selecione a Escala"
    })
    const [retroativo, setRetroativo] = useState(0)
    // const [escalas, setEscalas] = useState<any[]>([])
    const [escalasFiltro, setEscalasFiltro] = useState<any[]>([])
    const [escalasHistorico, setEscalasHistorico] = useState<any[]>([])

    useEffect(() => {

        const getDados = async () => {
            setLoading(true)

            if (escalaNova.value === "0" && escalasFiltro.length === 0) {
                const apiEscalas = await reqGetNew(ApiEndpoints.colaboradorEscalas)

                if (apiEscalas.status === 200) {
                    // setEscalas(apiEscalas.data)
                    let escalasFiltroAux = apiEscalas.data.map((item:any, index:any) => (
                        {
                            "value": `${item.codigo}`,
                            "label": `${item.codigo} - ${item.descricao}`
                        }
                    ))
                    setEscalasFiltro(escalasFiltroAux)
                    if (colaboradorDados.id_escala !== null) {
                        setEscalaNova({value: `${colaboradorDados.id_escala}`, label: `${colaboradorDados.id_escala} - ${colaboradorDados.escala}`})
                    }
                }
            }

            if (escalasHistorico.length === 0) {
                const colAux = {
                    id_colaborador: colaboradorDados.id_colaborador
                }
                const apiEscalaHistorico = await reqPostNew(ApiEndpoints.colaboradorEscalasHistorico, colAux)
                if (apiEscalaHistorico.status === 200) {
                    console.log(apiEscalaHistorico.data)
                    setEscalasHistorico(apiEscalaHistorico.data)
                    console.log('atualizando_historico_escala')
                    setRetroativo(0)
                }
            }

            setLoading(false)
        }

        getDados()
    // eslint-disable-next-line
    }, [])

    function handleChangeRetroativo(event:any) {
        let retroativoAux = Number(event.target.value)
        setRetroativo(retroativoAux)
    }

    async function handleChangeHistorico(event:any, historico:any) {
        let dados:any = {
            id_historico: historico.id,
            ativo: Number(event.target.value)
        }
        console.log(dados)
        setLoading(true)
        const api = await reqPatch(ApiEndpoints.colaboradorEscalaHistoricoAlterar, dados)

        if (api.status === 200) {
            toast.success('Escala alterada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            handleUpdate()
            await atualizarEscalaHistorico()
        }
        setLoading(false)
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        const form:any = event.target
        let formValidado = true

        let dados:any = {
            id_colaborador: colaboradorDados.id_colaborador,
            id_escala_antiga: colaboradorDados.id_escala,
            id_escala_nova: Number(escalaNova.value),
            data_inicio: retroativo === 1 ? form.data_inicio.value : null,
            retroativo: retroativo,
            id_usuario: Number(getCookie('id_user'))
        }
        console.log(dados)

        if (dados.id_escala_nova === 0) {
            form.escala_aux.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (retroativo === 1 && dados.data_inicio === "") {
            form.data_inicio.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (!formValidado) {
            return
        }

        salvarNovaEscala(dados)
    }

    async function salvarNovaEscala(dados:any) {
        setLoading(true)
        const api = await reqPatch(ApiEndpoints.colaboradorEscalaAlterar, dados)

        if (api.status === 200) {
            toast.success('Escala alterada com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })
            handleUpdate()
            await atualizarEscalaHistorico()
        }
        setLoading(false)
    }

    async function atualizarEscalaHistorico() {
        const colAux = {
            id_colaborador: colaboradorDados.id_colaborador
        }
        const apiEscalaHistorico = await reqPostNew(ApiEndpoints.colaboradorEscalasHistorico, colAux)
        if (apiEscalaHistorico.status === 200) {
            console.log(apiEscalaHistorico.data)
            setEscalasHistorico(apiEscalaHistorico.data)
            console.log('atualizan_historico_escala')
            setRetroativo(0)
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>Histórico de escala</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id={`formNovaEscala`} onSubmit={handleSubmit} noValidate>
                    <Row>
                        <Col className="col-12">
                            <Form.Group>
                                <Form.Label>Escala Nova:</Form.Label>
                                <Select name='escala' id='escala' value={escalaNova} options={escalasFiltro} placeholder="Selecione a Escala" onChange={
                                    (choice:any) => {
                                        setEscalaNova(choice)
                                    }
                                } styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: "#dee2e6"
                                    }),
                                }} />
                                <Form.Control name='escala_aux' type="text" id='escala_aux' placeholder="..." style={{display:"none"}}/>
                                <Form.Control.Feedback type="invalid">
                                    Informe a escala!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="col-6">
                            <Form.Group>
                                <Form.Label>Retroativo?</Form.Label>
                                <Form.Select name="retroativo" id="retroativo" aria-label="Selecione" value={retroativo} onChange={handleChangeRetroativo}>
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col className="col-6" style={{display: retroativo === 0 ? 'none' : 'block'}}>
                            <Form.Group>
                                <Form.Label>Data Início:</Form.Label>
                                <Form.Control name='data_inicio' type="date" id='data_inicio' placeholder="dd/mm/aaaa"/>
                                <Form.Control.Feedback type="invalid">
                                    Informe a Data!
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br/>
                    <Button type="submit" disabled={loading}>{loading ? "Aguarde..." : "Salvar"}</Button>
                </Form>
                <hr></hr>
                <Row style={{fontWeight: 'bold'}}>
                    <Col className="col-4">Escala</Col>
                    <Col className="auto">Data Início</Col>
                    <Col className="auto">Data Alteração</Col>
                    <Col className="auto">Retroativo?</Col>
                    <Col className="auto">Ativo</Col>
                </Row>
                <br></br>
                {
                    escalasHistorico.map((reg:any, idx:any) => (
                        <Row key={idx} style={{alignItems: 'center'}}>
                            <Col className="col-4">{reg.id_escala_nova.codigo} - {reg.id_escala_nova.descricao}</Col>
                            <Col className="auto">{dayjs(reg.data_inicio).format("DD/MM/YYYY")}</Col>
                            <Col className="auto">{dayjs(reg.data_alteracao).format("DD/MM/YYYY HH:mm:ss")}</Col>
                            <Col className="auto">{reg.retroativo === 1 ? 'Sim' : 'Não'}</Col>
                            <Col className="auto">
                                <Form.Select value={reg.ativo === 1 ? "1" : "0"} onChange={(event:any) => handleChangeHistorico(event, reg)} aria-label="Selecione">
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    ))
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}
