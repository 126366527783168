import React, { useEffect, useState } from "react"
import dayjs from "dayjs"

interface iProps {
    dados:any,
    filtro:any
}

export default function ApuracaoPageColaborador({dados, filtro}:iProps) {

    const [versaoApp, setVersaoApp] = useState<boolean>(false)
    const [versaoAppDesatualizado, setVersaoAppDesatualizado] = useState<boolean>(false)

    useEffect(() => {
        if (dados.colaborador.versao_app !== null) {
            setVersaoApp(true)

            let col_versao_app:string = dados.colaborador.versao_app
            let app_versao_app:string = dados.colaborador.plataforma_app === "Android" ? dados.definicoes_app.versao_android : dados.definicoes_app.versao_ios
            while (col_versao_app.length !== app_versao_app.length) {
                if (col_versao_app.length > app_versao_app.length) {
                    app_versao_app = app_versao_app + "0"
                }
                if (col_versao_app.length < app_versao_app.length) {
                    col_versao_app = col_versao_app + "0"
                }
            }
            let col_versao_app_number:number = Number(col_versao_app.replaceAll(".", ""))
            let app_versao_app_number:number = Number(app_versao_app.replaceAll(".", ""))

            if (col_versao_app_number < app_versao_app_number) {
                setVersaoAppDesatualizado(true)
            }
        }
    }, [dados])

    const divCol:React.CSSProperties = {
        marginTop: "10px"
    }

    const h2ColName:React.CSSProperties = {
        fontSize: "20px",
        fontWeight: "bold"
    }

    const iconColEdit:React.CSSProperties = {
        fontSize: "16px",
        color: "blue",
        cursor: "pointer",
        marginLeft: "5px"
    }

    return (
        <div>
            <div className="div-row" style={divCol}>
                <h2 style={h2ColName}>Matrícula: {dados.colaborador.matricula} - {dados.colaborador.nome_completo}</h2>
                <a href={`/colaborador?id=${dados.colaborador.id_colaborador}`} target="_blank" rel="noreferrer">
                <span className="material-symbols-outlined" style={iconColEdit}>
                    edit_square
                </span>
                </a>
            </div>
            <p>{dados.colaborador.id_cargo.descricao} • {dados.colaborador.id_escala.codigo} - {dados.colaborador.id_escala.descricao} • {dados.colaborador.id_centro_custo.codigo} - {dados.colaborador.id_centro_custo.centro_custo} • {dados.colaborador.id_empresa.nome} • Situação: {dados.colaborador.id_situacao.descricao} • Admissão: {dayjs(dados.colaborador.data_admissao).format("DD/MM/YYYY")}{dados.colaborador.data_demissao === null ? '' : ` • Demissao: ${dayjs(dados.colaborador.data_demissao).format("DD/MM/YYYY")}`}</p>
            <br></br>
            <div className="div-row">
                <p style={{
                    color: dayjs(filtro.data_inicial).isSame(dayjs(dados.colaborador.data_ultima_atualizacao_app), 'month') ? 
                    'black' : dayjs(dados.colaborador.data_ultima_atualizacao_app).isBefore(dayjs(filtro.data_final + "00:00:00")) ? 'red' : 'black',
                    fontWeight: 'bold',
                    marginRight: '5px'
                }}> • Última sincronização dados: {dayjs(dados.colaborador.data_ultima_atualizacao_app).format("DD/MM/YYYY HH:mm:ss")}</p>
                {
                    (versaoApp && versaoAppDesatualizado) &&
                    <p> • Aplicativo desatualizado: <span style={{color: 'red'}}>{dados.colaborador.versao_app}</span> &lt; {dados.colaborador.plataforma_app === "Android" ? dados.definicoes_app.versao_android : dados.definicoes_app.versao_ios} </p>
                }
                {
                    (versaoApp && versaoAppDesatualizado === false) &&
                    <p> • Aplicativo atualizado: {dados.colaborador.versao_app} </p>
                }
                <p>&nbsp; • Timezone: {dados.colaborador.id_tmz.descricao} ({dados.colaborador.id_tmz.gmt})</p>
                <p>&nbsp; • Tel.: {dados.colaborador.telefone}</p>
            </div>
        </div>
    )

}