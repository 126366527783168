import { useEffect, useState } from "react";
import { ApiEndpoints, reqPost } from "../../config/apiClient";
import { toast } from "react-toastify";
// import styles from '../styles/colaborador/colaborador.module.css'
import Spinner from "react-bootstrap/Spinner";

import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "../../components/dataTable/DataTable";
import { Button } from "react-bootstrap";

interface colaboradoresFilter {
  id_empresa: string;
  id_centro_custo: string;
  id_local: string;
  id_situacao: string;
}

interface colaborador {
  id_colaborador: number;
  matricula: number;
  id_centro_custo: number;
  id_escala: number;
  nome_completo: string;
  centro_custo: string;
  cpf: string;
  cargo: string;
  empresa: string;
  escala: string;
  situacao: string;
  telefone: string;
  versao_app: string | null;
}

const columnHelper = createColumnHelper<colaborador>();

const defaultColumns = [
  columnHelper.accessor((row) => `${row.matricula} - ${row.nome_completo}`, {
    id: "colaborador",
    header: "Colaborador",
    size: 366,
  }),
  columnHelper.accessor(
    (row) =>
      `${row.cpf} | ${row.telefone}${row.versao_app === null ? "" : " | " + row.versao_app}`,
    {
      header: "CPF",
      size: 270,
    },
  ),
  columnHelper.accessor("empresa", {
    header: "Empresa",
    size: 200,
  }),
  columnHelper.accessor(
    (row) => `${row.id_centro_custo} - ${row.centro_custo}`,
    {
      id: "centro_custo",
      header: "Centro de Custo",
      size: 392,
    },
  ),
  columnHelper.accessor("cargo", {
    header: "Cargo",
    size: 180,
  }),
  columnHelper.accessor("escala", {
    header: "Escala",
    size: 314,
  }),
  columnHelper.accessor("situacao", {
    header: "Situação",
    size: 119,
  }),
];

const defaultOrder = [
  {
    id: "situacao",
    desc: true,
  },
  {
    id: "empresa",
    desc: true,
  },
  {
    id: "centro_custo",
    desc: false,
  },
];

function ColaboradoresPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [dados, setDados] = useState<colaborador[]>([]);

  useEffect(() => {
    document.title = "Colaboradores | OB PORTUS";
    getColaboradores();
  }, []);

  async function getColaboradores() {
    let data: colaboradoresFilter = {
      id_empresa: "",
      id_centro_custo: "",
      id_local: "",
      id_situacao: "",
    };

    const colaboradoresApi = await reqPost(ApiEndpoints.colaboradores, data);

    if (colaboradoresApi.status === 200) {
      console.log(colaboradoresApi);
      // document.getElementById("pCols")!.textContent = `${String(colaboradoresApi.data)}`
      setDados(colaboradoresApi.data);
      setLoading(false);
    }

    if (colaboradoresApi.status === 0) {
      toast.warning("Erro de conexão!", {
        containerId: 1,
        theme: "dark",
      });
    }

    if (colaboradoresApi.status !== 0 && colaboradoresApi.status !== 200) {
      toast.error(
        "Erro ao consultar colaboradores! Entre em contato com o suporte ou tente mais tarde!",
        {
          containerId: 1,
          theme: "dark",
        },
      );
    }
  }

  function tableMiddleClickhandler(event: any) {
    const idx = Number(event.target.parentElement.getAttribute("about"));

    if (event.button === 1) {
      window.open(`/colaborador?id=${dados[idx].id_colaborador}`);
    }
  }

  function tableClickhandler(event: any) {
    const idx = Number(event.target.parentElement.getAttribute("about"));

    if (event.button === 0) {
      window.location.assign(`/colaborador?id=${dados[idx].id_colaborador}`);
    }
  }

  return (
    <div id="main">
      <div className="div-row-space-between">
        <h1 id="titleMain">Colaboradores</h1>
        <Button
          onClick={() => {
            window.location.assign(`/colaborador`);
          }}
        >
          Cadastrar
        </Button>
      </div>
      <br />
      {loading && <Spinner />}
      {!loading && (
        <DataTable
          dados={dados}
          tableSize={"fixed"}
          defaultColumns={defaultColumns}
          tableMiddleClickhandler={tableMiddleClickhandler}
          tableClickhandler={tableClickhandler}
          defaultOrder={defaultOrder}
        />
      )}
      <br></br>
      <a href={`${ApiEndpoints.apiOld}/dashboard/colaboradores`} rel="noreferrer" target="_blank">Ir para página de consulta antiga</a>
    </div>
  );
}

export default ColaboradoresPage;
