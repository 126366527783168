import removeAllErrorMsg from "../../../components/funcRemoveAllErros"
import { FormEvent, useEffect, useState } from "react"
import { getUrlSearchParams } from "../../urlParams"
import iCargoCadastrarDto from "./dto/cadastro-cargo.dto"
import iCargosDto from "./dto/lista-cargos.dto"
import { Button, Col, Form, Row } from "react-bootstrap"
import { ApiEndpoints, reqPatch, reqPostNew, reqPut } from "../../../config/apiClient"
import { toast } from "react-toastify"
import funcSleep from "../../../components/funcSleep"
import iCargoOneDto from "./dto/one-cargo.dto"
import LoadingModal from "../../../components/loadingModal"

function CargosCadastrarEditarPage() {

    const [loading, setLoading] = useState<boolean>(false)
    const [cargoD, setCargo] = useState<iCargosDto>()

    useEffect(() => {
        document.title = "Cadastrar Cargo | OB PORTUS"

        const urlDados = getUrlSearchParams()
        const id = urlDados.get("id")

        const consultarCargo = async (id:number) => {

            setLoading(true)

            const dados:iCargoOneDto = {
                codigo: Number(id)
            }

            const api = await reqPostNew(ApiEndpoints.painelControle.cargos.consultar, dados)

            if (api.status === 200) {
                const cargo:iCargosDto = api.data
                setCargo(cargo)

                let form:any = document.getElementById("formCargo")
                form.codigo.value = cargo.codigo
                form.descricao.value = cargo.descricao
                form.situacao.value = cargo.situacao
            }

            setLoading(false)

        }

        if (id !== null) {
            document.title = "Editar Cargo | OB PORTUS"
            consultarCargo(Number(id))
        }
    }, [])

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()

        removeAllErrorMsg()

        // throw new Error("Function not implemented.")
        const form:any = event.target
        let formValidado = true

        let dados:iCargoCadastrarDto = {
            codigo: Number(form.codigo.value),
            descricao: form.descricao.value,
            situacao: form.situacao.value
        }

        if (dados.codigo === null || dados.codigo === 0) {
            form.codigo.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (dados.descricao === "") {
            form.descricao.nextElementSibling.style.display = "block"
            formValidado = false
        }

        if (!formValidado) {
            return
        }

        console.log(dados)

        if (cargoD === undefined) {
            cadastrar(dados)
        }

        if (cargoD !== undefined) {
            editar(dados)
        }

    }

    async function cadastrar(dados:iCargoCadastrarDto) {

        setLoading(true)

        const api = await reqPut(ApiEndpoints.painelControle.cargos.cadastrar, dados)

        setLoading(false)

        if (api.status === 201) {
            console.log(api.data)

            toast.success('Cargo cadastrado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(1500)

            window.location.assign(`/painelControle/cargos`)
        }

    }

    async function editar(dados: iCargoCadastrarDto) {
        setLoading(true)

        let dadosAux:iCargosDto = dados
        dadosAux.id_cargo = cargoD?.id_cargo

        const api = await reqPatch(ApiEndpoints.painelControle.cargos.editar, dados)

        setLoading(false)

        if (api.status === 200) {
            console.log(api.data)

            toast.success('Cargo editado com sucesso!', {
                containerId: 1,
                position: 'top-center',
                theme: 'dark'
            })

            await funcSleep(500)

            window.location.assign(`/painelControle/cargos`)
        }
    }


    return (
        <div id="main">
            <LoadingModal show={loading} handleClose={(event:any) => {
                setLoading(!loading)
            }} />

            <h1 id="titleMain">Cargo</h1>

            <br></br>

            <Form id='formCargo' onSubmit={handleSubmit} noValidate>
                
                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Código do cargo:</Form.Label>
                            <Form.Control name='codigo' type="number" id='codigo' placeholder="#ID"/>
                            <Form.Control.Feedback type="invalid">
                                Informe o código do cargo!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Descrição:</Form.Label>
                            <Form.Control name='descricao' type="text" id='descricao' placeholder=""/>
                            <Form.Control.Feedback type="invalid">
                                Informe a Descrição do cargo!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-15-forms">
                    <Col className="col-6">
                        <Form.Group>
                            <Form.Label>Situação:</Form.Label>
                            <Form.Select name="situacao" id="situacao" aria-label="Selecione a situação">
                                <option value="1">Ativo</option>
                                <option value="0">Desativado</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                
                {
                    cargoD === undefined &&
                    <Button variant="success" type="submit">Cadastrar</Button>
                }

                {
                    cargoD !== undefined &&
                    <Button variant="success" type="submit">Editar</Button>
                }

            </Form>

        </div>
    )

}

export default CargosCadastrarEditarPage