import React, { useEffect, useState } from 'react'
import { ApiEndpoints, reqGet } from '../../config/apiClient'
import styles from './styles/selecao.module.css'
import {Button} from 'react-bootstrap'
import NFConsulta from './Consulta'
import { iEmpresa } from './dto/nfInterfaces'
import NFNova from './NovaNF'
import { getUrlSearchParams } from '../urlParams'

import iconAddCircle from '../../img/home/add.svg'

let empresasDadosAux:iEmpresa[] = []

export default function NFSelecaoEmpresasPage() {

    const [current, setCurrent] = useState<string>('select')
    const [nfSelected, setNfSelected] = useState({})
    const [idEmpresa, setIdEmpresa] = useState<number>(0)
    const [empresas, setEmpresas] = useState<iEmpresa[]>([])
    const [empresa, setEmpresa] = useState<iEmpresa>({
        id_empresa: 0,
        cnpj: "",
        nome: "",
        inscricao_municipal: "",
        optante_simples_nacional: 0,
        ISS: ""
    })

    window.onpopstate = function(event:any) {
        // console.log("location: " + document.location + ", state: " + JSON.stringify(event.state))
        handleUrlChange()
    }

    useEffect(() => {
        document.title = 'NF Selecionar empresas | OB PORTUS'

        const getCompanies = async () => {
            const api = await reqGet(ApiEndpoints.nfSelecionarEmpresas)
    
            console.log(api)
        
            if (api.status === 200) {
    
                setEmpresas(api.data)
                checkUrlData(api.data)
                empresasDadosAux = api.data
        
            }
        }

        getCompanies()
    }, [])

    function handleUrlChange() {

        let urlParams:URLSearchParams = getUrlSearchParams()
        const id_empresa = urlParams.get("id_empresa")
        const id_nota = urlParams.get("id_nota")

        if (id_empresa !== null && id_nota !== null) {
            // console.log(`id_empresa=${id_empresa} && id_nota=${id_nota}`)
            setNfSelected({
                "id_nota": Number(id_nota)
            })
            setCurrent("new")
            return
        }

        if (id_empresa === null && id_nota === null) {
            // console.log(`id_empresa=${id_empresa} && id_nota=${id_nota}`)
            setNfSelected({})
            setIdEmpresa(0)
            setEmpresa({
                id_empresa: 0,
                cnpj: "",
                nome: "",
                inscricao_municipal: "",
                optante_simples_nacional: 0,
                ISS: ""
            })
            setCurrent('select')
            return
        }

        if (id_empresa !== null && id_nota === null) {
            // console.log(`id_empresa=${id_empresa} && id_nota=${id_nota} || idEmpresa=${idEmpresa} && empresa.id_empresa=${empresa.id_empresa}`)
            if (idEmpresa === 0 && empresa.id_empresa === 0) {
                for (let reg of empresasDadosAux) {
                    if (reg.id_empresa === Number(id_empresa)) {
                        setNfSelected({})
                        setIdEmpresa(reg.id_empresa)
                        setEmpresa(reg)
                        setCurrent("consultar")
                        return
                    }
                }
            }

            setCurrent("consultar")
            setNfSelected({})
            return
        }

    }

    function checkUrlData(empresasAux:iEmpresa[]) {

        let urlParams:URLSearchParams = getUrlSearchParams()
        const id_empresa = urlParams.get("id_empresa")
        const id_nota = urlParams.get("id_nota")

        if (id_empresa !== null && id_nota !== null) {
            for (let reg of empresasAux) {
                if (reg.id_empresa === Number(id_empresa)) {
                    window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESA NF", `/nf?id_empresa=${reg.id_empresa}&id_nota=${id_nota}`)
                    setIdEmpresa(reg.id_empresa)
                    setEmpresa(reg)
                    setNfSelected({
                        "id_nota": Number(id_nota)
                    })
                    setCurrent("new")
                    break
                }
            }
            return
        }

        if (id_empresa !== null && id_nota === null) {
            for (let reg of empresasAux) {
                if (reg.id_empresa === Number(id_empresa)) {
                    window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESA NF", `/nf?id_empresa=${reg.id_empresa}`)
                    setIdEmpresa(reg.id_empresa)
                    setEmpresa(reg)
                    setCurrent('consultar')
                    break
                }
            }
            return
        }

    }

    function handleSelectCompany(id:number) {
        setIdEmpresa(id)

        if (id === 0) {
            document.title = 'NF Selecionar empresas | OB PORTUS'
            window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESAS NF", '/nf')
            setCurrent('select')
            return
        }

        for (let reg of empresas) {
            if (reg.id_empresa === id) {
                window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESA NF", `/nf?id_empresa=${reg.id_empresa}`)
                setEmpresa(reg)
                setCurrent('consultar')
                break
            }
        }
    }

    function handleSwitchAction(action:string) {

        if (action === "new" || action === "consultar") {
            window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESA NF", `/nf?id_empresa=${empresa.id_empresa}`)
            setNfSelected({})
        }

        setCurrent(action)

    }

    function handleClickNf(nf:any) {

        window.history.pushState("OB PORTUS .NET", "OB PORTUS EMPRESA NF", `/nf?id_empresa=${empresa.id_empresa}&id_nota=${nf.id_nota}`)
        setNfSelected(nf)
        setCurrent("new")
        // console.log(nf)

    }

    return (
        <div id='main'>
            <input id='competencia_state' type="text" style={{display: "none"}} disabled></input>

            {
                (idEmpresa === 0 && current === 'select') &&
                <div>
                    <h1 id="titleMain">Selecione a Empresa:</h1>
                    <br/>
                    <div id='div_select_company' className={styles.div_select_company}>
                        
                        {
                            empresas.map(
                                (item, index) => (
                                    <div key={index} className={styles.select_company} onClick={(e) => { handleSelectCompany(item.id_empresa) }}>
                                        <p>{item.nome}</p>
                                    </div>
                                )
                            )
                        }

                        <div className={styles.select_company}>
                            <img src={iconAddCircle} alt='add_circle'></img>
                            <p>Adicionar empresa</p>
                        </div>

                    </div>
                </div>
            }

            {/* {
                Object.values(nfSelected).length !== 0 &&
                <div>
                    <p>{JSON.stringify(nfSelected)}</p>
                </div>
            } */}

            {
                (idEmpresa !== 0 && current === 'consultar') &&
                <div>
                    <div className={styles.div_header_empresa}>
                        <h1 id='titleMain'>{empresa?.nome}</h1>
                        <Button variant='secondary' onClick={ (e) => {handleSelectCompany(0)} }>TROCAR</Button>
                    </div>

                    <NFConsulta empresaDados={empresa} currentView={handleSwitchAction} clickNf={handleClickNf} />
                </div>    
            }

            {
                (idEmpresa !== 0 && current === 'new') &&
                <div>
                    <NFNova empresaDados={empresa} currentView={handleSwitchAction} nfView={nfSelected} />
                </div>
            }
        </div>
    )

}